import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import ContactSection from './ContactSection';
import LanguageSection from './LanguageSection';
import UserInformationSection from './UserInformationSection';
import ContentSection from './ContentSection';
import LogoSection from './LogoSection';
import strings from '../../utils/strings';
import { Desktop, Mobile, useIsMobile } from '../general/MediaQuery';
import { useIsImpersonating } from '../../utils/selectorUtils';
import ImpersonationInfo from './ImpersonationInfo';

export const Sidebar = ({
  permissionsLoading,
  userManagementApiLoading,
  fileTransferGetClientsLoading,
}) => {
  const isMobile = useIsMobile();
  const isImpersonating = useIsImpersonating();

  return (
    <div
      className={classNames(
        'h-100',
        !isMobile && 'border-right shadow-right',
      )}
    >
      <div id="sidebar-wrapper" className="d-flex flex-column">
        <div className={classNames(!isMobile && 'border-bottom')}>
          <Desktop>
            <LogoSection />
          </Desktop>
          <Mobile>
            <div className="d-flex justify-content-center">
              <span id="topbar-text" className="text-secondary">
                Access
              </span>
            </div>
          </Mobile>
        </div>
        {!isMobile && isImpersonating && (
          <ImpersonationInfo />
        )}
        <div
          className={'d-flex flex-column justify-content-between h-100 pt-20 px-20 pb-15'}
        >
          {permissionsLoading || userManagementApiLoading || fileTransferGetClientsLoading ? (
            <img className="mx-auto mt-30" height="64" width="64" alt="" src="/lowell_loading.gif" data-testid="contentSectionLoading" />
          ) : (
            <div className="d-flex flex-column nav-link-section">
              <ContentSection />
              {!permissionsLoading && !userManagementApiLoading && <ContactSection />}
            </div>
          )}
          <div className="d-flex flex-column">
            <div className="nav-item">
              <button
                className="nav-link d-inline-block clickable"
                data-toggle="modal"
                data-target="#dataProtectionModal"
              >
                <span id="dataProtectionLink">{strings.dataProtection}</span>
              </button>
            </div>
          </div>
        </div>
        {!permissionsLoading && (
          <div className="p-20 border-top">
            <LanguageSection />
            <UserInformationSection />
          </div>
        )}
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  permissionsLoading: PropTypes.bool.isRequired,
  userManagementApiLoading: PropTypes.bool.isRequired,
  fileTransferGetClientsLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  permissionsLoading: state.permissions.policyApiLoading,
  userManagementApiLoading: state.usermanagement.userManagementApiLoading,
  fileTransferGetClientsLoading: state.fileTransfer.getClientsLoading,
  localization: state.localization
});

export default connect(mapStateToProps)(Sidebar);
