/* eslint-disable react/no-danger */
import styles from './DiscoAnnouncement.module.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import strings from '../utils/strings';

const DiscoAnnouncement = ({
  level,
  title,
  published,
  icon,
  linkifiedMessage,
  id,
  markAsRead,
  unread
}) => {
  const [clicked, setClicked] = useState(false);
  return (
    <div
      className={`disco-announcement disco-announcement-${level} p-2 mb-3`}
    >
      <div className="d-flex">
        {icon}
        <div className="px-2 w-100">
          <div className="d-flex align-items-baseline justify-content-between mb-10">
            <span><strong>{title}</strong></span>
            <div>
              {unread && (
                <span className={styles.new}>{strings.new}</span>
              )}
              <span className="text-nowrap ml-10">{published}</span>
            </div>
          </div>
          <div
            className="collapse"
            id={id}
          >
            <span className="disco-announcement-content" dangerouslySetInnerHTML={{ __html: linkifiedMessage }} />
          </div>
          <button
            onKeyDown={(e) => {
              if (e.keyCode === 13) setClicked(!clicked);
            }}
            tabIndex={0}
            className="disco-read-more"
            data-toggle="collapse"
            data-target={`#${id}`}
            type="button"
            onClick={() => {
              if (!clicked) {
                markAsRead(title);
              }
              setClicked(!clicked);
            }}
          >
            {clicked ? strings.showLess : strings.readMore}
          </button>
        </div>
      </div>
    </div>
  );
};

DiscoAnnouncement.propTypes = {
  level: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  published: PropTypes.string.isRequired,
  icon: PropTypes.node,
  linkifiedMessage: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  markAsRead: PropTypes.func.isRequired,
  unread: PropTypes.bool.isRequired,
};

DiscoAnnouncement.defaultProps = {
  icon: null,
};

export default DiscoAnnouncement;
