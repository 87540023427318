import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import strings from '../../utils/strings';

const CheckboxWithLoadingSpinner = ({
  loading,
  checked,
  permission,
  requestBody,
  modifyPermissions,
}) => {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (!loading) {
      setClicked(false);
    }
  }, [loading]);

  return (
    <div className="d-flex text-nowrap">
      <label className="m-0">
        <input
          type="checkbox"
          checked={checked}
          onChange={() => {
            setClicked(true);
            modifyPermissions(checked, requestBody(permission));
          }}
        />
        <span className="pl-1">
          {strings[`permission${permission}`] || permission}
        </span>
      </label>
      <div className="d-flex justify-content-center align-items-center loading-spinner-container">
        {clicked && loading && (
          <img className="loadingSpinnerSmall pl-1" alt="" src="/lowell_loading.gif" />
        )}
      </div>
    </div>
  );
};

CheckboxWithLoadingSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  permission: PropTypes.string.isRequired,
  requestBody: PropTypes.func.isRequired,
  modifyPermissions: PropTypes.func.isRequired,
};

export default CheckboxWithLoadingSpinner;
