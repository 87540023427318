import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Overlay } from 'react-bootstrap';
import strings from '../../utils/strings';

const AddUserFormField = ({
  type,
  name,
  handleChange,
  value,
  valid,
  label,
  autofocus,
  errorMessage,
}) => {
  const [target, setTarget] = useState(null);
  const attachRef = t => setTarget(t);

  const input = () => {
    if (type === 'select') {
      return (
        <select
          ref={attachRef}
          name={name}
          onChange={handleChange}
          value={value}
          className={`form-control form-control-sm${!valid ? ' invalid' : ''}`}
          data-testid={name}
        >
          <option className="dropdown-item" value="">{strings.country}</option>
          <option className="dropdown-item" value="da">{strings.denmark}</option>
          <option className="dropdown-item" value="fi">{strings.finland}</option>
          <option className="dropdown-item" value="no">{strings.norway}</option>
          <option className="dropdown-item" value="sv">{strings.sweden}</option>
        </select>
      );
    }
    return (
      <input
        ref={attachRef}
        autoFocus={autofocus}
        onChange={handleChange}
        value={value}
        name={name}
        className={`form-control form-control-sm${!valid ? ' invalid' : ''}`}
        type={type}
        placeholder={label}
        id={label}
        data-testid={name}
      />
    );
  };

  return (
    <div className="row">
      <div className="mb-1">
        <label
          htmlFor={label}
        >
          {`${label}:`}
        </label>
      </div>
      <div className="col">
        {input()}
      </div>
      <Overlay target={target} show={!!(!valid && errorMessage && errorMessage.length > 0)} placement="right">
        {({
          placement,
          scheduleUpdate,
          arrowProps,
          outOfBoundaries,
          show: _show,
          ...props
        }) => (
          <span {...props} className="pl-2 form-validation-error">
            {errorMessage}
          </span>
        )}
      </Overlay>
    </div>
  );
};

AddUserFormField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  valid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  autofocus: PropTypes.bool,
  errorMessage: PropTypes.string,
};

AddUserFormField.defaultProps = {
  autofocus: false,
  errorMessage: '',
  valid: false,
};

export default AddUserFormField;
