import React from 'react';
import PropTypes from 'prop-types';
import RoleCheckbox from './RoleCheckbox';
import { userType, roleType, clientType } from '../../utils/propTypeTemplates';

const RoleRow = ({
  client,
  roles,
  userRoles,
  modifyRoles,
  user,
  modifyUserPermissionsLoading,
  label,
}) => (
  <tr data-testid="roleRow">
    <td className="col-4">
      {label}
    </td>
    <td className="col-5" />
    <td className="col-3">
      {roles.map(r => (
        <div key={r.RoleId}>
          <RoleCheckbox
            client={client}
            roleId={r.RoleId}
            coreSystemId={r.CoreSystemId}
            modifyRoles={modifyRoles}
            user={user}
            userRoles={userRoles}
            modifyUserPermissionsLoading={modifyUserPermissionsLoading}
          />
        </div>
      ))}
    </td>
  </tr>
);

RoleRow.propTypes = {
  client: clientType.isRequired,
  roles: roleType.isRequired,
  userRoles: roleType.isRequired,
  user: userType.isRequired,
  modifyRoles: PropTypes.func.isRequired,
  modifyUserPermissionsLoading: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

export default RoleRow;
