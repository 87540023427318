import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as localizationActionCreators } from '../../store/LocalizationStore';
import strings from '../../utils/strings';
import { checkUploaderRole } from '../../utils/fileServiceRoleChecker';
import { policyApiPermissionsType } from '../../utils/propTypeTemplates';
import NavigationLink from './NavigationLink';

export const ContentSection = ({
  availableClients,
  fileTransferClients,
  policyApiPermissions,
  localization,
}) => {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    const newLinks = [
      { route: '/', text: strings.home, exact: true },
    ];
    if (availableClients.length > 0) {
      newLinks.push({ route: '/adminpanel', text: strings.adminPanel, exact: true });
    }
    if (fileTransferClients.length > 0) {
      const sublinks = [
        { route: '/files/download', text: strings.download, exact: true },
      ];
      if (checkUploaderRole(policyApiPermissions)) {
        sublinks.push({ route: '/files/upload', text: strings.upload, exact: true });
      }
      newLinks.push({
        route: '/files',
        text: strings.fileTransfer,
        exact: false,
        sublinks,
      });
    }
    setLinks(newLinks);
  }, [availableClients, fileTransferClients, policyApiPermissions, setLinks, localization]);

  return (
    <ul className="nav sidebar flex-column">
      {links.map(l => (
        <NavigationLink
          key={l.route}
          route={l.route}
          text={l.text}
          exact={l.exact}
          sublinks={l.sublinks || []}
        />
      ))}
    </ul>
  );
};

ContentSection.propTypes = {
  availableClients: PropTypes.arrayOf(PropTypes.shape({
    clientId: PropTypes.number.isRequired,
  })).isRequired,
  fileTransferClients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  policyApiPermissions: policyApiPermissionsType.isRequired,
};

function mapStateToProps(state) {
  return {
    localization: state.localization,
    route: state.router.location.pathname,
    availableClients: state.permissions.availableClients,
    fileTransferClients: state.fileTransfer.clients,
    policyApiPermissions: state.permissions.policyApiPermissions,
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...localizationActionCreators,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentSection);
