import { useSelector } from 'react-redux';
import { useAuth } from '../store/AuthStore';

const allowedApps = [
  'CollectionWeb Denmark',
  'CollectionWeb Finland',
  'CollectionWeb Norway',
  'CollectionWeb Sweden',
  'InvoiceWeb',
  'Credit Decision',
  'Company Monitor',
];

export const useDiscoApps = (): string[] => {
  const discoMessages: { applications: string[] }[] = useSelector((state: any) => state.permissions.discoAnnouncements);
  const apps = [...new Set(discoMessages.flatMap(m => m.applications))].filter(a => allowedApps.includes(a));
  return apps;
};

export const useIsImpersonating = (): boolean | null => {
  const actingIdentityEmail: string = useSelector((state: any) => state.permissions.actingIdentity);
  const auth = useAuth();
  const userEmail = auth.user?.emailaddress;
  if (actingIdentityEmail === null) {
    return null;
  }
  return actingIdentityEmail !== userEmail;
}

export const useActingIdentity = (): string => {
  return useSelector((state: any) => state.permissions.actingIdentity);
}