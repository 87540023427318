import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './LinkCard.module.scss';

const LinkCard = ({
  icon,
  title,
  links,
}) => {
  if (links.length === 0) return null;

  return (
    <div
      className={classnames(
        styles.card,
        links.length === 1 && styles.singleLink,
      )}
    >
      <div className="d-flex">
        <div className={styles.icon}>
          {icon}
        </div>
        <div className={classnames(styles.title, links.length > 1 && styles.multiLinkTitle)}>
          {title}
        </div>
      </div>
      <div className={styles.links}>
        {links.length > 1 ? links.map(l => (
          <div
            key={l.url}
          >
            <a
              href={l.url}
              rel="noopener noreferrer"
              target="_blank"
              className={styles.link}
              data-testid={l.testId}
            >
              {l.text}
            </a>
          </div>
        )) : (
          <a
            href={links[0].url}
            className="stretched-link"
            rel="noopener noreferrer"
            target="_blank"
            data-testid={links[0].testId}
          >
            {' '}
          </a>
        )}
      </div>
    </div>
  );
};

LinkCard.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string,
  })).isRequired,
  testId: PropTypes.string,
};

LinkCard.defaultProps = {
  testId: '',
};

export default LinkCard;
