
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import logger from '../../utils/logger';
import { actionCreators as userManagementActionCreators } from '../../store/UserManagementStore';
import { actionCreators as permissionsActionCreators } from '../../store/PermissionStore';
import AddUserForm from './AddUserForm';
import EditUserForm from './EditUserForm';
import AssetPermissions from './AssetPermissions';
import AdminPanelButton from './AdminPanelButton';
import UserListHeader from './UserListHeader';
import strings from '../../utils/strings';
import { roleType } from '../../utils/propTypeTemplates';

export class AdminPanel extends Component {
  constructor(props) {
    super(props);
    const { selectedUser } = props;
    this.state = {
      showEditForm: !!selectedUser,
      showAddUserForm: false,
      searchInput: '',
      userInfo: selectedUser,
      userList: null,
      sortOrder: 0,
      sortedBy: '',
    };
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.showEditForm = this.showEditForm.bind(this);
    this.showAddUserForm = this.showAddUserForm.bind(this);
    this.sortUsers = this.sortUsers.bind(this);
    this.exportUserList = this.exportUserList.bind(this);
    this.handleAddNewUserButtonClick = this.handleAddNewUserButtonClick.bind(this);
  }

  componentDidUpdate() {
    const {
      userList,
      sortedBy,
    } = this.state;
    const { usermanagementInfo } = this.props;
    if (userList && userList.length !== usermanagementInfo.length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ userList: usermanagementInfo });
      this.sortUsers(sortedBy);
    }
  }

  handleSearchSubmit = (event) => {
    event.preventDefault();
  }

  selectUser(user) {
    const {
      selectedUser,
      setUserInputs,
    } = this.props;
    let userInfo;
    if (!user || selectedUser.Email === user.Email) {
      userInfo = {
        Email: '',
      };
    } else {
      userInfo = user;
    }
    setUserInputs({
      ...userInfo,
    });
    this.setState({ userInfo });
  }

  handleSearchInputChange(e) {
    this.setState({ searchInput: e.target.value });
  }

  showEditForm(setFormElementOpen) {
    this.setState({ showEditForm: setFormElementOpen });
  }

  showAddUserForm(setFormElementOpen) {
    const { showAddUserForm } = this.state;
    this.setState({ showAddUserForm: setFormElementOpen || !showAddUserForm });
  }

  sortUsers(sortedByParam) {
    if (sortedByParam.length === 0) return;

    const { usermanagementInfo } = this.props;
    const { sortOrder, sortedBy } = this.state;
    const userList = [...usermanagementInfo];
    let order;
    if (sortOrder === 0 || sortedByParam !== sortedBy) {
      order = 1;
    } else if (sortOrder === 1) {
      order = -1;
    } else if (sortOrder === -1) {
      order = 0;
    }
    if (sortedByParam === 'name') {
      userList.sort((a, b) => {
        if (a.LastName.toLowerCase() < b.LastName.toLowerCase()) {
          return -order;
        } if (a.LastName.toLowerCase() > b.LastName.toLowerCase()) {
          return order;
        } if (a.FirstName.toLowerCase() < b.FirstName.toLowerCase()) {
          return -order;
        } if (a.FirstName.toLowerCase() > b.FirstName.toLowerCase()) {
          return order;
        }
        return 0;
      });
      this.setState({ sortedBy: 'name' });
    } else if (sortedByParam === 'email') {
      userList.sort((a, b) => {
        if (a.Email.toLowerCase() < b.Email.toLowerCase()) {
          return -order;
        } if (a.Email.toLowerCase() > b.Email.toLowerCase()) {
          return order;
        } if (a.Email.toLowerCase() < b.Email.toLowerCase()) {
          return -order;
        } if (a.Email.toLowerCase() > b.Email.toLowerCase()) {
          return order;
        }
        return 0;
      });
      this.setState({ sortedBy: 'email' });
    } else if (sortedByParam === 'login') {
      userList.sort((a, b) => {
        if (a.LastLogin === null) {
          return order;
        }
        if (b.LastLogin === null) {
          return -order;
        }
        if (a.LastLogin < b.LastLogin) {
          return order;
        } if (a.LastLogin > b.LastLogin) {
          return -order;
        }
        return 0;
      });
      this.setState({ sortedBy: 'login' });
    }
    this.setState({ userList, sortOrder: order });
  }

  exportUserList(clients) {
    const { exportUserList } = this.props;
    exportUserList(clients);
  }

  handleAddNewUserButtonClick() {
    const { showAddUserForm } = this.state;
    this.selectUser();
    this.setState({ showAddUserForm: !showAddUserForm, showEditForm: false });
  }

  render() {
    logger.log('Admin panel rendered:', this);
    const {
      userList,
      searchInput,
      showEditForm,
      showAddUserForm,
      userInfo,
      sortOrder,
      sortedBy,
    } = this.state;
    const {
      usermanagementInfo,
      userManagementLoading,
      policyApiLoading,
      selectedUser,
      assetPermissions,
      availableClients,
      userExportLoading,
      availableUserRoles,
    } = this.props;
    const users = userList || [...usermanagementInfo];
    const splitSearch = searchInput.split(' ');
    const userSearchResult = !userManagementLoading
      ? users.filter(user => splitSearch
        .filter(word => (`${user.FirstName.toLowerCase()} ${user.LastName.toLowerCase()}`.includes(word.toLowerCase())
          || user.Email.toLowerCase().includes(word.toLowerCase())
          || (new RegExp(([...word.toLowerCase()]
            .map(letter => `[${letter}]+.?`)).join(''))).exec(`${user.FirstName.toLowerCase()} ${user.LastName.toLowerCase()}`)
          || (new RegExp(([...word.toLowerCase()]
            .map(letter => `[${letter}]+.?`)).join(''))).exec(user.Email.toLowerCase())))
        .length === splitSearch.length)
      : [];

    const loadingSpinner = (
      <tr data-testid="usersLoading">
        <td className="container pt-4 pb-4 text-center border-top-0">
          <img alt="Loading users" src="/lowell_loading.gif" />
        </td>
      </tr>
    );

    const AdminPanelList = userSearchResult.length > 0 ? userSearchResult.map(user => (
      <tr
        className={selectedUser.Email === user.Email ? 'selected' : ''}
        onClick={() => {
          this.selectUser(user);
          this.setState({
            showEditForm: showEditForm
              ? !(user.Email === selectedUser.Email) : true,
            showAddUserForm: false,
          });
        }}
        key={user.Email}
        data-testid="userRow"
      >
        <td className="col-4 pl-5" data-testid="userListName">
          {`${user.FirstName} ${user.LastName}`}
        </td>
        <td className="col-5" data-testid="userListEmail">{user.Email}</td>
        <td className="col-3">
          {user.LastLogin
            ? `${new Date(user.LastLogin).getDate()}.${new Date(user.LastLogin).getMonth() + 1}.${new Date(user.LastLogin).getFullYear()}`
            : '-'}
        </td>
      </tr>
    )) : (
      <tr className="d-flex">
        <td className="mx-auto border-top-0">
          <span>{strings.usersNotFound}</span>
        </td>
      </tr>
    );
    const editForm = (showEditForm && selectedUser.Email !== '')
      ? (
        <div className="bg-white h-100">
          <EditUserForm
            user={userInfo}
            hasAssets={assetPermissions.length > 0}
          />
          {assetPermissions.length > 0 && (
            <AssetPermissions
              availableUserRoles={availableUserRoles}
              permissions={assetPermissions}
              clients={availableClients}
              user={userInfo}
            />
          )}
        </div>
      ) : '';
    const addUserForm = showAddUserForm && (
      <AddUserForm
        showEditForm={this.showEditForm}
        showAddUserForm={this.showAddUserForm}
        selectUser={this.selectUser}
      />
    );
    const sortIcon = () => {
      if (sortOrder === 0) {
        return 'fa-sort';
      }
      return sortOrder === 1 ? 'fa-sort-up' : 'fa-sort-down';
    };
    return (
      <div className="row no-gutters h-100">

        <div className="col pt-30 px-0 shadow-right">
          <div className="d-flex justify-content-between pl-50 pr-30 pb-50">
            <div className="d-flex">
              <form onSubmit={this.handleSearchSubmit} className="navbar-form" role="search">
                <div className="input-group border flex-nowrap">
                  <input onChange={this.handleSearchInputChange} value={searchInput} type="text" className="form-control border-0" style={{ minWidth: '100px' }} placeholder={strings.search} />
                  <button type="button" className="searchButton">
                    <i className="fas fa-search" />
                  </button>
                </div>
              </form>
            </div>
            <div className="d-flex">
              <div>
                <AdminPanelButton
                  handleClick={() => this.exportUserList(availableClients)}
                  toolTip={strings.export}
                  testId="exportButton"
                  faIcon="fa-file-export"
                  disabled={userExportLoading}
                />
              </div>
              {assetPermissions.length > 0 && (
                <div className="ml-20">
                  <AdminPanelButton
                    handleClick={this.handleAddNewUserButtonClick}
                    toolTip={strings.addUserFormTitle}
                    showAddUserForm={showAddUserForm}
                    testId="addNewUserButton"
                    faIcon="fa-user-plus"
                  />
                </div>
              )}
            </div>
          </div>
          <div id="userTableContainer">
            <table id="userTableFixed" className="table table-responsive-md table-clickable mb-0">
              <thead className="d-flex">
                <tr className="flex-grow-1">
                  <UserListHeader
                    sortedBy={sortedBy}
                    sortBy="name"
                    sortUsers={this.sortUsers}
                    sortIcon={sortIcon}
                    colSize={4}
                    testId="userListNameSort"
                    headerText={strings.usersTableHeaderName}
                    leftPadding={5}
                  />
                  <UserListHeader
                    sortedBy={sortedBy}
                    sortBy="email"
                    sortUsers={this.sortUsers}
                    sortIcon={sortIcon}
                    colSize={5}
                    testId="userListEmailSort"
                    headerText={strings.email}
                  />
                  <UserListHeader
                    sortedBy={sortedBy}
                    sortBy="login"
                    sortUsers={this.sortUsers}
                    sortIcon={sortIcon}
                    colSize={3}
                    testId="userListLoginSort"
                    headerText={strings.usersTableHeaderLogin}
                  />
                </tr>
                <tr>
                  <th className="border-top-0 h-100" style={{ width: '17px' }} />
                </tr>
              </thead>
              <tbody>
                {userManagementLoading || policyApiLoading ? loadingSpinner : AdminPanelList}
              </tbody>

            </table>
          </div>

        </div>
        <div className="col pl-0 bg-body-bg">
          {addUserForm}
          {editForm}
        </div>
      </div>
    );
  }
}

AdminPanel.propTypes = {
  availableClients: PropTypes.arrayOf(
    PropTypes.shape({
      clientId: PropTypes.number,
      clientName: PropTypes.string,
      administrator: PropTypes.bool,
    }),
  ),
  selectedUser: PropTypes.shape({
    Email: PropTypes.string,
  }),
  usermanagementInfo: PropTypes.arrayOf(
    PropTypes.shape({
      FirstName: PropTypes.string,
      LastName: PropTypes.string,
      Email: PropTypes.string,
    }),
  ),
  setUserInputs: PropTypes.func.isRequired,
  exportUserList: PropTypes.func.isRequired,
  userManagementLoading: PropTypes.bool,
  policyApiLoading: PropTypes.bool,
  assetPermissions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userExportLoading: PropTypes.bool,
  availableUserRoles: roleType.isRequired,
};

AdminPanel.defaultProps = {
  availableClients: [],
  selectedUser: {},
  usermanagementInfo: [],
  userManagementLoading: false,
  policyApiLoading: false,
  userExportLoading: false,
};

const mapStateToProps = state => ({
  userManagementLoading: state.usermanagement.userManagementApiLoading,
  policyApiLoading: state.permissions.policyApiLoading,
  usermanagementInfo: state.usermanagement.usermanagementInfo,
  availableClients: state.permissions.availableClients,
  selectedUser: state.usermanagement.selectedUser,
  assetPermissions: state.usermanagement.assetsAndPermissions,
  availableUserRoles: state.usermanagement.availableUserRoles,
  activeLanguage: state.localization.language,
  userExportLoading: state.usermanagement.userExportLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...userManagementActionCreators,
  ...permissionsActionCreators,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminPanel);
