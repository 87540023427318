import LocalizedStrings from 'react-localization';
import fi from './strings.fi';
import en from './strings.en';
import sv from './strings.sv';
import da from './strings.da';
import no from './strings.no';

const strings = new LocalizedStrings({
  en,
  fi,
  sv,
  da,
  no,
});

export default strings;
