import React from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import styles from './NoMobilePageAvailable.module.scss';
import strings from '../../utils/strings';

const NoMobilePageAvailable = () => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        {strings.pleaseViewOnDesktop}
      </div>
      <button
        className={styles.button}
        type="button"
        onClick={() => history.push('/')}
      >
        {strings.backToHomePage}
      </button>
    </div>
  );
};

const mapStateToProps = state => ({ localization: state.localization });

export default connect(mapStateToProps)(NoMobilePageAvailable);
