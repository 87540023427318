import api from '../utils/ApiUtils';
import strings from '../utils/strings';

export const LocalizationTypes = Object.freeze({
  setLanguage: 'SET_LANGUAGE',
});

const initialState = {
  // eslint-disable-next-line no-prototype-builtins
  language: 'en',
};

export const actionCreators = {
  setLanguage: (language, updateOnlyState) => {
    document.documentElement.lang = language;
    strings.setLanguage(language);
    return (dispatch) => {
      dispatch({ type: LocalizationTypes.setLanguage, language });
      if (!updateOnlyState) {
        api.patch('api/settings/user/language', JSON.stringify({ languageCode: language }));
      }
    };
  },
};

export const reducer = (state, action) => {
  const localizationState = state || initialState;

  switch (action.type) {
    case LocalizationTypes.setLanguage:
      return { ...localizationState, language: action.language };
    default:
      return localizationState;
  }
};
