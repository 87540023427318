/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as pbi from 'powerbi-client';
import queryString from 'query-string';
import { actionCreators as permissionActionCreators } from '../store/PermissionStore';
import { getConfig } from '../utils/configManager';
import logger from '../utils/logger';
import PageNotFound from './PageNotFound';
import api from '../utils/ApiUtils';

class PowerBIDashboard extends Component {
  constructor(props) {
    super(props);
    this.pbiContainerRef = React.createRef();
    this.powerbi = window.powerbi;
    this.config = getConfig();
    this.getReportConfig = this.getReportConfig.bind(this);
    this.updateToken = this.updateToken.bind(this);
    this.setTokenExpirationListener = this.setTokenExpirationListener.bind(this);

    this.state = {
      loading: true,
      failedLoading: false,
      urlParams: queryString.parse(this.props.location.search),
    };

    logger.log('This.config:', this.config);
    logger.log('PowerBiDashboard Props:', this.props);
  }

  componentDidMount() {
    logger.log('PowerBiDashboard mounted');

    const { urlParams } = this.state;

    const { fetchAndSetPermissions } = this.props;
    const policyApiFetch = fetchAndSetPermissions().then(() => {
      const { externalPbiReports } = this.props;
      return externalPbiReports;
    });

    // We use both the available PBI reports from the Redux state (Policy API)
    // and the ones from the new external-pbireport endpoint in IAMToo
    policyApiFetch
      .then((response) => {
        const availableReports = response
          .map(report => ({
            workspaceId: report.sourceId.split(':')[0],
            reportId: report.sourceId.split(':')[1],
            name: report.name,
            sourceId: report.sourceId
          }));

        const targetReport = availableReports
          .find(report => report.name === urlParams.dashboardId);
        if (
          urlParams.dashboardId
              && targetReport !== undefined
        ) {
          this.getReportConfig(targetReport.sourceId);
        } else {
          this.setState({
            failedLoading: true,
            loading: false,
          });
        }
      });
  }

  getReportConfig(sourceId) {
    logger.log('PowerBiDashboard getReportConfig');
    const headers = new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Report-Name': this.state.urlParams.dashboardId,
    });

    api.get(`api/powerbi/report/${sourceId}`, headers)
      .then((json) => {
        console.log('PBI Report Json:', json);
        const report = this.powerbi.embed(this.pbiContainerRef.current, {
          type: 'report',
          tokenType: pbi.models.TokenType.Embed,
          accessToken: json.accessToken,
          embedUrl: json.embedUrl,
          id: json.reportId,
          pageView: 'fitToWidth',
          settings: {
            filterPaneEnabled: false,
          },
        });

        report.off('loaded');

        report.on('loaded', () => {
          this.setTokenExpirationListener(
            json.expiration,
            2,
            sourceId.split(":")[0],
            sourceId.split(":")[1],
          );
        });

        this.setState({
          loading: false,
          failedLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          failedLoading: true,
          loading: false,
        });
      });

    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  setTokenExpirationListener(
    tokenExpiration,
    minutesToRefresh,
    reportId,
    workspaceId,
  ) {
    const currentTime = Date.now();
    const expiration = Date.parse(tokenExpiration);
    const safetyInterval = minutesToRefresh * 60 * 1000;

    // time until token refresh in milliseconds
    const timeout = expiration - currentTime - safetyInterval;

    // if token already expired, generate new token and set the access token
    if (timeout <= 0) {
      console.log('Updating Report Embed Token now');
      this.updateToken(reportId, workspaceId);
    } else {
      console.log(`Report Embed Token will be updated in ${timeout} milliseconds.`);
      setTimeout(() => {
        this.updateToken(reportId, workspaceId);
      }, timeout);
    }
  }

  updateToken(reportId, workspaceId) {
    api.get(`api/powerbi/report/${workspaceId}/${reportId}`)
      .then((json) => {
        const report = this.powerbi.get(this.pbiContainerRef.current);
        report.setAccessToken(json.accessToken)
          .then(() => {
            this.setTokenExpirationListener(
              json.expiration,
              2,
              reportId,
              workspaceId,
            );
            console.log('Updated PBI Report token to:', json);
          });
      });
  }

  render() {

    let innerJsx = (
      <div className="row flex-column pt-5 w-100 justify-content-center align-items-center">
        <h4>Loading report..</h4>
        <img height="64" width="64" alt="Loading.." src="/lowell_loading.gif" />
      </div>
    );

    if (!this.state.loading && this.state.failedLoading) {
      innerJsx = (
        <PageNotFound />
      );
    }

    return (
      <div className="pbi-container">
        <div
          className="pbi-target"
          ref={this.pbiContainerRef}
        >
          <div>{innerJsx}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    externalPbiReports: state.permissions.externalPbiReports,
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...permissionActionCreators,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PowerBIDashboard);
