/* eslint-disable no-underscore-dangle */

import 'core-js';
import 'whatwg-fetch';
import 'bootstrap';
import './entrypoint.css';
import React from 'react';
import { render } from 'react-dom';
import { store, history } from './store/store';
import logger from './utils/logger';
import Root from './Root';

logger.log('Store:', store);

if (window.location.pathname === '/silent_renew') {
  processSilentRenew();
} else {
  render(
    <Root
      store={store}
      history={history}
    />,
    document.getElementById('react-root'),
  );
}
