import React from 'react';
import { Desktop } from '../general/MediaQuery';
import LinkSection from './LinkSection';

const Home = () => {
  return (
    <div className="row no-gutters h-100">
      <LinkSection />
      <Desktop>
        <div className="col bg-body-bg" />
      </Desktop>
    </div>
  );
};

export default Home;
