import { createBrowserHistory } from 'history';
import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import ReduxThunk from 'redux-thunk';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { reducer as permissionsReducer } from './PermissionStore';
import { reducer as localizationReducer } from './LocalizationStore';
import { reducer as userManagementReducer } from './UserManagementStore';
import { reducer as alertsReducer } from './AlertsStore';
import { reducer as fileTransferReducer } from './FileTransferStore';
import authReducer, { IAuthState } from './AuthStore';

export interface ApplicationState {
  permissions: any;
  localization: any;
  customOidc: any;
  userManagement: any;
  alerts: any;
  fileTransfer: any;
  auth: IAuthState;
}

export const history = createBrowserHistory();

export const reducers = {
  permissions: permissionsReducer,
  localization: localizationReducer,
  usermanagement: userManagementReducer,
  alerts: alertsReducer,
  fileTransfer: fileTransferReducer,
  auth: authReducer
};

const middlewares = [
  ReduxThunk,
  routerMiddleware(history),

];

const composeEnhancers = typeof window === 'object'
  && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares),
);

const rootReducer = combineReducers({
  ...reducers,
  router: connectRouter(history),
});


export const store = createStore(rootReducer, enhancer);

export type AppStore = typeof store;

