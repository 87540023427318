import download from 'downloadjs';
import logger from '../utils/logger';
import { AlertTypes } from './AlertsStore';
import { FileUploadStatus } from '../utils/enums';
import { queryStringFromObject } from '../utils/queryStringBuilder';
import strings from '../utils/strings';
import api from '../utils/ApiUtils';
import { getClientsWithUploaderRole } from '../utils/fileServiceRoleChecker';

const siteUrl = `${window.location.protocol}//${window.location.host}`;

export const FileTransferTypes = Object.freeze({
  setGetFilesLoading: 'SET_GET_FILES_LOADING',
  setGetClientsLoading: 'SET_GET_CLIENTS_LOADING',
  setClients: 'SET_CLIENTS',
  setFiles: 'SET_FILES',
  setDownloadedBy: 'SET_DOWNLOADED_BY',
  addFilesToUpload: 'ADD_FILES_TO_UPLOAD',
  setFilesToUpload: 'SET_FILES_TO_UPLOAD',
  setClientForUploadFile: 'SET_CLIENT_FOR_UPLOAD_FILE',
  setFileUploadStatus: 'SET_FILE_UPLOAD_STATUS',
  setClientForAllFilesToUpload: 'SET_CLIENT_FOR_ALL_UPLOAD_FILES',
  setFilesBeingDownloaded: 'SET_FILES_BEING_DOWNLOADED',
  setUploadProgress: 'SET_UPLOAD_PROGRESS',
});

export const initialState = {
  getFilesLoading: false,
  getClientsLoading: false,
  clients: [],
  fileResponse: {},
  filesToUpload: [],
  filesBeingDownloaded: [],
};

export const actionCreators = {
  getFileTransferClients: () => (dispatch) => {
    dispatch({ type: FileTransferTypes.setGetClientsLoading, payload: true });
    api.get('api/files/clients').then((json) => {
      dispatch({ type: FileTransferTypes.setClients, payload: json });
    }).catch(() => {
      dispatch({ type: AlertTypes.setAlertText, payload: strings.errorFileTransferClientFetch });
      dispatch({ type: AlertTypes.setShowErrorAlert, payload: true });
      dispatch({ type: FileTransferTypes.setGetClientsLoading, payload: false });
    });
  },
  getClientFiles: (clientId, queryObject) => (dispatch) => {
    dispatch({ type: FileTransferTypes.setGetFilesLoading, payload: true });
    const query = queryStringFromObject(queryObject);
    api.get(`api/files/clients/${clientId}/files${query}`).then((json) => {
      dispatch({ type: FileTransferTypes.setFiles, payload: json });
    }).catch(() => {
      dispatch({ type: FileTransferTypes.setGetFilesLoading, payload: false });
      dispatch({ type: AlertTypes.setAlertText, payload: strings.errorFileTransferFileFetch });
      dispatch({ type: AlertTypes.setShowErrorAlert, payload: true });
    });
  },
  downloadFile: downloadUrl => (dispatch, getState) => {
    const headers = new Headers({
      Accept: 'application/octet-stream',
    });

    const newFiles = getState().fileTransfer.filesBeingDownloaded.concat(downloadUrl);
    dispatch({ type: FileTransferTypes.setFilesBeingDownloaded, payload: newFiles });

    const query = `?downloadUrl=${downloadUrl}`;
    api.get(`api/files/download${query}`, headers, 'blob').then((blob) => {
      // url is something like /clients/1/files/example.txt?folder=subfolder
      // so to get the actual filename we do some splitting and popping
      const fileName = downloadUrl.split('/').pop().split('?')[0];
      if (download(blob, fileName, 'application/octet-stream') === true) {
        const { emailaddress } = getState().auth.user;
        dispatch({ type: FileTransferTypes.setDownloadedBy, payload: { email: emailaddress, downloadUrl } });
        const newFilesBeingDownloaded = getState().fileTransfer.filesBeingDownloaded
          .filter(f => f !== downloadUrl);
        dispatch({
          type: FileTransferTypes.setFilesBeingDownloaded,
          payload: newFilesBeingDownloaded,
        });
      }
    }).catch(() => {
      dispatch({ type: AlertTypes.setAlertText, payload: strings.errorFileTransferDownload });
      dispatch({ type: AlertTypes.setShowErrorAlert, payload: true });
    });
  },
  addFilesToUpload: files => (dispatch, getState) => {
    const { policyApiPermissions } = getState().permissions;
    const clients = getClientsWithUploaderRole(policyApiPermissions);
    const checkForErrors = (file) => {
      const errors = [];
      if (file.size > 2147483648) errors.push(`${strings.fileSizeError} 2 GiB`);
      return errors;
    };
    const newFiles = files.map(file => (
      {
        file,
        clientId: clients[0],
        status: FileUploadStatus.Initial,
        errors: checkForErrors(file),
        progress: 0,
      }));
    dispatch({ type: FileTransferTypes.addFilesToUpload, payload: newFiles });
  },
  removeFileToUpload: fileName => (dispatch, getState) => {
    const newFiles = getState().fileTransfer.filesToUpload
      .filter(file => file.file.name !== fileName);
    // const { [fileName]: value, ...withFilenameRemoved } = getState().fileTransfer.filesToUpload;
    dispatch({ type: FileTransferTypes.setFilesToUpload, payload: newFiles });
  },
  uploadFile: filesToUpload => (dispatch) => {
    filesToUpload.forEach((fileToUpload) => {
      if (fileToUpload.status === FileUploadStatus.Finished
        || fileToUpload.status === FileUploadStatus.InProgress
        || fileToUpload.errors.length > 0) return;
      dispatch(
        {
          type: FileTransferTypes.setFileUploadStatus,
          payload: {
            fileName: fileToUpload.file.name,
            status: FileUploadStatus.InProgress,
          },
        },
      );
      const formData = new FormData();
      formData.append('file', fileToUpload.file);

      const request = new XMLHttpRequest();
      request.open('POST', `${siteUrl}/api/files/clients/${fileToUpload.clientId}/files`);

      request.upload.addEventListener('progress', (e) => {
        const progress = Math.floor((e.loaded / e.total) * 100);
        dispatch({
          type: FileTransferTypes.setUploadProgress,
          payload: { fileName: fileToUpload.file.name, progress },
        });
      });

      request.addEventListener('load', () => {
        if (request.status === 204) {
          dispatch(
            {
              type: FileTransferTypes.setFileUploadStatus,
              payload: {
                fileName: fileToUpload.file.name,
                status: FileUploadStatus.Finished,
              },
            },
          );
        } else {
          dispatch(
            {
              type: FileTransferTypes.setFileUploadStatus,
              payload: {
                fileName: fileToUpload.file.name,
                status: FileUploadStatus.Failed,
              },
            },
          );
        }
      });

      request.send(formData);
    });
  },
  setClientForUploadFile: (fileName, clientId) => (dispatch) => {
    dispatch({ type: FileTransferTypes.setClientForUploadFile, payload: { fileName, clientId } });
  },
  setClientForAllFilesToUpload: clientId => (dispatch) => {
    dispatch({ type: FileTransferTypes.setClientForAllFilesToUpload, payload: clientId });
  },
};

export const reducer = (state, action) => {
  const fileTransferStoreState = state || initialState;

  switch (action.type) {
    case FileTransferTypes.addFilesToUpload: {
      return {
        ...fileTransferStoreState,
        filesToUpload: fileTransferStoreState.filesToUpload.concat(action.payload),
      };
    }

    case FileTransferTypes.setFilesToUpload:
      // eslint-disable-next-line no-case-declarations
      return {
        ...fileTransferStoreState,
        filesToUpload: action.payload,
      };

    case FileTransferTypes.setGetFilesLoading: {
      return {
        ...fileTransferStoreState,
        getFilesLoading: action.payload,
      };
    }
    case FileTransferTypes.setGetClientsLoading: {
      return {
        ...fileTransferStoreState,
        getClientsLoading: action.payload,
      };
    }
    case FileTransferTypes.setClients: {
      return {
        ...fileTransferStoreState,
        clients: action.payload,
        getClientsLoading: false,
      };
    }
    case FileTransferTypes.setFiles: {
      return {
        ...fileTransferStoreState,
        fileResponse: action.payload,
        getFilesLoading: false,
      };
    }
    case FileTransferTypes.setDownloadedBy: {
      const { downloadUrl, email } = action.payload;
      return {
        ...fileTransferStoreState,
        fileResponse: {
          ...fileTransferStoreState.fileResponse,
          files: fileTransferStoreState.fileResponse.files.map((file) => {
            if (file.downloadUrl === downloadUrl) {
              return {
                ...file,
                lastDownloadedBy: email,
              };
            }
            return file;
          }),
        },
      };
    }
    case FileTransferTypes.setClientForUploadFile: {
      const { fileName, clientId } = action.payload;
      const newFilesToUpload = fileTransferStoreState.filesToUpload
        .map(fileToUpload => (fileToUpload.file.name === fileName
          ? ({ ...fileToUpload, clientId })
          : fileToUpload));
      return {
        ...fileTransferStoreState,
        filesToUpload: newFilesToUpload,
      };
    }
    case FileTransferTypes.setFileUploadStatus: {
      const { fileName, status } = action.payload;
      return {
        ...fileTransferStoreState,
        filesToUpload: fileTransferStoreState.filesToUpload
          .map(fileToUpload => (fileToUpload.file.name === fileName
            ? ({ ...fileToUpload, status })
            : fileToUpload)),
      };
    }
    case FileTransferTypes.setClientForAllFilesToUpload: {
      const clientId = action.payload;
      return {
        ...fileTransferStoreState,
        filesToUpload: fileTransferStoreState.filesToUpload
          .map(fileToUpload => ({ ...fileToUpload, clientId })),
      };
    }
    case FileTransferTypes.setFilesBeingDownloaded: {
      const files = action.payload;
      return {
        ...fileTransferStoreState,
        filesBeingDownloaded: files,
      };
    }
    case FileTransferTypes.setUploadProgress: {
      const { fileName, progress } = action.payload;
      return {
        ...fileTransferStoreState,
        filesToUpload: fileTransferStoreState.filesToUpload
          .map(fileToUpload => (fileToUpload.file.name === fileName
            ? ({ ...fileToUpload, progress })
            : fileToUpload)),
      };
    }
    default:
      return fileTransferStoreState;
  }
};
