import React from 'react';
import { connect } from 'react-redux';
import strings from '../../utils/strings';
import { useAuth } from '../../store/AuthStore';
import { logout } from '../../utils/authUtils';


function UserInformationSection() {
  const { user } = useAuth();

  function signOutClicked(e) {
    if (e.key === undefined || e.keyCode === 13) {
      logout();
    }
  }

  return user && (
    <div style={{ marginTop: '100px' }}>
      <div style={{ marginBottom: '10px' }}>{user.name}</div>
      <span
        style={{ fontSize: '18px', fontWeight: 'bold' }}
        className="clickable d-inline-block"
        onKeyDown={(e) => signOutClicked(e)}
        onClick={(e) => signOutClicked(e)}
        role="button"
        tabIndex="0"
      >{strings.signOut}</span>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    localization: state.localization,
  };
}

export default connect(
  mapStateToProps,
)(UserInformationSection);
