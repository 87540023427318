export const configEnvironments = Object.freeze({
  Production: 'Production',
  Development: 'Development',
  Staging: 'Staging',
});

export const FileUploadStatus = Object.freeze({
  Initial: 0,
  InProgress: 1,
  Finished: 2,
  Failed: 3,
});
