const sv = {
  dataProtection: 'Dataskydd',
  dataProtectionTitle: 'Dataskyddsuppgifter',
  dataProtectionContent: `Vi behandlar och lagrar personuppgifter avseende kontaktpersoner på våra kundföretag då vi tillhandahåller våra tjänster. Personuppgiftsansvarig är Lowell Sverige AB. Vi använder uppgifterna för kundservice, kundrelationer och marknadsföring av tjänster.

Grunden för behandling av uppgifter är iakttagande av lagstadgade skyldigheter i anknytning till indrivning samt Lowells s.k. berättigade intressen, till exempel att tillhandahålla kunderna överenskomna tjänster. Vi lämnar inte ut uppgifter till tredje part.

Mer information om hur du kan kontrollera dina uppgifter, dina övriga rättigheter och behandling av personuppgifter finns på:`,
  contactUs: 'Kontakta oss',
  contactUsSubText: 'Kundtjänst svarar på eventuella frågor.',
  contactUsSubTitle: 'Kontaktinformation',
  youWereSignedOut: 'Du har blivit utloggad',
  clickButtonToSignInAgain: 'Tryck på knappen nedan för att logga in igen',
  signIn: 'Logga in',
  signOut: 'Logga ut',
  welcome: 'Välkommen till Lowell Access!',
  yourAvailableServices: 'Här är tjänsterna som är tillgängliga för dig',
  privacyPolicy: 'Integritetspolicy',
  selectLanguage: 'Välj språk',
  home: 'Startsida',
  adminPanel: 'Användare',

  // Services
  collection: 'Inkasso',
  monitorService: 'Spårningstjänst',
  creditDecision: 'Kreditinformationstjänst',
  informationServices: 'Information Services',
  dashboard: 'Dashboards',
  invoiceWeb: 'Faktura',
  handyman: 'Handyman',
  collectionServices: 'Inkasso',
  invoiceServices: 'Fakturaservice',

  // Countries
  denmark: 'Danmark',
  estonia: 'Estland',
  finland: 'Finland',
  sweden: 'Sverige',
  norway: 'Norge',

  // Languages
  danish: 'danska',
  estonian: 'estniska',
  finnish: 'finska',
  norwegian: 'norska',
  swedish: 'svenska',

  finlandExtraPhoneInfo: '(Från fast anslutning 8,28 cent + 5,95 cent/minut, från mobiltelefon 8,28 cent + 17,04 cent/minut)',
  finlandOpenTimes: 'Måndag-torsdag kl. 8-17, fredag kl. 8-16',

  // User deletion modal
  deleteUserModalTitle: 'Tar bort användare',
  deleteUserModalContent: 'Den här användaren kommer att tas bort permanent. Vill du fortsätta?',
  deleteUserModalRemoveUser: 'Ta bort användaren',

  // Forms
  save: 'Spara',
  edit: 'Redigera',
  cancel: 'Avbryt',
  firstName: 'Förnamn',
  lastName: 'Efternamn',
  email: 'E-post',
  language: 'Språk',
  country: 'Land',

  search: 'Sök användare',
  searchAsset: 'Sök',

  // Add user form
  addUserFormTitle: 'Lägg till ny användare',
  addUserDescription: `Vänligen fyll i alla fält nedan.
  När du skapar en ny användare för företaget, skickar vi ett välkomstmeddelande till e-postadressen du angett nedan. Vi ber dig notera att detta meddelande inte skickas, om e-postadressen redan finns lagrad i Lowells system.`,
  addUserAdminCheckboxText: 'Administratör',

  // Users
  usersTableHeaderName: 'Namn',
  usersTableHeaderLogin: 'Senaste inloggning',

  // Edit user form
  editUserDelete: 'Ta bort användaren',
  editUserTitle: 'Grundläggande information',
  editInformation: 'Ändra informationen',
  emailEditTooltip: 'E-postadressen används som identifikation och kan inte ändras.',

  // Error messages
  errorFetchUsers: 'Misslyckades med att hämta användarlistan, var god försök igen.',
  errorPostUser: 'Misslyckades med att lägga till den nya användaren, försök igen.',
  errorDeleteUser: 'Misslyckades att ta bort användaren, försök igen.',
  errorEditName: 'Misslyckades med att redigera användarnamnet, försök igen.',
  errorEditLanguage: 'Misslyckades med att ändra användarspråket, försök igen.',
  errorFetchAssets: 'Misslyckades med att hämta portfölj/avdelning och rättigheter.',
  errorFetchPermissions: 'Misslyckades med att hämta rättigheter.',
  errorAddAssetPermissions: 'Misslyckades med att lägga till rättigheter.',
  errorDeleteAssetPermissions: 'Misslyckades med att ta bort rättigheter.',
  errorExportUsers: 'Misslyckades med att exportera användarlistan till Excel.',
  errorAdminChange: 'Failed to modify admin rights.',
  errorEditUser: 'Mislyckades med att ändra på användarinformationen.',
  errorFileTransferClientFetch: 'Misslyckades att hämta klientinformation.',
  errorFileTransferFileFetch: 'Misslyckades att hämta listan av filer.',
  errorFileTransferDownload: 'Misslyckades att ladda ned filen.',

  // Success messages
  successPostUser: 'Användaren har lagts till.',
  successDeleteUser: 'Användaren har tagits bort.',
  successEditUserName: 'Användarnamnet har ändrats.',
  successEditUserLanguage: 'Användarspråket har ändrats.',
  successExportUsers: 'Användarlistan har exporterats till Excel.',
  successAdminChange: 'Successfully modified admin rights.',
  successEditUser: 'Användainformationen har ändrats.',

  allClients: 'All clients',

  assetPermissions: 'Asset permissions',
  asset: 'Kund',
  id: 'Id',
  client: 'Klient',
  assetName: 'Namn',
  permissions: 'Rättigheter',

  export: 'Exportera alla användare och rättigheter till Excel.',

  yes: 'Ja',
  no: 'Nej',

  formValidationMandatory: 'Obligatoriskt fält',
  formValidationInvalidEmail: 'Ogiltig email adress',
  assetsNotFound: 'Inga resultat.',
  usersNotFound: 'Användare ej funnen.',

  // Permissions
  permissionRead: 'Läs',
  permissionWrite: 'Skriv',
  'permissionRead Write': 'Skriv',
  permissionReporting: 'Rapporter',
  permissionAllowed: 'Tillåten',
  permissionUSER: 'Tillåten',
  permissionCLI: 'Skriv',
  permissionR: 'Läs',
  permissionREADONLY: 'Enbart läsbehörighet',
  permissionREPORTS: 'Åtkomst till rapporter',
  permissionINVOICES: 'Åtkomst till klientfakturor',
  permissionNTFYRPRTS: 'Notifiering om nya rapporter',
  permissionNTFYTASKS: 'Notifiering om nya åtgärder',

  // Help modal
  help: 'Anvisningar',
  helpModalText: 'Här hittar du systemets användarguide. Du behöver Adobe Acrobat Reader eller motsvarande pdf-läsare för att öppna filen.',
  helpModalDownload: 'Ladda ned användarguiden',

  readMore: 'Läs vidare',
  showLess: 'Visa mindre',

  news: 'Nyheter och uppdateringar',

  // File transfer UI
  fileTransfer: 'Fil transfer',
  download: 'Ladda ner',
  upload: 'Ladda upp',
  searchFiles: 'Söka filer',
  submit: 'Skicka',
  apply: 'Verkställ',
  emailFilterNotLimited: 'Filerna är inte begränsade till någon e-postadress',
  emailFilterEnterEmail: 'Skriv e-postadress för begränsning',
  emailFilterLimitedTo: 'Filerna är begränsade till:',
  emailFilterRemoveLimitation: 'Ta bort begränsning',
  emailFilterAnotherEmail: 'Se filer för en annan emailadress e-postadress',

  downloaded: 'Nedladdad',
  notDownloaded: 'Ej nedladdad',
  status: 'Status',
  uploadDate: 'Utgivningsdatum',
  downloadedBy: 'Nedladdad av',
  noFilesFound: 'Inga filer hittades',

  inProgress: 'Överföring pågår',
  success: 'Överöring lyckades',
  failed: 'Överöring misslyckades',
  readyForSubmission: 'Redo för överföring',
  dragndropTutorial: 'Dra och släpp filer här eller klicka för att välja filer',
  actions: 'Aktioner',
  downloadSelected: 'Ladda ned valda',

  fileSizeError: 'Filstorleken överskrider',

  // Easy banner
  easyTryText: 'Du kan hantera inkassoärenden ännu enklare i vår nya tjänst! Vill du prova Lowell Easy?',
  takeMeThere: '(ta mig dit)',
  closeBanner: '(stäng meddelandet)',

  // Dashboard
  collectionDashboard: 'Inkasso',
  invoiceDashboard: 'Faktura',

  // No mobile page
  pleaseViewOnDesktop: 'Den här sidan visas bäst på en datorskärm.',
  backToHomePage: 'Tillbaka till startsidan',

  // DISCO announcement filter
  filter: 'Filter',
  all: 'Alla',

  // DISCO apps
  'disco_CollectionWeb Denmark': 'Inkasso Danmark',
  'disco_CollectionWeb Finland': 'Inkasso Finland',
  'disco_CollectionWeb Norway': 'Inkasso Norge',
  'disco_CollectionWeb Sweden': 'Inkasso Sverige',
  'disco_InvoiceWeb': 'Faktura',
  'disco_Credit Decision': 'Kreditinformation',
  'disco_Company Monitor': 'Spårningstjänst',

  new: 'Ny'
};

export default sv;
