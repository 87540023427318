import classNames from 'classnames';
import {
  arrayOf,
  bool,
  shape,
  string,
} from 'prop-types';
import React from 'react';
import {
  useLocation,
} from 'react-router';
import { Link } from 'react-router-dom';

const NavigationLink = ({
  route,
  text,
  exact,
  small,
  sublinks,
}) => {
  const { pathname } = useLocation();
  const active = exact ? pathname === route : pathname.includes(route);

  return (
    <div className={classNames(!small && 'mb-18')}>
      <div
        className={classNames(
          'd-inline-block clickable nav-link',
          small && 'sub-nav-link',
          active && 'active',
        )}
        data-testid={route}
      >
        <Link to={!active ? route : pathname}>
          {text}
        </Link>
      </div>
      {active && sublinks.length > 0 && sublinks.map(sl => (
        <NavigationLink
          key={sl.route}
          route={sl.route}
          text={sl.text}
          exact={sl.exact}
          small
        />
      ))}
    </div>
  );
};

NavigationLink.propTypes = {
  route: string.isRequired,
  text: string.isRequired,
  exact: bool,
  small: bool,
  sublinks: arrayOf(shape({
    route: string.isRequired,
    text: string.isRequired,
    exact: bool.isRequired,
  })),
};

NavigationLink.defaultProps = {
  exact: false,
  small: false,
  sublinks: [],
};

export default NavigationLink;
