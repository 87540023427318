import download from 'downloadjs';
import logger from '../utils/logger';
import { AlertTypes } from './AlertsStore';
import strings from '../utils/strings';
import { allCoreSystems } from '../utils/coreSystemHandling';
import api from '../utils/ApiUtils';

const siteUrl = `${window.location.protocol}//${window.location.host}`;

export const UserManagementTypes = Object.freeze({
  setUserManagementApiLoading: 'SET_USERMANAGEMENT_API_LOADING',
  setUserManagementDeleteUserLoading: 'SET_USERMANAGEMENT_DELETE_LOADING',
  setUserManagementPostUserLoading: 'SET_USERMANAGEMENT_POST_LOADING',
  setUserManagementEditUserLoading: 'SET_USERMANAGEMENT_EDIT_USER_LOADING',
  updateUserManagementType: 'UPDATE_USERMANAGEMENT',
  setSelectedUser: 'SET_SELECTED_USER',
  updateAssets: 'UPDATE_ASSETS',
  updateAvailableUserRoles: 'UPDATE_AVAILABLE_USER_ROLES',
  setUserPermissionsLoading: 'SET_USER_PERMISSIONS_LOADING',
  setAvailableUserRolesLoading: 'SET_AVAILABLE_USER_ROLES_LOADING',
  setUserPermissions: 'SET_USER_PERMISSIONS',
  setModifyUserPermissionsLoading: 'SET_MODIFY_USER_PERMISSIONS_LOADING',
  setUserExportLoading: 'SET_USER_EXPORT_LOADING',
  setUserRoles: 'SET_USER_ROLES',
  setUserRolesLoading: 'SET_USER_ROLES_LOADING',
});


const initialState = {
  userManagementApiLoading: false,
  userManagementDeleteUserLoading: false,
  userManagementPostUserLoading: false,
  userManagementEditUserLoading: false,
  usermanagementInfo: [],
  selectedUser: {
    Email: '',
  },
  assetsAndPermissions: [],
  availableUserRoles: [],
  userPermissionsLoading: false,
  availableUserRolesLoading: false,
  userPermissions: null,
  modifyUserPermissionsLoading: false,
  userExportLoading: false,
  userRoles: null,
  userRolesLoading: false,
};

export const actionCreators = {
  exportUserList: clients => (dispatch) => {
    dispatch({ type: UserManagementTypes.setUserExportLoading, payload: true });
    const query = JSON.stringify(clients.map(client => client.clientId));
    const headers = new Headers({
      'Content-type': 'application/csv',
    });
    return api.get(`selfservice-api/users/export?clientIds=${query}`, headers, 'blob').then((blob) => {
      const today = new Date();
      const report = `user-export-${today.getFullYear()}-${((today.getMonth() + 1) < 10) ? (`0${today.getMonth() + 1}`) : (today.getMonth() + 1)}-${today.getDate()}.xlsx`;
      download(blob, report, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      dispatch({ type: AlertTypes.setAlertText, payload: strings.successExportUsers });
      dispatch({ type: AlertTypes.setShowSuccessAlert, payload: true });
      dispatch({ type: UserManagementTypes.setUserExportLoading, payload: false });
    }).catch(() => {
      dispatch({ type: AlertTypes.setAlertText, payload: strings.errorExportUsers });
      dispatch({ type: AlertTypes.setShowErrorAlert, payload: true });
      dispatch({ type: UserManagementTypes.setUserExportLoading, payload: false });
    });
  },
  fetchUsersFromMultipleClients: clients => (dispatch, getState) => {
    dispatch({ type: UserManagementTypes.setUserManagementApiLoading, payload: true });
    const query = JSON.stringify(clients.map(client => client.clientId));
    const { availableClients } = getState().permissions;
    return api.get(`selfservice-api/clients/users?clientIds=${query}`).then((json) => {
      const distinct = (value, self) => self
        .filter((user, idx, arr) => arr
          .map(u => u[value])
          .indexOf(user[value]) === idx);

      const groupedUsersByEmail = json.map(user => ({
        Email: user.Email,
        ClientIds: (json
          .filter(u => user.Email === u.Email).map(emailMatch => emailMatch.ClientId)),
      }));
      const usersThatAreNotInAllClients = distinct('Email', json)
        .filter(user => groupedUsersByEmail
          .filter(groupedUser => !availableClients
            .map(ac => ac.clientId)
            .every(val => groupedUser.ClientIds.includes(val)))
          .find(u => u.Email === user.Email));
      const usersNotInAllClientsAdministratorSetToFalse = usersThatAreNotInAllClients.map(
        user => ({ ...user, Administrator: false }),
      );

      const usersThatAreInAllClients = json
        .filter(u => !usersNotInAllClientsAdministratorSetToFalse
          .map(adminFalse => adminFalse.Email)
          .includes(u.Email));
      const falseAdminList = distinct('Email', usersThatAreInAllClients
        .filter((user, idx, arr) => arr
          .some(() => !user.Administrator)))
        .concat(usersNotInAllClientsAdministratorSetToFalse);
      const trueAdminList = distinct('Email', json)
        .filter(u => !falseAdminList
          .map(adminFalse => adminFalse.Email)
          .includes(u.Email));

      const userList = trueAdminList.concat(falseAdminList);
      dispatch({ type: UserManagementTypes.updateUserManagementType, payload: userList });
      dispatch({ type: UserManagementTypes.setUserManagementApiLoading, payload: false });
    }).catch((e) => {
      logger.error(e);
      dispatch({ type: UserManagementTypes.setUserManagementApiLoading, payload: false });
      dispatch({ type: AlertTypes.setAlertText, payload: strings.errorFetchUsers });
      dispatch({ type: AlertTypes.setShowErrorAlert, payload: true });
    });
  },
  postUser: (clientIdList, userInfo) => (dispatch, getState) => {
    const clientIds = JSON.stringify(clientIdList.map(client => client.clientId));
    const url = (userInfo && userInfo.Administrator) ? `selfservice-api/clients/users?clientIds=${clientIds}` : `selfservice-api/clients/${clientIdList[0].clientId}/users`;
    const usermanagementInfo = getState().usermanagement.usermanagementInfo.slice();
    dispatch({ type: UserManagementTypes.setUserManagementPostUserLoading, payload: true });
    return api.post(url, JSON.stringify(userInfo)).then((json) => {
      const responseBody = json;
      let user = null;
      if (Array.isArray(responseBody)) {
        [user] = responseBody;
      } else {
        user = responseBody;
      }
      usermanagementInfo.push(user);
      dispatch({ type: AlertTypes.setAlertText, payload: strings.successPostUser });
      dispatch({ type: AlertTypes.setShowSuccessAlert, payload: true });
      dispatch({ type: UserManagementTypes.updateUserManagementType, payload: usermanagementInfo });
      dispatch({ type: UserManagementTypes.setUserManagementPostUserLoading, payload: false });
    }).catch((e) => {
      dispatch({ type: AlertTypes.setAlertText, payload: strings.errorPostUser });
      dispatch({ type: AlertTypes.setShowErrorAlert, payload: true });
      dispatch({ type: UserManagementTypes.setUserManagementPostUserLoading, payload: false });
      throw e;
    });
  },
  editUser: (userInfo, clients) => (dispatch, getState) => {
    dispatch({ type: UserManagementTypes.setUserManagementEditUserLoading, payload: true });
    const clientIds = clients.map(client => client.clientId);
    const body = {
      email: userInfo.Email,
      language: userInfo.Language,
      firstName: userInfo.FirstName,
      lastName: userInfo.LastName,
      administrator: userInfo.Administrator,
      clientIds,
    };
    if (userInfo.FirstName.length === 0) delete body.firstName;
    if (userInfo.LastName.length === 0) delete body.lastName;
    if (userInfo.Language.length === 0) delete body.language;
    if (userInfo.Administrator === null) delete body.administrator;

    return api.post('selfservice-api/clients/users/edit', JSON.stringify(body)).then((json) => {
      const result = json;
      const users = [...getState().usermanagement.usermanagementInfo];
      const editedUsers = users.map((user) => {
        if (user.Email === result.Email) {
          return {
            ...user,
            FirstName: result.FirstName || user.FirstName,
            LastName: result.LastName || user.LastName,
            Language: result.Language || user.Language,
            Administrator: result.Administrator !== undefined
              ? result.Administrator
              : user.Administrator,
          };
        }
        return user;
      });
      dispatch({ type: AlertTypes.setAlertText, payload: strings.successEditUser });
      dispatch({ type: AlertTypes.setShowSuccessAlert, payload: true });
      dispatch({ type: UserManagementTypes.updateUserManagementType, payload: editedUsers });
      dispatch({ type: UserManagementTypes.setUserManagementEditUserLoading, payload: false });
    }).catch((e) => {
      logger.error('UserManagement editUser error:', e);
      dispatch({ type: AlertTypes.setAlertText, payload: strings.errorEditUser });
      dispatch({ type: AlertTypes.setShowErrorAlert, payload: true });
      dispatch({ type: UserManagementTypes.setUserManagementEditUserLoading, payload: false });
      throw e;
    });
  },
  setUserInputs: user => (dispatch) => {
    dispatch({ type: UserManagementTypes.setSelectedUser, payload: user });
  },
  fetchAvailableUserRolesFromMultipleClients: clients => (dispatch) => {
    dispatch({ type: UserManagementTypes.setAvailableUserRolesLoading, payload: true });
    const query = JSON.stringify(clients.map(c => c.clientId));
    return api.get(`selfservice-api/clients/roles?clientIds=${query}`).then((json) => {
      const roles = json.reverse();
      dispatch({ type: UserManagementTypes.updateAvailableUserRoles, payload: roles });
    }).catch((e) => {
      logger.log(e);
    })
      .finally(() => {
        dispatch({ type: UserManagementTypes.setAvailableUserRolesLoading, payload: false });
      });
  },
  fetchAssetsAndPermissionsFromMultipleClients: clients => (dispatch) => {
    dispatch({ type: UserManagementTypes.setUserManagementApiLoading, payload: true });
    const allowedCoreSystems = allCoreSystems;
    const query = JSON.stringify(clients.map(client => client.clientId));
    return api.get(`selfservice-api/clients/permissions?clientIds=${query}`)
    .then((json) => {
      const filteredAssets = json
        .filter(a => a.assetPermission
          .find(ap => allowedCoreSystems.find(ac => ap.AssetId.includes(ac))));
      dispatch({ type: UserManagementTypes.updateAssets, payload: filteredAssets });
      dispatch({ type: UserManagementTypes.setUserManagementApiLoading, payload: false });
    }).catch(() => {
      dispatch({ type: UserManagementTypes.setUserManagementApiLoading, payload: false });
      dispatch({ type: AlertTypes.setAlertText, payload: strings.errorFetchPermissions });
      dispatch({ type: AlertTypes.setShowErrorAlert, payload: true });
    });
  },
  fetchUserPermissions: (clients, user) => (dispatch) => {
    dispatch({ type: UserManagementTypes.setUserPermissionsLoading, payload: true });
    const clientsQuery = JSON.stringify(clients.map(client => client.clientId));
    return api.get(`selfservice-api/clients/user/permissions?clientIds=${clientsQuery}&user=${user}`).then((json) => {
      dispatch({ type: UserManagementTypes.setUserPermissions, payload: json });
      dispatch({ type: UserManagementTypes.setUserPermissionsLoading, payload: false });
    }).catch(() => {
      dispatch({ type: UserManagementTypes.setUserPermissionsLoading, payload: false });
      dispatch({ type: AlertTypes.setAlertText, payload: strings.errorFetchPermissions });
      dispatch({ type: AlertTypes.setShowErrorAlert, payload: true });
    });
  },
  addUserPermission: request => (dispatch, getState) => {
    dispatch({ type: UserManagementTypes.setModifyUserPermissionsLoading, payload: true });
    const userPermissions = [...getState().usermanagement.userPermissions];
    const clientIds = JSON.stringify([...new Set(request.map(c => c.clientId))]);
    return api.post(`selfservice-api/clients/user/permissions?clientIds=${clientIds}`, JSON.stringify(request)).then((json) => {
      const newPermissions = userPermissions.concat(json);
      dispatch({ type: UserManagementTypes.setUserPermissions, payload: newPermissions });
      dispatch({ type: UserManagementTypes.setModifyUserPermissionsLoading, payload: false });
    }).catch(() => {
      dispatch({ type: AlertTypes.setAlertText, payload: strings.errorAddAssetPermissions });
      dispatch({ type: AlertTypes.setShowErrorAlert, payload: true });
      dispatch({ type: UserManagementTypes.setModifyUserPermissionsLoading, payload: false });
    });
  },
  deleteUserPermission: request => (dispatch, getState) => {
    dispatch({ type: UserManagementTypes.setModifyUserPermissionsLoading, payload: true });
    const clientIds = JSON.stringify([...new Set(request.map(c => c.clientId))]);
    return api.delete(`selfservice-api/clients/user/permissions?clientIds=${clientIds}`, JSON.stringify(request)).then((json) => {
      const result = json;
      const newPermissions = getState().usermanagement.userPermissions
        .filter(up => result
          .find(r => up.assetId === r.assetId && up.permission === r.permission) === undefined);
      dispatch({ type: UserManagementTypes.setUserPermissions, payload: newPermissions });
      dispatch({ type: UserManagementTypes.setModifyUserPermissionsLoading, payload: false });
    }).catch(() => {
      dispatch({
        type: AlertTypes.setAlertText,
        payload: strings.errorDeleteAssetPermissions,
      });
      dispatch({ type: AlertTypes.setShowErrorAlert, payload: true });
      dispatch({ type: UserManagementTypes.setModifyUserPermissionsLoading, payload: false });
    });
  },
  massDeleteUser: (clients, email) => (dispatch, getState) => {
    dispatch({ type: UserManagementTypes.setUserManagementDeleteUserLoading, payload: true });
    const clientsQuery = JSON.stringify(clients.map(client => client.clientId));
    return api.delete(`selfservice-api/clients/users/${email}?clientIds=${clientsQuery}`).then((json) => {
      const deletedUsers = json;
      const newUsers = getState().usermanagement.usermanagementInfo
        .filter(user => deletedUsers.find(u => u === user.Id) === undefined);
      dispatch({ type: UserManagementTypes.updateUserManagementType, payload: newUsers });
      dispatch({ type: UserManagementTypes.setUserManagementDeleteUserLoading, payload: false });
      dispatch({ type: AlertTypes.setAlertText, payload: strings.successDeleteUser });
      dispatch({ type: AlertTypes.setShowSuccessAlert, payload: true });
      dispatch({ type: UserManagementTypes.setSelectedUser, payload: initialState.selectedUser });
    }).catch(() => {
      dispatch({ type: UserManagementTypes.setUserManagementDeleteUserLoading, payload: false });
      dispatch({ type: AlertTypes.setAlertText, payload: strings.errorDeleteUser });
      dispatch({ type: AlertTypes.setShowErrorAlert, payload: true });
    });
  },
  fetchUserRoles: (clients, email) => (dispatch) => {
    dispatch({ type: UserManagementTypes.setUserRolesLoading, payload: true });
    const clientsQuery = JSON.stringify(clients.map(client => client.clientId));
    return api.get(`selfservice-api/clients/users/${email}/roles?clientIds=${clientsQuery}`).then((json) => {
      const roles = json;
      dispatch({ type: UserManagementTypes.setUserRoles, payload: roles });
    }).catch(() => {
      dispatch({ type: AlertTypes.setAlertText, payload: strings.errorFetchPermissions });
      dispatch({ type: AlertTypes.setShowErrorAlert, payload: true });
    })
      .finally(() => {
        dispatch({ type: UserManagementTypes.setUserRolesLoading, payload: false });
      });
  },
  addUserRole: request => (dispatch, getState) => {
    dispatch({ type: UserManagementTypes.setModifyUserPermissionsLoading, payload: true });
    const { userRoles } = getState().usermanagement;
    return api.post('selfservice-api/clients/user/roles', JSON.stringify(request)).then((json) => {
      dispatch({ type: UserManagementTypes.setUserRoles, payload: userRoles.concat(json) });
    }).catch(() => {
      dispatch({ type: AlertTypes.setAlertText, payload: strings.errorAddAssetPermissions });
      dispatch({ type: AlertTypes.setShowErrorAlert, payload: true });
    })
      .finally(() => {
        dispatch({ type: UserManagementTypes.setModifyUserPermissionsLoading, payload: false });
      });
  },
  deleteUserRole: request => (dispatch, getState) => {
    dispatch({ type: UserManagementTypes.setModifyUserPermissionsLoading, payload: true });
    const { userRoles } = getState().usermanagement;
    return api.delete('selfservice-api/clients/user/roles', JSON.stringify(request)).then((json) => {
      const newRoles = userRoles.filter(ur => !(json.ClientId === ur.ClientId
        && json.RoleId === ur.RoleId
        && json.CoreSystemId === ur.CoreSystemId));
      dispatch({ type: UserManagementTypes.setUserRoles, payload: newRoles });
    }).catch(() => {
      dispatch({ type: AlertTypes.setAlertText, payload: strings.errorDeleteAssetPermissions });
      dispatch({ type: AlertTypes.setShowErrorAlert, payload: true });
    })
      .finally(() => {
        dispatch({ type: UserManagementTypes.setModifyUserPermissionsLoading, payload: false });
      });
  },
};

export const reducer = (state, action) => {
  const userManagementState = state || initialState;

  switch (action.type) {
    case UserManagementTypes.setUserManagementApiLoading:
      return { ...userManagementState, userManagementApiLoading: action.payload };
    case UserManagementTypes.setUserManagementPostUserLoading:
      return { ...userManagementState, userManagementPostUserLoading: action.payload };
    case UserManagementTypes.setUserManagementEditUserLoading:
      return { ...userManagementState, userManagementEditUserLoading: action.payload };
    case UserManagementTypes.setUserManagementDeleteUserLoading:
      return { ...userManagementState, userManagementDeleteUserLoading: action.payload };
    case UserManagementTypes.updateUserManagementType:
      return { ...userManagementState, usermanagementInfo: action.payload };
    case UserManagementTypes.setSelectedUser:
      return { ...userManagementState, selectedUser: action.payload };
    case UserManagementTypes.updateAssets:
      return { ...userManagementState, assetsAndPermissions: action.payload };
    case UserManagementTypes.setUserPermissionsLoading:
      return { ...userManagementState, userPermissionsLoading: action.payload };
    case UserManagementTypes.setUserPermissions:
      return { ...userManagementState, userPermissions: action.payload };
    case UserManagementTypes.setModifyUserPermissionsLoading:
      return { ...userManagementState, modifyUserPermissionsLoading: action.payload };
    case UserManagementTypes.setUserExportLoading:
      return { ...userManagementState, userExportLoading: action.payload };
    case UserManagementTypes.updateAvailableUserRoles:
      return { ...userManagementState, availableUserRoles: action.payload };
    case UserManagementTypes.setAvailableUserRolesLoading:
      return { ...userManagementState, availableUserRolesLoading: action.payload };
    case UserManagementTypes.setUserRoles:
      return { ...userManagementState, userRoles: action.payload };
    case UserManagementTypes.setUserRolesLoading:
      return { ...userManagementState, userRolesLoading: action.payload };
    default:
      return userManagementState;
  }
};
