import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as localizationActionCreators } from '../../store/LocalizationStore';
import strings from '../../utils/strings';

export const ContactSection = ({
  availableClients,
  assetsAndPermissions,
  policyApiCoreSystems,
}) => (
  <div className="nav flex-column">
    <div className="nav-item mb-18">
      <button
        className="nav-link d-inline-block clickable"
        data-toggle="modal"
        data-target="#contactUsModal"
      >
        {strings.contactUs}
      </button>
    </div>
    {((availableClients.length > 0 && assetsAndPermissions.length > 0) || policyApiCoreSystems.includes('FILESERVICE')) && (
      <div className="nav-item mb-18">
        <button
          className="nav-link d-inline-block clickable"
          data-toggle="modal"
          data-target="#helpModal"
          data-testid="helpLink"
        >
          {strings.help}
        </button>
      </div>
    )}
  </div>
);

ContactSection.propTypes = {
  availableClients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  assetsAndPermissions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  policyApiCoreSystems: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function mapStateToProps(state) {
  return {
    localization: state.localization,
    availableClients: state.permissions.availableClients,
    assetsAndPermissions: state.usermanagement.assetsAndPermissions,
    policyApiCoreSystems: state.permissions.policyApiCoreSystems,
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...localizationActionCreators,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactSection);
