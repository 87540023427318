const en = {
  dataProtection: 'Data protection',
  dataProtectionContent: `We process and save personal data pertaining to our customer companies’ contact persons when we produce our services. Lowell companies are processing your data as data controllers. The purpose of the processing is delivering customer service, managing customer relationships and marketing our services.

    The processing of data is based on compliance with statutory obligations related to debt collection as well as the provision of Lowell's legitimate interests, such as services agreed upon, for customers. We do not disclose any data to third parties.
    
    More information about the right to view your data, your other rights and the processing of personal data is available at:`,
  contactUs: 'Contact us',
  contactUsSubText: 'Customer service will assist you with possible questions.',
  contactUsSubTitle: 'Contact information',
  youWereSignedOut: 'You were signed out',
  clickButtonToSignInAgain: 'Click the button below to sign in again',
  signIn: 'Sign in',
  signOut: 'Sign out',
  welcome: 'Welcome to Lowell Access!',
  yourAvailableServices: 'Here are our services available to you',
  privacyPolicy: 'Privacy policy',
  selectLanguage: 'Select language',
  home: 'Home',
  reports: 'Reports',
  adminPanel: 'Users',

  // Services
  collection: 'Collection',
  monitorService: 'Monitor Service',
  creditDecision: 'Credit Information Service',
  informationServices: 'Information Services',
  dashboard: 'Dashboards',
  invoiceWeb: 'Invoice',
  handyman: 'Handyman',
  collectionServices: 'Collection Services',
  invoiceServices: 'Invoice Services',

  // Countries
  denmark: 'Denmark',
  estonia: 'Estonia',
  finland: 'Finland',
  sweden: 'Sweden',
  norway: 'Norway',

  // Languages
  danish: 'Danish',
  estonian: 'Estonian',
  finnish: 'Finnish',
  norwegian: 'Norwegian',
  swedish: 'Swedish',

  finlandExtraPhoneInfo: '+358 10 2700 534 (Land line 8,28 c + 5,95 c/min, mobile phone 8,28 c + 17,04 c/min)',
  finlandOpenTimes: 'Mon–Thu 8–17, Fri 8–16',

  // User deletion modal
  deleteUserModalTitle: 'Removing user',
  deleteUserModalContent: 'This user is going to be removed permanently. Do you want to continue?',
  deleteUserModalRemoveUser: 'Remove the user',

  // Forms
  save: 'Save',
  edit: 'Edit',
  cancel: 'Cancel',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  language: 'Language',
  country: 'Country',

  search: 'Search user',
  searchAsset: 'Search',

  // Add user form
  addUserFormTitle: 'Add new user',
  addUserDescription: `Please fill all the fields below.
  When creating a new user for your company, an invitation message is sent to the email address given below. If the email address already exists in Lowell’s system, no message will be sent.`,
  addUserAdminCheckboxText: 'Admin user',

  // Users
  usersTableHeaderName: 'Name',
  usersTableHeaderLogin: 'Last login',

  // Edit user form
  editUserDelete: 'Remove user',
  editUserTitle: 'Basic information',
  editInformation: 'Edit information',
  emailEditTooltip: 'Email is used as an identifier and cannot be edited.',

  // Error messages
  errorFetchUsers: 'Failed to fetch the user list, please try again.',
  errorPostUser: 'Failed to add the new user, please try again.',
  errorDeleteUser: 'Failed to delete the user, please try again.',
  errorEditName: 'Failed to edit the user name, please try again.',
  errorEditLanguage: 'Failed to change the user language, please try again.',
  errorFetchAssets: 'Failed to fetch portfolios/departments and permissions.',
  errorFetchPermissions: 'Failed to fetch permissions.',
  errorAddAssetPermissions: 'Failed to add permissions.',
  errorDeleteAssetPermissions: 'Failed to remove permissions.',
  errorExportUsers: 'Failed to export the user list to Excel.',
  errorAdminChange: 'Failed to modify admin rights.',
  errorEditUser: 'Failed to edit user information.',
  errorFetchRoles: 'Failed to fetch user roles.',
  errorAddUserRole: 'Failed to add a role.',
  errorDeleteUserRole: 'Failed to delete a role.',
  errorFileTransferClientFetch: 'Failed to fetch File Transfer client information.',
  errorFileTransferFileFetch: 'Failed to fetch file list.',
  errorFileTransferDownload: 'Failed to download file.',

  // Success messages
  successPostUser: 'The user was successfully added.',
  successDeleteUser: 'The user was successfully deleted.',
  successEditUserName: 'The user name was successfully edited.',
  successEditUserLanguage: 'The user language was successfully edited.',
  successExportUsers: 'The user list was successfully exported.',
  successAdminChange: 'Successfully modified admin rights.',
  successEditUser: 'The user information was successfully edited.',

  allClients: 'All clients',

  assetPermissions: 'Asset permissions',
  asset: 'Portfolio/department',
  id: 'Id',
  client: 'Client',
  assetName: 'Name',
  permissions: 'Permissions',

  export: 'Export all users and permissions in Excel.',

  yes: 'Yes',
  no: 'No',

  formValidationMandatory: 'Mandatory field',
  formValidationInvalidEmail: 'Invalid email address',
  assetsNotFound: 'No results.',
  usersNotFound: 'No users found.',

  // Permissions
  permissionRead: 'Read',
  permissionWrite: 'Write',
  'permissionRead Write': 'Write',
  permissionReporting: 'Reporting',
  permissionAllowed: 'Allowed',
  permissionUSER: 'Allowed',
  permissionCLI: 'Write',
  permissionR: 'Read',
  permissionREADONLY: 'Read-only permissions',
  permissionREPORTS: 'Access reports',
  permissionINVOICES: 'Access customer invoices',
  permissionNTFYRPRTS: 'Notify about new reports',
  permissionNTFYTASKS: 'Notify about new tasks',

  // Help modal
  help: 'Help',
  helpModalText: `Here is the user guide for Admin users of Lowell Access. As an Admin user, you can manage the Access users of your company: view, modify, add, and remove users.
  Service-specific user guides can be found under each service.
  
  You will need Adobe Acrobat Reader or other tool supporting PDF format to open the file.`,
  helpModalDownload: 'Download user guide',

  readMore: 'Read more',
  showLess: 'Show less',

  news: 'News and updates',

  // File transfer UI
  fileTransfer: 'File Transfer',
  download: 'Download',
  upload: 'Upload',
  searchFiles: 'Search for files',
  submit: 'Submit',
  apply: 'Apply',
  emailFilterNotLimited: 'Files are not limited to any email address',
  emailFilterEnterEmail: 'Enter email for limitation',
  emailFilterLimitedTo: 'Files are limited to:',
  emailFilterRemoveLimitation: 'Remove limitation',
  emailFilterAnotherEmail: 'See files for another email',

  downloaded: 'Downloaded',
  notDownloaded: 'Not downloaded',
  status: 'Status',
  uploadDate: 'Upload date',
  downloadedBy: 'Downloaded by',
  noFilesFound: 'No files found',

  inProgress: 'In Progress',
  success: 'Success',
  failed: 'Failed',
  readyForSubmission: 'Ready for submission',
  dragndropTutorial: 'Drag & drop files here or click to select files',
  actions: 'Actions',
  downloadSelected: 'Download selected',

  fileSizeError: 'File size exceeds',

  // Easy banner
  easyTryText: 'You can handle collection matters even more easily in our new service! Would you like to try Lowell Easy?',
  takeMeThere: '(take me there)',
  closeBanner: '(close this banner)',

  // Dashboard
  collectionDashboard: 'Collection',
  invoiceDashboard: 'Invoice',

  // No mobile page
  pleaseViewOnDesktop: 'Please view this page on a desktop.',
  backToHomePage: 'Back to home page',

  // DISCO announcement filter
  filter: 'Show',
  all: 'All',

  // DISCO apps
  'disco_CollectionWeb Denmark': 'Collection Denmark',
  'disco_CollectionWeb Finland': 'Collection Finland',
  'disco_CollectionWeb Norway': 'Collection Norway',
  'disco_CollectionWeb Sweden': 'Collection Sweden',
  'disco_InvoiceWeb': 'Invoice',
  'disco_Credit Decision': 'Credit Information',
  'disco_Company Monitor': 'Company Monitor',

  new: 'New'
};

export default en;
