/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import logger from '../../utils/logger';

class Reports extends Component {
  render() {
    logger.log('LinkSection re-rendered:', this);


    return (
      <React.Fragment>
        <div className="row">
          <nav className="navbar navbar-light justify-content-between w-100">
            <ul className="nav nav-tabs">
              <li className="nav-item pr-4">
                <span className="nav-link clickable active"><i className="far fa-print" /></span>
              </li>
              <li className="nav-item pr-4">
                <span className="nav-link clickable"><i className="far fa-filter" /></span>
              </li>
              <li className="nav-item pr-4">
                <span className="nav-link clickable"><i className="fas fa-download" /></span>
              </li>
              <li className="nav-item pr-4">
                <span className="nav-link clickable">Calendar</span>
              </li>
            </ul>

            <form className="form-inline ml-auto">
              <div className="md-form my-0 border">
                <input className="form-control border-right-0" type="text" placeholder="Search" aria-label="Search" />
                <button href="#!" className="searchButton btn border border-left-0 btn-outline-white btn-md my-0" type="button"><i className="fas fa-search" /></button>
              </div>
            </form>

          </nav>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th />
              <th>Name</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr />

          </tbody>

        </table>

      </React.Fragment>
    );
  }
}

export default Reports;
