/* eslint-disable prefer-rest-params */
const logger = {
  log() {
    if (process.env.NODE_ENV === 'development') {
      console.log(...arguments);
    }
  },
  warn() {
    if (process.env.NODE_ENV === 'development') {
      console.warn(...arguments);
    }
  },
  error() {
    if (process.env.NODE_ENV === 'development') {
      console.error(...arguments);
    }
  },
};

export default logger;
