import React from 'react';
import { connect } from 'react-redux';
import { arrayOf, bool, shape } from 'prop-types';
import UploadFilesDropzone from './UploadFilesDropzone';
import SelectedUploadFiles from './SelectedUploadFiles';

const Upload = ({ getClientsLoading, clients }) => {
  if (getClientsLoading) {
    return (
      <div className="d-flex justify-content-center mt-30">
        <img height="64" width="64" alt="" src="/lowell_loading.gif" />
      </div>
    );
  }
  if (clients.length === 0) {
    return null;
  }
  return (
    <div className="row no-gutters h-100">
      <div className="col-8 shadow-right p-30">
        <UploadFilesDropzone />
        <SelectedUploadFiles />
      </div>
      <div className="col-4 bg-body-bg" />
    </div>
  );
};

Upload.propTypes = {
  getClientsLoading: bool.isRequired,
  clients: arrayOf(shape({})).isRequired,
};

const mapStateToProps = state => ({
  clients: state.fileTransfer.clients,
  getClientsLoading: state.fileTransfer.getClientsLoading,
});

export default connect(mapStateToProps, null)(Upload);
