import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContactUsPartial from './ContactUsPartial';
import close from '../../images/Close-dark.png';
import strings from '../../utils/strings';
import logger from '../../utils/logger';

class ContactUsModal extends Component {
  constructor(props) {
    super(props);
    this.flagIconClasses = [
      'flagicon',
      'align-self-start',
      'mr-2',
    ];
  }

  render() {
    const {
      contactInfo,
      localization,
    } = this.props;
    const contactUsPartials = [];
    if (contactInfo) {
      const { language } = localization;
      const countryCodes = Object.keys(contactInfo).filter(s => !s.startsWith('_'));

      countryCodes.forEach((cc) => {
        let countryContactInfo;

        if (Object.keys(contactInfo[cc]).includes(language)) {
          countryContactInfo = contactInfo[cc][language];
        } else {
          countryContactInfo = contactInfo[cc].en;
        }

        logger.log('Country Contact Info 1:', contactInfo);
        logger.log('Country Contact Info 2:', countryContactInfo);

        contactUsPartials.push(
          <ContactUsPartial
            key={`contactUsPartial-${cc}`}
            countryCode={cc}
            phone={countryContactInfo.phone}
            extraPhoneInformation={countryContactInfo.extraPhoneInformation}
            email={countryContactInfo.email}
            openHours={countryContactInfo.openHours}
          />,
        );
      });
    }

    return (
      <div id="contactUsModal" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className="modal-content rounded-0">
            <div className="modal-header py-2">
              <h5 className="modal-title text-dark"><strong>{strings.contactUs}</strong></h5>
              <button type="button" className="close" data-dismiss="modal">
                <img
                  src={close}
                  alt="Close"
                />
              </button>
            </div>
            <div className="modal-body">
              <p>{strings.contactUsSubText}</p>
              <p><strong>{strings.contactUsSubTitle}</strong></p>
              {contactUsPartials}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ContactUsModal.propTypes = {
  contactInfo: PropTypes.shape({
    dk: PropTypes.shape({}),
    ee: PropTypes.shape({}),
    fi: PropTypes.shape({}),
    se: PropTypes.shape({}),
  }),
  localization: PropTypes.shape({
    language: PropTypes.string,
  }),
};

ContactUsModal.defaultProps = {
  contactInfo: null,
  localization: {
    language: 'en',
  },
};

function mapStateToProps(state) {
  return {
    localization: state.localization,
    contactInfo: state.permissions.contactInfo,
  };
}

export default connect(
  mapStateToProps,
  null,
)(ContactUsModal);
