/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useLocalStorage } from 'react-use';
import styles from './DiscoAnnouncementSection.module.scss';
import DiscoAnnouncement from './DiscoAnnouncement';
import strings from '../utils/strings';
import { useDiscoApps } from '../utils/selectorUtils';
import { ReactComponent as WarningIcon } from '../images/Warning.svg';

export const DiscoAnnouncementSection = ({
  discoAnnouncements,
}) => {
  const [filteredAnnouncements, setFilteredAnnouncements] = useState(discoAnnouncements);
  const [readAnnouncements, setReadAnnouncements] = useLocalStorage('disco-read-announcements', []);
  const [unreadCategories, setUnreadCategories] = useState([]);
  const [filter, setFilter] = useState([]);
  const discoApps = useDiscoApps();

  useEffect(() => {
    const filtered = discoAnnouncements.filter(a => (filter.length > 0
      ? filter.every(f => a.applications.includes(f)) : true));
    setFilteredAnnouncements(filtered);
  }, [filter, setFilteredAnnouncements, discoAnnouncements]);

  useEffect(() => {
    const unread = discoAnnouncements
      .filter(a => !readAnnouncements.includes(a.title)).flatMap(a => a.applications);
    const categories = [...new Set(unread)];
    setUnreadCategories(categories);
  }, [discoAnnouncements, readAnnouncements]);

  useEffect(() => {
    if (discoAnnouncements.length > 0) {
      const existingAnnouncements = readAnnouncements
        .filter(ra => !!discoAnnouncements.find(da => da.title === ra));
      if (existingAnnouncements.length !== readAnnouncements.length) {
        setReadAnnouncements(existingAnnouncements);
      }
    }
  }, [discoAnnouncements, readAnnouncements, setReadAnnouncements]);

  const getIconForLevel = (level) => {
    switch (level) {
      case 'Problem':
        return <WarningIcon className="align-self-center" data-testid="disco-exclamation" />;
      case 'Information':
        return null;
      default:
        return null;
    }
  };

  const linkify = (inputText) => {
    let replacedText;

    // URLs starting with http://, https://, or ftp://
    const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    const replacePattern2 = /(^|[^/])(www\.[\S][^<]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    // Change email addresses to mailto:: links.
    const replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
  };

  if (!discoAnnouncements || discoAnnouncements.length === 0) {
    return null;
  }

  const omgMessagesJsx = [];
  filteredAnnouncements.forEach((msg, i) => {
    const title = msg.title ? msg.title : null;
    const content = msg.content ? msg.content : null;
    const startTime = msg.startTime ? moment(msg.startTime).format('DD.MM.YYYY HH:mm') : null;
    const { level } = msg;
    const icon = getIconForLevel(level);


    const linkifiedMessage = content.includes('<a') ? content : linkify(content);

    omgMessagesJsx.push(
      <DiscoAnnouncement
        icon={icon}
        level={level}
        title={title}
        id={`disco-${i}`}
        linkifiedMessage={linkifiedMessage}
        published={startTime}
        markAsRead={t => (!readAnnouncements.includes(t)
          ? setReadAnnouncements(readAnnouncements.concat(t))
          : {})}
        unread={!readAnnouncements.includes(title)}
        key={title}
      />,
    );
  });

  return (
    <div
      id="discoAnnouncements"
      className="d-flex flex-column"
    >
      {discoAnnouncements.length > 0 && (
        <span className={styles.title}>
          {strings.news}
        </span>
      )}
      {discoAnnouncements.length > 5 && discoApps.length > 1 && (
        <div className={styles.filter}>
          <span className="font-weight-bold">
            {`${strings.filter}:`}
          </span>
          <span className={styles.filterOptions}>
            <span
              className={classNames(filter.length === 0 && styles.selectedFilter)}
              onClick={() => setFilter([])}
            >
              {strings.all}
            </span>
            {discoApps.map(a => (
              <span
                key={a}
                className={classNames(
                  styles.filterOption,
                  filter.includes(a) && styles.selectedFilter,
                  unreadCategories.includes(a) && styles.unread,
                )}
                onClick={() => setFilter(prev => (prev.includes(a)
                  ? prev.filter(p => p !== a) : prev.concat(a)))}
              >
                {strings[`disco_${a}`] || a}
              </span>
            ))}
          </span>
        </div>
      )}
      {omgMessagesJsx}
    </div>
  );
};

DiscoAnnouncementSection.propTypes = {
  discoAnnouncements: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
    published: PropTypes.string,
    level: PropTypes.string,
  })),
};

DiscoAnnouncementSection.defaultProps = {
  discoAnnouncements: {
    title: null,
    message: null,
    published: null,
    level: null,
  },
};

function mapStateToProps(state) {
  return {
    localization: state.localization,
    discoAnnouncements: state.permissions.discoAnnouncements,
    policyApiLoading: state.permissions.policyApiLoading,
  };
}

export default connect(
  mapStateToProps,
)(DiscoAnnouncementSection);
