import React, { useState, useEffect } from 'react';
import { Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import strings from '../../utils/strings';

const EditUserRow = ({
  originalValue,
  content,
  name,
  handleInputChange,
  valid,
  label,
  edit,
  errorMessage,
  hasAssets,
}) => {
  const [target, setTarget] = useState(null);
  const [value, setValue] = useState(content);

  useEffect(() => {
    setValue(content);
  }, [originalValue, content]);

  const attachRef = t => setTarget(t);

  const handleChange = (e) => {
    setValue(e.target.value);
    handleInputChange(e);
  };

  const editContent = edit
    ? (
      <div className="row">
        <div className="col">
          <input ref={attachRef} id={`${name}EditInput`} data-testid={`${name}EditInput`} onChange={handleChange} value={value} className={`form-control form-control-sm${!valid ? ' invalid' : ''}`} name={name} />
        </div>
        <Overlay target={target} show={!!(!valid && errorMessage && errorMessage.length > 0)} placement="right">
          {({
            placement,
            scheduleUpdate,
            arrowProps,
            outOfBoundaries,
            show: _show,
            ...props
          }) => (
            <span {...props} className="pl-2 form-validation-error" data-testid="validationErrorMessage">
              {errorMessage}
            </span>
          )}
        </Overlay>
      </div>
    )
    : (
      <div className="row">
        <div className="col text-nowrap" data-testid={`${name}Content`}>
          {`${content} `}
          {name === 'Email' && hasAssets && (
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>{strings.emailEditTooltip}</Tooltip>}
            >
              <i className="fas fa-question-circle text-blue" />
            </OverlayTrigger>
          )}
        </div>
      </div>
    );
  return (
    <div className="row no-gutters">
      <label className="" htmlFor={`${name}EditInput`}>
        {`${label}:`}
      </label>
      <div className="col-4">
        {editContent}
      </div>
    </div>
  );
};
EditUserRow.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  originalValue: PropTypes.string,
  name: PropTypes.string,
  handleInputChange: PropTypes.func,
  valid: PropTypes.bool,
  edit: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasAssets: PropTypes.bool,
};
EditUserRow.defaultProps = {
  valid: false,
  originalValue: '',
  name: '',
  handleInputChange: undefined,
  edit: false,
  errorMessage: '',
  hasAssets: true,
};

export default EditUserRow;
