const replaceAssetId = (assetId, replaceValue) => assetId.replace(assetId.substring(0, assetId.lastIndexOf(':') + 1), replaceValue);

export const friendlyAssetId = (assetId) => {
  const [coreSystem] = assetId.split(':');
  switch (coreSystem) {
    case 'FIN_CS':
      return replaceAssetId(assetId, 'FI ');
    case 'FIN_IW':
      return replaceAssetId(assetId, 'FI ');
    case 'NOR_CS':
      return replaceAssetId(assetId, 'NO ');
    case 'SWE_CS':
      return replaceAssetId(assetId, 'SE ');
    case 'DNK_CS_GUG':
      return assetId.replace(assetId.substring(0, assetId.indexOf(':') + 1), '').replace(':', ' ');
    case 'FIN_IS_CREDITDECISION':
      return replaceAssetId(assetId, 'FI ');
    default:
      return assetId;
  }
};

export { friendlyAssetId as default };
