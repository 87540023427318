const no = {
  dataProtection: 'Databeskyttelse',
  dataProtectionContent: `Databeskyttelse
  Vi behandler og lagrer personopplysninger knyttet til våre kunders  kontaktpersoner når vi produserer våre tjenester. For tjenestene vi leverer innenfor inkassoområdet vil vi være behandlingsansvarlig.  Vi behandler personopplysningene med det formål å levere kundeservice og administrere kundeforhold.
  
  Databehandlingen er basert på overholdelse av lovbestemte forpliktelser knyttet til inkasso samt Lowell's berettigede interesse, som for eksempel å yte avtalte tjenester for kunder. Dataene kan bli delt med leverandører som legger til rette for eller bistår oss i våre tjenesteleveranser. I tilfelle dine personopplysninger blir gitt til leverandører, gjøres dette på grunnlag av databehandleravtaler eller annet lovlig grunnlag. 
  
  Mer informasjon om retten til å se data, dine andre rettigheter og behandling av personopplysninger er tilgjengelig på <a href="https://www.lowell.no/lowell/personvernerklaering-og-cookiepolicy/">lowell.no/lowell/personvernerklaering-og-cookiepolicy</a>.`,
  contactUs: 'Kontakt oss',
  contactUsSubText: 'Kundeservice hjelper deg ved mulig spørsmål',
  contactUsSubTitle: 'Kontakt informasjon',
  youWereSignedOut: 'Du er logget ut',
  clickButtonToSignInAgain: 'Click the button below to sign in again (NO)',
  signIn: 'Logg in',
  signOut: 'Logg ut',
  welcome: 'Velkommen til Lowell Access!',
  yourAvailableServices: 'Her er tjenestene som er tilgjengelig for deg',
  privacyPolicy: 'Privacy policy (NO)',
  selectLanguage: 'Velg språk',
  home: 'Hjem',
  adminPanel: 'Brukere',

  // Services
  collection: 'Inkasso',
  monitorService: 'Sporingstjeneste',
  creditDecision: 'Credit Information Services',
  informationServices: 'Informasjons service',
  dashboard: 'Dashbord',
  invoiceWeb: 'Faktura',
  handyman: 'Montørportalen',
  collectionServices: 'Inkasso',
  invoiceServices: 'Faktura service',

  // Countries
  denmark: 'Danmark',
  estonia: 'Estland',
  finland: 'Finland',
  sweden: 'Sverige',
  norway: 'Norge',

  // Languages
  danish: 'dansk',
  estonian: 'estisk',
  finnish: 'finsk',
  norwegian: 'norsk',
  swedish: 'svensk',

  finlandExtraPhoneInfo: '+358 10 2700 534 (Land line 8,28 c + 5,95 c/min, mobile phone 8,28 c + 17,04 c/min)',
  finlandOpenTimes: 'Mon�Thu 8�17, Fri 8�16',

  // User deletion modal
  deleteUserModalTitle: 'Slette brukere',
  deleteUserModalContent: 'Denne bruker vil bli slettet permanent. Vil du fortsette?',
  deleteUserModalRemoveUser: 'Slett bruker',

  // Forms
  save: 'Lagre',
  edit: 'Redigere',
  cancel: 'Avbryt',
  firstName: 'Fornavn',
  lastName: 'Etternavn',
  email: 'E-post',
  language: 'Språk',
  country: 'Land',

  search: 'Søk bruker',
  searchAsset: 'Søk',

  // Add user form
  addUserFormTitle: 'Legg til ny bruker',
  addUserDescription: `Vennligst fyll inn alle feltene under.
  Når du oppretter en ny bruker, vil en invitasjonsmail sendes til mailadressen du har oppgitt nedenfor. Dersom mailadressen allerede er registrert som bruker hos oss vil det ikke sendes velkomstmail.`,
  addUserAdminCheckboxText: 'Admin bruker',

  // Users
  usersTableHeaderName: 'Navn',
  usersTableHeaderLogin: 'Siste innlogging',

  // Edit user form
  editUserDelete: 'Slette brukere',
  editUserTitle: 'Grunnleggende informasjon',
  editInformation: 'Rediger informasjon',
  emailEditTooltip: 'Email blir brukt for identifisering og kan ikke redigeres.',

  // Alerts
  errorAlertFailedTo: 'Failed to',
  errorAlertTryAgain: 'please try again',

  // Error messages
  errorFetchUsers: 'Det mislyktes med å hente brukerlisten, vennligst forsøk på nytt.',
  errorPostUser: 'Det mislyktes med å opprette ny bruker, vennligst forsøk på nytt.',
  errorDeleteUser: 'Det mislyktes med å slette bruker, vennligst forsøk på nytt.',
  errorEditName: 'Det mislyktes med å redigere brukernavnet, vennligst forsøk på nytt.',
  errorEditLanguage: 'Det mislyktes med å endre bruker språk, vennligst forsøk på nytt.',
  errorFetchAssets: 'Kunne ikke hente avdelinger og rettigheter.',
  errorFetchPermissions: 'Kunne ikke hente rettigheter.',
  errorAddAssetPermissions: 'Kunne ikke legge til rettigheter',
  errorDeleteAssetPermissions: 'Kunne ikke fjerne rettigheter.',
  errorExportUsers: 'Kunne ikke eksportere brukerlisten til Excel.',
  errorAdminChange: 'Failed to modify admin rights.',
  errorEditUser: 'Brukerinnformasjon kunne ikke redigeres.',
  errorFileTransferClientFetch: 'Failed to fetch File Transfer client information.',
  errorFileTransferFileFetch: 'Failed to fetch files.',
  errorFileTransferDownload: 'Failed to download file.',

  // Success messages
  successPostUser: 'Brukeren ble vellykket lagt til.',
  successDeleteUser: 'Brukeren ble vellykket slettet.',
  successEditUserName: 'Brukernavnen ble vellykket endret.',
  successEditUserLanguage: 'Bruker språk ble vellykket endret.',
  successExportUsers: 'Brukerlisten ble vellykke eksportert til Excel.',
  successAdminChange: 'Successfully modified admin rights.',
  successEditUser: 'Brukerinformasjonen ble vellykket endret.',

  allClients: 'All clients',

  assetPermissions: 'Asset permissions',
  asset: 'Avdeling',
  id: 'Id',
  client: 'Client',
  assetName: 'Navn',
  permissions: 'Rettigheter',

  export: 'Eksporter alle brukere og rettigheter til Excel.',

  yes: 'Ja',
  no: 'Nei',

  formValidationMandatory: 'Obligatorisk felt',
  formValidationInvalidEmail: 'Feil i email adressen',
  assetsNotFound: 'Ingen resultater.',
  usersNotFound: 'Bruker ikke funnet',

  permissionRead: 'Lese',
  permissionWrite: 'Skrive',
  'permissionRead Write': 'Skrive',
  permissionReporting: 'Rapportering',
  permissionAllowed: 'Tillatt',
  permissionUSER: 'Tillatt',
  permissionCLI: 'Skrive',
  permissionR: 'Lese',

  // Help modal
  help: 'Hjelp',
  helpModalText: 'Her finner du en brukerguide for applikasjonen. Du trenger Adobe Acrobat Reader eller et annet verktøy som støtter PDF format for å åpne filen.',
  helpModalDownload: 'Last ned brukerguiden',

  readMore: 'Les mer',
  showLess: 'Vis mindre',

  news: 'Nyheter og oppdateringer',

  // File transfer UI
  fileTransfer: 'File Transfer',
  download: 'Download',
  upload: 'Upload',
  searchFiles: 'Search for files',
  submit: 'Submit',
  apply: 'Apply',
  emailFilterNotLimited: 'Files are not limited to any email address',
  emailFilterEnterEmail: 'Enter email for limitation',
  emailFilterLimitedTo: 'Files are limited to:',
  emailFilterRemoveLimitation: 'Remove limitation',
  emailFilterAnotherEmail: 'See files for another email',

  downloaded: 'Downloaded',
  notDownloaded: 'Not downloaded',
  status: 'Status',
  uploadDate: 'Upload date',
  downloadedBy: 'Downloaded by',
  noFilesFound: 'No files found',

  inProgress: 'In Progress',
  success: 'Success',
  failed: 'Failed',
  readyForSubmission: 'Ready for submission',
  dragndropTutorial: 'Drag & drop files here or click to select files',
  actions: 'Actions',
  downloadSelected: 'Download selected',

  fileSizeError: 'File size exceeds',

  // Dashboard
  collectionDashboard: 'Inkasso',
  invoiceDashboard: 'Faktura',

  // No mobile page
  pleaseViewOnDesktop: 'Siden vises best på en dataskjerm/PC.',
  backToHomePage: 'Tilbake til startsiden',

  // DISCO announcement filter
  filter: 'Filter',
  all: 'Alle',

  // DISCO apps
  'disco_CollectionWeb Denmark': 'Inkasso Danmark',
  'disco_CollectionWeb Finland': 'Inkasso Finland',
  'disco_CollectionWeb Norway': 'Inkasso Norge',
  'disco_CollectionWeb Sweden': 'Inkasso Sverige',
  'disco_InvoiceWeb': 'Faktura',
  'disco_Credit Decision': 'Kredittinformasjon',
  'disco_Company Monitor': 'Sporingstjeneste',

  new: 'Ny'
};

export default no;
