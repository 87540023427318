import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import strings from '../../utils/strings';
import { actionCreators as localizationActionCreators } from '../../store/LocalizationStore';
import DiscoAnnouncementSection from '../DiscoAnnouncementSection';
import LinkCard from './LinkCard';
import EasyBanner from './EasyBanner';
import { localizationType } from '../../utils/propTypeTemplates';
import envUrl from '../../utils/envUrlHelper';
import { Desktop, Mobile, useIsMobile } from '../general/MediaQuery';
import { ReactComponent as CollectionIcon } from '../../images/collection_service.svg';
import { ReactComponent as DashboardIcon } from '../../images/dashboards.svg';
import { ReactComponent as InvoiceIcon } from '../../images/invoice_service.svg';
import { ReactComponent as HandymanIcon } from '../../images/handyman.svg';
import { ReactComponent as InformationServicesIcon } from '../../images/information_service.svg';
import styles from './LinkSection.module.scss';

export const LinkSection = ({
  policyApiLoading,
  discoAnnouncementsLoading,
  policyApiCoreSystems,
  externalPbiReports,
  localization,
  permissions,
}) => {
  const isMobile = useIsMobile();

  const categories = {
    collection: {
      title: strings.collectionServices,
      icon: CollectionIcon,
      links: [],
    },
    reporting: {
      title: strings.dashboard,
      icon: DashboardIcon,
      links: [],
    },
    invoice: {
      title: strings.invoiceServices,
      icon: InvoiceIcon,
      links: [],
    },
    informationService: {
      title: strings.informationServices,
      icon: InformationServicesIcon,
      links: [],
    },
    handyman: {
      title: strings.handyman,
      icon: HandymanIcon,
      links: [],
    },
  };

  if (policyApiCoreSystems.includes('FIN_CS')) {
    categories.collection.links.push(
      {
        url: envUrl('https://collection.lowell.fi/', 'https://access-qa.lowell.com/CWFIUI/'),
        text: strings.finland,
        testId: 'FIN_CS',
      },
    );
  }

  if (policyApiCoreSystems.includes('NOR_CS')) {
    categories.collection.links.push(
      {
        url: envUrl('https://collection.lowell.no/', 'https://access-qa.lowell.com/CWNOUI/'),
        text: strings.norway,
        testId: 'NOR_CS',
      },
    );
  }

  if (policyApiCoreSystems.includes('SWE_CS')) {
    categories.collection.links.push(
      {
        url: envUrl('https://collection.lowell.se/', 'https://access-qa.lowell.com/CWSEUI/'),
        text: strings.sweden,
        testId: 'SWE_CS',
      },
    );
  }

  if (policyApiCoreSystems.includes('DNK_CS_GUG')) {
    categories.collection.links.push(
      {
        url: envUrl('https://collection.lowell.dk/', 'https://access-qa.lowell.com/CWDKUI/'),
        text: strings.denmark,
        testId: 'DNK_CS_GUG',
      },
    );
  }

  if (policyApiCoreSystems.includes('FIN_IS_COMPANYMONITOR')) {
    categories.informationService.links.push(
      {
        url: envUrl('https://is.lowell.fi/CompanyMonitor/account/login', 'http://informationservices-test-fi.intad1.com/CompanyMonitorUI_Head/'),
        text: strings.monitorService,
        testId: 'FIN_IS_COMPANYMONITOR',
      },
    );
  }

  // if (policyApiCoreSystems.includes('FIN_IS_CREDITDECISION')) {
  if (permissions && permissions.some(p => p.coreSystemId === 'FIN_IS_CREDITDECISION' && p.assetPermissions.length > 0)) {
    categories.informationService.links.push(
      {
        url: envUrl('https://is.lowell.fi/CreditInformationV2/Query', 'http://informationservices-test-fi.intad1.com/CreditInformationServiceUI/'),
        text: strings.creditDecision,
        testId: 'FIN_IS_CREDITDECISION',
      },
    );
  }

  if (policyApiCoreSystems.includes('FIN_IW')) {
    categories.invoice.links.push(
      {
        url: envUrl('https://invoice.lowell.fi/', 'https://access-qa.lowell.com/InvoiceWeb/'),
        text: strings.invoiceWeb,
        testId: 'FIN_IW',
      },
    );
  }

  externalPbiReports.forEach((report) => {
    if (report.coreSystem === 'FIN_CS' && report.name.substr(-3) === (localization.language === 'fi' ? '_FI' : '_EN')) {
      categories.reporting.links.push(
        {
          url: `/pbiReport?dashboardId=${report.name}`,
          text: `${strings.collectionDashboard} ${strings.finland}`,
          testId: report.name,
        },
      );
    } else if (report.coreSystem === 'SWE_CS' && report.name.substr(-3) === (localization.language === 'sv' ? '_SE' : '_EN')) {
      categories.reporting.links.push(
        {
          url: `/pbiReport?dashboardId=${report.name}`,
          text: `${strings.collectionDashboard} ${strings.sweden}`,
          testId: report.name,
        },
      );
    } else if (
      report.coreSystem === 'NOR_CS'
      && report.name.substr(4) === 'COLLECTION_DASHBOARD_EN'
    ) {
      categories.reporting.links.push(
        {
          url: `/pbiReport?dashboardId=${report.name}`,
          text: `${strings.collectionDashboard} ${strings.norway}`,
          testId: report.name,
        },
      );
    } else if (
      report.coreSystem.startsWith('DNK_CS')
      && report.name.substr(4) === 'COLLECTION_DASHBOARD_EN'
    ) {
      categories.reporting.links.push(
        {
          url: `/pbiReport?dashboardId=${report.name}`,
          text: `${strings.collectionDashboard} ${strings.denmark}`,
          testId: report.name,
        },
      );
    } else if (report.coreSystem === 'FIN_IW' && (localization.language === 'fi'
      ? report.name.substr(-3) !== '_EN'
      : report.name.substr(-3) === '_EN')) {
      categories.reporting.links.push(
        {
          url: `/pbiReport?dashboardId=${report.name}`,
          text: strings.invoiceDashboard,
          testId: report.name,
        },
      );
    } else if (report.coreSystem === 'CUSTOM_REPORT') {
      categories.reporting.links.push(
        {
          url: `/pbiReport?dashboardId=${report.name}`,
          text: report.name,
        },
      );
    }
  });

  if (policyApiCoreSystems.includes('NOR_ELDISCO') && permissions.find(p => p.coreSystemId === 'NOR_ELDISCO')) {
    categories.handyman.links.push(
      {
        url: envUrl('https://access.lowell.io/electricitydisconnect/', 'https://access-test.lowell.io/electricitydisconnect/'),
        text: 'Montørportalen',
      },
    );
  }

  let easyBanner;

  if (permissions) {
    const easyRoleClients = permissions.filter(p => p.roles.filter(r => r.id === 'EASY').length > 0);

    if (easyRoleClients.length > 0) {
      let easyUrl;

      if (easyRoleClients[0].coreSystemId === 'SWE_CS') {
        easyUrl = envUrl(
          'https://access.lowell.io/easy/se/',
          'https://access-qa.lowell.io/easy/se/',
        );
      } else if (easyRoleClients[0].coreSystemId === 'FIN_CS') {
        easyUrl = envUrl(
          'https://access.lowell.io/easy/fi/',
          'https://access-qa.lowell.io/easy/fi/',
        );
      }

      if (easyUrl) {
        easyBanner = <EasyBanner url={easyUrl} />;
      }
    }
  }

  const links = Object.keys(categories).filter(k => categories[k].links.length > 0)
    .map(key => categories[key])
    .map((category) => {
      return (
        <LinkCard
          key={category.title}
          icon={<category.icon />}
          title={category.title}
          links={category.links}
        />
      );
    });

  const innerLinkSection = (
    <div
      id="mainLinkSection"
      className="col px-0 shadow-right"
      style={{ minWidth: isMobile ? 'unset' : '500px' }}
    >
      {policyApiLoading || discoAnnouncementsLoading ? (
        <div className="container pt-4 pb-4 text-center">
          <img alt="Loading Links" src="/lowell_loading.gif" data-testid="loading" />
        </div>
      ) : (
        <div id="linkContainer" className="pt-40 px-20 pb-20">
          {easyBanner}
          <h1>
            {strings.welcome}
          </h1>
          <div>
            {strings.yourAvailableServices}
          </div>
          <div className={styles.linkContainer}>
            <Desktop>
              <div className={styles.cardRow}>
                <div className={styles.cardCol}>
                  {links.filter((_, i) => i % 2 === 0)}
                </div>
                <div className={styles.cardCol}>
                  {links.filter((_, i) => i % 2 === 1)}
                </div>
              </div>
            </Desktop>
            <Mobile>
              {links}
            </Mobile>
          </div>
          <div>
            <DiscoAnnouncementSection />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <React.Fragment>
      {innerLinkSection}
    </React.Fragment>
  );
};

LinkSection.propTypes = {
  policyApiLoading: PropTypes.bool.isRequired,
  discoAnnouncementsLoading: PropTypes.bool.isRequired,
  policyApiCoreSystems: PropTypes.arrayOf(PropTypes.string).isRequired,
  externalPbiReports: PropTypes.arrayOf(PropTypes.any),
  localization: localizationType.isRequired,
};

LinkSection.defaultProps = {
  externalPbiReports: [],
};

function mapStateToProps(state) {
  return {
    localization: state.localization,
    policyApiCoreSystems: state.permissions.policyApiCoreSystems,
    externalPbiReports: state.permissions.externalPbiReports,
    policyApiLoading: state.permissions.policyApiLoading,
    discoAnnouncementsLoading: state.permissions.discoAnnouncementsLoading,
    permissions: state.permissions.policyApiPermissions,
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...localizationActionCreators,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(LinkSection);
