const fi = {
  dataProtection: 'Tietosuoja',
  dataProtectionTitle: 'Tietosujatiedot',
  dataProtectionContent: `Käsittelemme ja tallennamme asiakasyritystemme yhteyshenkilöiden henkilötietoja palvelujemme tuottamisen yhteydessä. Tietojen rekisterinpitäjänä on Lowell Suomi Oy. Käytämme tietoja asiakaspalveluun, asiakassuhteiden hoitamiseen sekä palvelujen markkinointiin.

Tietojen käsittelyn perusteena on perintään liittyvien lakisääteisten velvoitteiden noudattaminen sekä Lowellin ns. oikeutettujen etujen, kuten sovittujen palvelujen tuottaminen asiakkaalle. Emme luovuta tietoja kolmansille osapuolille.

Lisätietoja omien tietojen tarkistamisesta, muista oikeuksistasi sekä henkilötietojen käsittelystä osoitteessa <a href="https://lowell.fi/tietosuoja">lowell.fi/tietosuoja</a>.`,
  contactUs: 'Ota yhteyttä',
  contactUsSubText: 'Asiakaspalvelu auttaa mahdollisissa kysymyksissä.',
  contactUsSubTitle: 'Yhteystiedot',
  youWereSignedOut: 'Olet kirjautunut ulos',
  clickButtonToSignInAgain: 'Kirjaudu uudestaan klikkaamalla alla olevaa painiketta',
  signIn: 'Kirjaudu',
  signOut: 'Kirjaudu ulos',
  welcome: 'Tervetuloa Lowell Accessiin!',
  yourAvailableServices: 'Käytettävissäsi olevat palvelut',
  privacyPolicy: 'Privacy policy (FI)',
  selectLanguage: 'Valitse kieli',
  home: 'Etusivu',
  reports: 'Raportit',
  adminPanel: 'Käyttäjät',

  // Services
  collection: 'Perintä',
  monitorService: 'Seurantapalvelu',
  creditDecision: 'Luottotietopalvelu',
  informationServices: 'Informaatiopalvelut',
  dashboard: 'Dashboard',
  invoiceWeb: 'Laskutus',
  handyman: 'Handyman',
  collectionServices: 'Perintä',
  invoiceServices: 'Laskutus',

  // Countries
  denmark: 'Tanska',
  estonia: 'Viro',
  finland: 'Suomi',
  sweden: 'Ruotsi',
  norway: 'Norja',

  // Languages
  danish: 'tanska',
  estonian: 'viro',
  finnish: 'suomi',
  norwegian: 'norja',
  swedish: 'ruotsi',

  finlandExtraPhoneInfo: '(Lankapuhelimesta 8,28 c + 5,95 c/min, matkapuhelimesta 8,28 c + 17,04 c/min)',
  finlandOpenTimes: 'Ma-to 8-17, pe 8-16',

  // User deletion modal
  deleteUserModalTitle: 'Käyttäjän poistaminen',
  deleteUserModalContent: 'Käyttäjä poistetaan pysyvästi. Haluatko jatkaa?',
  deleteUserModalRemoveUser: 'Poista käyttäjä',

  // Forms
  save: 'Tallenna',
  edit: 'Muokkaa',
  cancel: 'Peruuta',
  firstName: 'Etunimi',
  lastName: 'Sukunimi',
  email: 'Sähköposti',
  language: 'Kieli',
  country: 'Maa',

  search: 'Hae käyttäjää',
  searchAsset: 'Hae',

  // Add user form
  addUserFormTitle: 'Lisää uusi käyttäjä',
  addUserDescription: `Täytäthän kaikki allaolevat kentät.
  Kun luot yritykselle uuden käyttäjän, lähetämme kutsuviestin alla antamaasi sähköpostiosoitteeseen. Huomaathan, että jos sähköpostiosoite on jo Lowellin järjestelmässä, kutsuviestiä ei lähetetä.`,
  addUserAdminCheckboxText: 'Pääkäyttäjä',

  // Users
  usersTableHeaderName: 'Nimi',
  usersTableHeaderLogin: 'Kirjauduttu',

  // Edit user form
  editUserDelete: 'Poista käyttäjä',
  editUserTitle: 'Perustiedot',
  editInformation: 'Muuta tietoja',
  emailEditTooltip: 'Sähköpostiosoitetta käytetään tunnisteena, sitä ei voi muuttaa.',

  // Error messages
  errorFetchUsers: 'Käyttäjälistan haku ei onnistunut, ole hyvä ja yritä uudelleen.',
  errorPostUser: 'Uuden käyttäjän lisääminen ei onnistunut, ole hyvä ja yritä uudelleen.',
  errorDeleteUser: 'Käyttäjän poistaminen ei onnistunut, ole hyvä ja yritä uudelleen.',
  errorEditName: 'Käyttäjän muokkaaminen ei onnistunut, ole hyvä ja yritä uudelleen.',
  errorEditLanguage: 'Käyttäjän kielen vaihtaminen ei onnistunut, ole hyvä ja yritä uudelleen.',
  errorFetchAssets: 'Päämiesnumeroiden ja käyttöoikeuksien hakeminen ei onnistunut.',
  errorFetchPermissions: 'Käyttöoikeuksien hakeminen ei onnistunut.',
  errorAddAssetPermissions: 'Käyttöoikeuksien lisääminen ei onnistunut.',
  errorDeleteAssetPermissions: 'Käyttöoikeuksien poistaminen ei onnistunut.',
  errorExportUsers: 'Käyttäjälistan tuominen Excel-tiedostoon ei onnistunut.',
  errorAdminChange: 'Failed to modify admin rights.',
  errorEditUser: 'Käyttäjän tietojen muuttaminen ei onnistunut.',
  errorFileTransferClientFetch: 'Asiakkuuden tietojen haku epäonnistui.',
  errorFileTransferFileFetch: 'Tiedostojen haku epäonnistui.',
  errorFileTransferDownload: 'Tiedoston lataus epäonnistui.',

  // Success messages
  successPostUser: 'Käyttäjä lisättiin.',
  successDeleteUser: 'Käyttäjä poistettiin.',
  successEditUserName: 'Käyttäjän nimi muokattiin.',
  successEditUserLanguage: 'Käyttäjän kieli vaihdettiin.',
  successExportUsers: 'Käyttäjälista tuotiin Excel-tiedostoon.',
  successAdminChange: 'Successfully modified admin rights.',
  successEditUser: 'Käyttäjän tiedot muutettiin.',

  allClients: 'All clients',

  assetPermissions: 'Asset permissions',
  asset: 'Päämiesnumero',
  id: 'Id',
  client: 'Asiakas',
  assetName: 'Nimi',
  permissions: 'Käyttöoikeudet',

  export: 'Tuo kaikki käyttäjät ja käyttöoikeudet Excel-tiedostoon.',

  yes: 'Kyllä',
  no: 'Ei',

  formValidationMandatory: 'Pakollinen kenttä',
  formValidationInvalidEmail: 'Virheellinen sähköpostiosoite',
  assetsNotFound: 'Ei tuloksia.',
  usersNotFound: 'Käyttäjiä ei löytynyt.',

  permissionRead: 'Luku',
  permissionWrite: 'Kirjoitus',
  'permissionRead Write': 'Kirjoitus',
  permissionReporting: 'Raportointi',
  permissionAllowed: 'Sallittu',
  permissionUSER: 'Sallittu',
  permissionCLI: 'Kirjoitus',
  permissionR: 'Luku',

  // Help modal
  help: 'Ohje',
  helpModalText: `Tästä voit ladata Lowell Accessin pääkäyttäjille tarkoitetun käyttöohjeen. Pääkäyttäjänä voit hallita yrityksesi Access-käyttöoikeuksia: katsella, muokata, lisätä ja poistaa käyttäjiä.
  Palvelukohtaiset käyttöohjeet löytyvät palveluiden alta.
  
  Tiedostojen avaamiseen tarvitset Adobe Acrobat Readerin tai vastaavan ohjelman, joka tukee PDF-tiedostoja.`,
  helpModalDownload: 'Lataa käyttöohje',

  readMore: 'Lue lisää',
  showLess: 'Näytä vähemmän',

  news: 'Uutiset ja tiedotteet',

  // File transfer UI
  fileTransfer: 'Tiedostojen siirto',
  download: 'Lataa tiedostoja',
  upload: 'Lähetä tiedostoja',
  searchFiles: 'Etsi tiedostoja',
  submit: 'Lähetä',
  apply: 'Käytä',
  emailFilterNotLimited: 'Näyttää kaikki tiedostot ilman rajausta',
  emailFilterEnterEmail: 'Rajaa näytettävät tiedostot sovitun sähköpostilinkityksen perusteella (syötä sähköpostiosoite)',
  emailFilterLimitedTo: 'Tiedostojen näkyvyys rajattu:',
  emailFilterRemoveLimitation: 'Poista rajaus',
  emailFilterAnotherEmail: 'Käytä eri rajausta',

  downloaded: 'Ladattu',
  notDownloaded: 'Ei ladattu',
  status: 'Tila',
  uploadDate: 'Julkaisupäivä',
  downloadedBy: 'Lataaja',
  noFilesFound: 'Tiedostoja ei löydy',

  inProgress: 'Ladataan',
  success: 'Valmis',
  failed: 'Epäonnistui',
  readyForSubmission: 'Valmiina lähetettäväksi',
  dragndropTutorial: 'Vedä ja pudota tiedostot tähän tai valitse tiedostot klikkaamalla',
  actions: 'Toiminnot',
  downloadSelected: 'Lataa valitut',

  fileSizeError: 'Tiedoston koko ylittää',

  // Easy banner
  easyTryText: 'Voit hoitaa perintäasiat entistä helpommin uudessa palvelussamme! Haluaisitko kokeilla Lowell Easyä?',
  takeMeThere: '(siirtäkää minut sinne)',
  closeBanner: '(sulje viesti)',

  // Dashboard
  collectionDashboard: 'Perintä',
  invoiceDashboard: 'Laskutus',

  // No mobile page
  pleaseViewOnDesktop: 'Tämä sivu toimii vain pöytäkoneella.',
  backToHomePage: 'Takaisin etusivulle',

  // DISCO announcement filter
  filter: 'Näytä',
  all: 'Kaikki',

  // DISCO apps
  'disco_CollectionWeb Denmark': 'Perintä Tanska',
  'disco_CollectionWeb Finland': 'Perintä Suomi',
  'disco_CollectionWeb Norway': 'Perintä Norja',
  'disco_CollectionWeb Sweden': 'Perintä Ruotsi',
  'disco_InvoiceWeb': 'Laskutus',
  'disco_Credit Decision': 'Luottotieto',
  'disco_Company Monitor': 'Seurantapalvelu',

  new: 'Uusi'
};

export default fi;
