const siteUrl = `${window.location.protocol}//${window.location.host}`;

const defaultHeaders = new Headers({
  'Content-Type': 'application/json',
  Accept: 'application/json',
});

interface BaseRequestParams {
  method: string;
  url: string;
  headers?: HeadersInit;
  body?: BodyInit;
  returnType?: 'json' | 'blob'
}

const baseRequest = async ({ method, url, headers, body, returnType = 'json' }: BaseRequestParams) => {
  try {
    const response = await fetch(`${siteUrl}/${url}`, {
      method,
      body,
      headers,
      mode: 'cors'
    });
    if (response.ok) {
      let data;
      switch (returnType) {
        case 'json':
          data = await response.json();
          break;
        case 'blob':
          data = await response.blob();
          break;
      }
      return data;
    } else {
      throw new Error();
    }
  } catch (e) {
    throw e;
  }
}

const api = {
  get: (
    url: string,
    headers: HeadersInit = defaultHeaders,
    returnType: 'json' | 'blob' = 'json'
  ) => baseRequest({ method: 'GET', url, headers, returnType }),
  post: (url: string, body?: BodyInit, headers: HeadersInit = defaultHeaders) => baseRequest({ method: 'POST', url, body, headers }),
  patch: (url: string, body?: BodyInit, headers: HeadersInit = defaultHeaders) => baseRequest({ method: 'PATCH', url, body, headers }),
  delete: (url: string, body?: BodyInit, headers: HeadersInit = defaultHeaders) => baseRequest({ method: 'DELETE', url, body, headers }),
};

export default api;
