import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const AdminPanelButton = ({
  handleClick,
  toolTip,
  showAddUserForm,
  testId,
  faIcon,
  disabled,
}) => (
  <OverlayTrigger
    placement="bottom"
    overlay={<Tooltip>{toolTip}</Tooltip>}
  >
    <button
      type="button"
      className={`admin-panel-button${showAddUserForm ? ' low-opacity' : ''}`}
      onClick={handleClick}
      data-testid={testId}
      disabled={disabled}
    >
      {disabled
        ? <img style={{ height: '20px' }} alt="" src="/lowell_loading.gif" />
        : <i className={`fas ${faIcon}`} />}
    </button>
  </OverlayTrigger>
);

AdminPanelButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  toolTip: PropTypes.string.isRequired,
  showAddUserForm: PropTypes.bool,
  testId: PropTypes.string,
  faIcon: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

AdminPanelButton.defaultProps = {
  showAddUserForm: false,
  testId: 'adminPanelButton',
  disabled: false,
};

export default AdminPanelButton;
