import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import logger from '../../utils/logger';
import { actionCreators as userManagementActionCreators } from '../../store/UserManagementStore';
import AddUserFormField from './AddUserFormField';
import strings from '../../utils/strings';

export class AddUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailInput: '',
      languageInput: '',
      firstNameInput: '',
      lastNameInput: '',
      formErrors: {
        email: '',
        firstName: '',
        lastName: '',
        language: '',
      },
      admin: false,
      emailValid: true,
      firstNameValid: true,
      lastNameValid: true,
      languageValid: true,
    };
    this.postUser = this.postUser.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  postUser(e) {
    e.preventDefault();
    const {
      firstNameInput,
      lastNameInput,
      emailInput,
      languageInput,
      admin,
    } = this.state;
    const {
      postUser,
      showAddUserForm,
      adminClients,
      showEditForm,
      selectUser,
    } = this.props;
    const userInfo = {
      FirstName: firstNameInput.trim(),
      LastName: lastNameInput.trim(),
      Email: emailInput.trim(),
      Language: languageInput,
      ShouldNotifyNewUser: true,
      Administrator: admin,
    };
    if (this.validateForm()) {
      postUser(adminClients, userInfo).then(() => {
        showAddUserForm(false);
        selectUser(userInfo);
        showEditForm(true);
      }).catch((error) => {
        logger.error(error);
      });

      this.setState({
        emailInput: '',
        languageInput: '',
        firstNameInput: '',
        lastNameInput: '',
        admin: false,
      });
    }
  }


  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.validateForm({ fieldName: name, value });
  }

  validateForm(formEditData) {
    const {
      formErrors,
      emailInput,
      firstNameInput,
      lastNameInput,
      languageInput,
    } = this.state;
    const fieldValidationErrors = formErrors;
    let {
      emailValid,
      firstNameValid,
      lastNameValid,
      languageValid,
    } = this.state;
    if (!formEditData) {
      emailValid = (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i).test(emailInput.trim());
      if (emailInput.trim().length === 0) {
        fieldValidationErrors.email = strings.formValidationMandatory;
      } else fieldValidationErrors.email = emailValid ? '' : strings.formValidationInvalidEmail;
      firstNameValid = firstNameInput.trim().length >= 1;
      lastNameValid = lastNameInput.trim().length >= 1;
      languageValid = languageInput.length > 0;
    } else if (formEditData.fieldName === 'firstNameInput') {
      firstNameValid = formEditData.value.trim().length >= 1;
    } else if (formEditData.fieldName === 'lastNameInput') {
      lastNameValid = formEditData.value.trim().length >= 1;
    } else if (formEditData.fieldName === 'emailInput') {
      if (formEditData.value.trim().length === 0) {
        emailValid = false;
        fieldValidationErrors.email = strings.formValidationMandatory;
      } else {
        emailValid = true;
        fieldValidationErrors.email = '';
      }
    } else if (formEditData.fieldName === 'languageInput') {
      languageValid = formEditData.value.length > 0;
    }
    fieldValidationErrors.firstName = firstNameValid ? '' : strings.formValidationMandatory;
    fieldValidationErrors.lastName = lastNameValid ? '' : strings.formValidationMandatory;
    fieldValidationErrors.language = languageValid ? '' : strings.formValidationMandatory;
    this.setState({
      formErrors: fieldValidationErrors,
      emailValid,
      firstNameValid,
      lastNameValid,
      languageValid,
    });

    return emailValid && firstNameValid && lastNameValid && languageValid;
  }


  render() {
    const {
      postUserLoading,
      showAddUserForm,
    } = this.props;
    const {
      firstNameInput,
      firstNameValid,
      lastNameInput,
      lastNameValid,
      emailInput,
      emailValid,
      languageInput,
      languageValid,
      admin,
      formErrors,
    } = this.state;
    const content = postUserLoading ? (
      <div className="container pt-4 pb-4 text-center">
        <img alt="" src="/lowell_loading.gif" />
      </div>
    ) : (
      <form id="addUserForm" className="pl-20">
        <h6 className="pt-30 pb-20 my-0">
          <b>
            {`${strings.addUserFormTitle}:`}
          </b>

        </h6>
        <p className="multiline">{strings.addUserDescription}</p>
        <div className="d-flex">
          <div className="col-6">
            <AddUserFormField
              autofocus
              value={firstNameInput}
              label={strings.firstName}
              name="firstNameInput"
              type="text"
              valid={firstNameValid}
              handleChange={this.handleInputChange}
              errorMessage={formErrors.firstName}
            />
            <AddUserFormField
              value={lastNameInput}
              label={strings.lastName}
              name="lastNameInput"
              type="text"
              valid={lastNameValid}
              handleChange={this.handleInputChange}
              errorMessage={formErrors.lastName}
            />
            <AddUserFormField
              value={emailInput}
              label={strings.email}
              name="emailInput"
              type="text"
              valid={emailValid}
              handleChange={this.handleInputChange}
              errorMessage={formErrors.email}
            />
            <AddUserFormField
              value={languageInput}
              label={strings.country}
              name="languageInput"
              type="select"
              valid={languageValid}
              errorMessage={formErrors.language}
              handleChange={this.handleInputChange}
            />
            <div className="row">
              <label htmlFor="adminCheckbox">
                {`${strings.addUserAdminCheckboxText}:`}
              </label>
              <div className="col">
                <input
                  type="checkbox"
                  checked={admin}
                  onChange={() => this.setState({ admin: !admin })}
                  name="adminCheckbox"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button type="button" className="cancel-button mr-15" onClick={() => showAddUserForm(false)}><strong>{strings.cancel}</strong></button>
          <button onClick={this.postUser} type="button" className="save-button"><strong>{strings.save}</strong></button>
        </div>
      </form>
    );
    return (
      <div className="bg-white h-100 pr-20" id="adminPanelAddNewUser">
        {content}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  usermanagementInfo: state.usermanagement.usermanagementInfo,
  adminClients: state.permissions.availableClients,
  postUserLoading: state.usermanagement.userManagementPostUserLoading,
  activeLanguage: state.localization.language,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...userManagementActionCreators,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddUserForm);

AddUserForm.propTypes = {
  postUser: PropTypes.func.isRequired,
  showAddUserForm: PropTypes.func.isRequired,
  adminClients: PropTypes.arrayOf(PropTypes.shape({
    clientId: PropTypes.number.isRequired,
  })).isRequired,
  postUserLoading: PropTypes.bool.isRequired,
  showEditForm: PropTypes.func.isRequired,
  selectUser: PropTypes.func.isRequired,
};
