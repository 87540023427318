import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  any,
  arrayOf,
  func,
  number,
  shape,
  string,
} from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import { actionCreators as fileTransferActionCreators } from '../../store/FileTransferStore';
import { formatBytes } from '../../utils/formatting';
import { FileUploadStatus } from '../../utils/enums';
import strings from '../../utils/strings';

const UploadFileRow = (props) => {
  const {
    fileToUpload,
    fileName,
    fileSize,
    removeFileToUpload,
    clients,
    setClientForUploadFile,
    clientId,
    status,
    uploadFile,
    setClientForAllFilesToUpload,
  } = props;

  const statusIndicator = (uploadStatus) => {
    switch (uploadStatus) {
      case FileUploadStatus.InProgress:
        return (
          <div className="px-20">
            <ProgressBar variant="info" now={fileToUpload.progress} />
          </div>
        );
      case FileUploadStatus.Finished:
        return strings.success;
      case FileUploadStatus.Failed:
        return (
          <div className="d-flex justify-content-around align-items-center mr-15">
            <span className="text-danger">{strings.failed}</span>
            <i
              className="fas fa-redo-alt text-primary clickable"
              onClick={() => {
                uploadFile([fileToUpload]);
              }}
            />
          </div>
        );
      default:
        return formatBytes(fileSize);
    }
  };

  const fileErrors = () => {
    if (fileToUpload.errors.length === 0) {
      return <span className="text-blue">{strings.readyForSubmission}</span>;
    }
    return (
      <div className="d-flex flex-column">
        {
          fileToUpload.errors.map(error => <span className="text-danger">{error}</span>)
        }
      </div>
    );
  };

  return (
    <div className="d-flex justify-content-between align-items-center py-1">
      <div
        className="w-100 font-weight-bold"
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {fileName}
      </div>
      <div
        className="text-muted text-center w-100"
      >
        {statusIndicator(status)}
      </div>
      <div className="w-100 d-flex justify-content-between align-items-center">
        <select
          className="form-control mr-10"
          placeholder="Client information"
          onChange={({ target: { value } }) => setClientForUploadFile(fileName, Number(value))}
          value={clientId}
          disabled={status === FileUploadStatus.Finished}
        >
          {clients.map(client => (
            <option
              key={client.id}
              value={client.id}
            >
              {client.rootFolder}
            </option>
          ))}
        </select>
        <i
          className="fas fa-repeat-alt text-primary clickable"
          onClick={() => setClientForAllFilesToUpload(clientId)}
        />
      </div>
      <div
        className="text-center w-100"
      >
        {status !== FileUploadStatus.Finished && fileErrors()}
      </div>
      <button
        type="button"
        onClick={() => removeFileToUpload(fileName)}
        style={{
          background: 'none',
          border: 'none',
        }}
      >
        <i className="fas fa-trash-alt text-primary" />
      </button>
    </div>
  );
};

UploadFileRow.propTypes = {
  fileToUpload: shape(any).isRequired,
  fileName: string.isRequired,
  fileSize: number.isRequired,
  removeFileToUpload: func.isRequired,
  clients: arrayOf(any).isRequired,
  setClientForUploadFile: func.isRequired,
  clientId: number.isRequired,
  status: number.isRequired,
  uploadFile: func.isRequired,
  setClientForAllFilesToUpload: func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  ...fileTransferActionCreators,
}, dispatch);


export default connect(null, mapDispatchToProps)(UploadFileRow);
