import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as localizationActionCreators } from '../../store/LocalizationStore';
import { getConfig } from '../../utils/configManager';
import strings from '../../utils/strings';

class LanguageSection extends Component {
  constructor(props) {
    super(props);
    this.setLanguage = this.setLanguage.bind(this);
    this.config = getConfig();
  }

  setLanguage(event, language) {
    const { setLanguage } = this.props;
    if (event.key === undefined || event.keyCode === 13) {
      document.documentElement.lang = language;
      setLanguage(language);
    }
  }

  getLanguageString = (currLang) => {
    switch (currLang) {
      case 'en':
        return 'English';
      case 'da':
        return 'Dansk';
      case 'no':
        return 'Norsk';
      case 'sv':
        return 'Svenska';
      case 'fi':
        return 'Suomi';
      default:
        return '';
    }
  }

  render() {
    const { localization } = this.props;
    const currLang = localization.language || strings.getLanguage || 'en';

    return (
      <React.Fragment>
        <div className="dropdown">
          <button
            className="nav-link pr-0 d-inline-block clickable"
            data-toggle="dropdown"
          >
            {this.getLanguageString(currLang)}
            <i className="fas fa-caret-right pl-10" />
          </button>
          <div className="dropdown-menu">
            <div className="dropdown-row">
              <LanguageDropdown
                languageCode="da"
                languageText="Dansk"
                currentLanguage={currLang}
                setLanguage={this.setLanguage}
              />
              <LanguageDropdown
                languageCode="en"
                languageText="English"
                currentLanguage={currLang}
                setLanguage={this.setLanguage}
              />
            </div>
            <div className="dropdown-row">
              <LanguageDropdown
                languageCode="no"
                languageText="Norsk"
                currentLanguage={currLang}
                setLanguage={this.setLanguage}
              />
              <LanguageDropdown
                languageCode="fi"
                languageText="Suomi"
                currentLanguage={currLang}
                setLanguage={this.setLanguage}
              />
            </div>
            <div className="dropdown-row">
              <LanguageDropdown
                languageCode="sv"
                languageText="Svenska"
                currentLanguage={currLang}
                setLanguage={this.setLanguage}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const LanguageDropdown = ({
  languageCode,
  languageText,
  setLanguage,
  currentLanguage,
}) => (
  <div
    className={`dropdown-item btn btn-link${currentLanguage === languageCode ? ' current-language' : ' clickable'}`}
    onClick={e => setLanguage(e, languageCode)}
    role="button"
    tabIndex="0"
    onKeyDown={e => setLanguage(e, languageCode)}
  >
    {languageText}
  </div>
);

LanguageSection.propTypes = {
  localization: PropTypes.shape({
    language: PropTypes.string,
  }),
  setLanguage: PropTypes.func.isRequired,
};

LanguageSection.defaultProps = {
  localization: {
    language: 'en',
  },
};

LanguageDropdown.propTypes = {
  languageCode: PropTypes.string.isRequired,
  languageText: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    localization: state.localization,
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({ ...localizationActionCreators },
  dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LanguageSection);
