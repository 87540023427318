import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  any,
  arrayOf,
  func,
} from 'prop-types';
import { actionCreators as fileTransferActionCreators } from '../../store/FileTransferStore';
import UploadFileRow from './UploadFileRow';
import strings from '../../utils/strings';
import { policyApiPermissionsType } from '../../utils/propTypeTemplates';
import { getClientsWithUploaderRole } from '../../utils/fileServiceRoleChecker';

const SelectedUploadFiles = (props) => {
  const {
    filesToUpload,
    uploadFile,
    clients,
    policyApiPermissions,
  } = props;

  const files = filesToUpload.map(fileToUpload => (
    <UploadFileRow
      fileToUpload={fileToUpload}
      fileName={fileToUpload.file.name}
      fileSize={fileToUpload.file.size}
      clients={clients
        .filter(c => getClientsWithUploaderRole(policyApiPermissions)
          .includes(c.id))}
      clientId={fileToUpload.clientId}
      status={fileToUpload.status}
      uploadFile={uploadFile}
      key={fileToUpload.file.name}
    />
  ));

  return (
    <div>
      {files}
      <div className="d-flex justify-content-end">
        {filesToUpload.length > 0 && (
          <button
            type="button"
            className="btn btn-primary px-35 py-15"
            onClick={() => {
              uploadFile(filesToUpload);
            }}
          >
            {strings.submit}
          </button>
        )}
      </div>
    </div>
  );
};

SelectedUploadFiles.propTypes = {
  filesToUpload: arrayOf(any).isRequired,
  uploadFile: func.isRequired,
  clients: arrayOf(any).isRequired,
  policyApiPermissions: policyApiPermissionsType.isRequired,
};

function mapStateToProps(state) {
  return {
    filesToUpload: state.fileTransfer.filesToUpload,
    clients: state.fileTransfer.clients,
    policyApiPermissions: state.permissions.policyApiPermissions,
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...fileTransferActionCreators,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(SelectedUploadFiles);
