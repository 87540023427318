import React from 'react';
import PropTypes from 'prop-types';
import finland from '../../images/flag_finland.png';
import denmark from '../../images/flag_denmark.png';
import estonia from '../../images/flag_estonia.png';
import sweden from '../../images/flag_sweden.png';
import logger from '../../utils/logger';
import norway from '../../images/flag_norway.png';

const flagIconClasses = [
  'flagicon',
  'align-self-start',
  'mr-2',
];

function getFlagIconForCountryCode(countryCode) {
  switch (countryCode) {
    case 'dk':
      return { img: denmark };
    case 'ee':
      return { img: estonia };
    case 'fi':
      return { img: finland };
    case 'se':
      return { img: sweden };
    case 'no':
      return { img: norway };
    default:
      return null;
  }
}

const ContactUsPartial = ({
  countryCode,
  phone,
  extraPhoneInformation,
  email,
  openHours,
}) => {
  const flagIcon = getFlagIconForCountryCode(countryCode);
  logger.log('flagIcon:', flagIcon);
  let flagImage;
  if (flagIcon) {
    flagImage = (
      <img
        src={flagIcon.img}
        alt={countryCode}
        className={flagIconClasses.join(' ')}
      />
    );
  }

  return (
    <div className="d-flex pb-4">
      {flagImage}
      <span className="d-flex flex-column align-text-top">
        <span className="pb-1">
          {phone}
          {' '}
          {extraPhoneInformation}
        </span>
        <span className="pb-1">
          <a href={`mailto:${email}`}>{email}</a>
        </span>
        <span>{openHours}</span>
      </span>
    </div>
  );
};

ContactUsPartial.propTypes = {
  countryCode: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  extraPhoneInformation: PropTypes.string,
  email: PropTypes.string,
  openHours: PropTypes.string.isRequired,
};

ContactUsPartial.defaultProps = {
  extraPhoneInformation: null,
  email: null,
};

export default ContactUsPartial;
