const da = {
  dataProtection: 'Databeskyttelse',
  dataProtectionTitle: 'Databeskyttelse',
  dataProtectionContent: `Vi behandler og opbevarer personoplysninger om vores kunder og virksomheders kontaktpersoner, når vi leverer vores services. Den dataansvarlige er Lowell Danmark A/S. Vi behandler de nævnte personoplysninger for at yde kundeservice, behandle kundeforhold samt for at markedsføre vores services.

Behandlingen af oplysninger sker for at overholde Lowells kontraktlige forpligtelser, for at sikre overholdelse af lovgivning i relation til inkassovirksomhed samt Lowells forfølgelse af legitime interesser. Vi videregiver ikke personoplysninger til tredjemand.

Du kan finde yderligere information omkring din ret til aktindsigt, dine andre rettigheder og om vores behandling af personoplysninger på: <a href="https://lowell.dk/lowell/privatlivspolitik">lowell.dk/lowell/privatlivspolitik</a>.`,

  contactUs: 'Kontakt os',
  contactUsSubText: 'Kundeservice vil hjælpe dig med eventuelle spørgsmål.',
  contactUsSubTitle: 'Kontaktinformation',
  youWereSignedOut: 'Du er blevet logget ud',
  clickButtonToSignInAgain: 'Tryk på knappen herunder for at logge ind igen',
  signIn: 'Log ind',
  signOut: 'Log af',
  welcome: 'Velkommen til Lowell Access!',
  yourAvailableServices: 'Her er de tjenester tilgængelig for dig',
  privacyPolicy: 'Privacy policy',
  selectLanguage: 'Vælg sprog',
  home: 'Hjem',
  adminPanel: 'Brugere',

  // Services
  collection: 'Inkasso',
  monitorService: 'Monitor Service',
  creditDecision: 'Credit Information Service',
  informationServices: 'Information Service',
  dashboard: 'Dashboard',
  invoiceWeb: 'Faktura',
  handyman: 'Handyman',
  collectionServices: 'Inkasso',
  invoiceServices: 'Faktura Service',

  // Countries
  denmark: 'Danmark',
  estonia: 'Estland',
  finland: 'Finland',
  sweden: 'Sverige',
  norway: 'Norge',

  // Languages
  danish: 'Dansk',
  estonian: 'Estisk',
  finnish: 'Finsk',
  norwegian: 'Norsk',
  swedish: 'Svensk',

  finlandExtraPhoneInfo: '+358 10 2700 534 (Land line 8,28 c + 5,95 c/min, mobile phone 8,28 c + 17,04 c/min)',
  finlandOpenTimes: 'Mon–Thu 8–17, Fri 8–16',

  // User deletion modal
  deleteUserModalTitle: 'Fjern bruger',
  deleteUserModalContent: 'Denne bruger vil blive fjernet permanent. Vil du fortsætte?',
  deleteUserModalRemoveUser: 'Slet bruger',

  // Forms
  save: 'Gem',
  edit: 'Redigere',
  cancel: 'Annullere',
  firstName: 'Fornavn',
  lastName: 'Efternavn',
  email: 'E-mail',
  language: 'Sprog',
  country: 'Land',

  search: 'Søg bruger',
  searchAsset: 'Søg',

  // Add user form
  addUserFormTitle: 'Tilføj ny bruger',
  addUserDescription: 'Udfyld venligst alle felterne nedenfor. ',
  addUserAdminCheckboxText: 'Administrator',

  // Users
  usersTableHeaderName: 'Navn',
  usersTableHeaderLogin: 'Sidste login',

  // Edit user form
  editUserDelete: 'Slet bruger',
  editUserTitle: 'Grundlæggende information',
  editInformation: 'Rediger information',
  emailEditTooltip: 'E-mailen bruges som en identifikator og kan ikke redigeres',

  // Error messages
  errorFetchUsers: 'Det mislykkedes at hente brugerlisten, prøv venligst igen.',
  errorPostUser: 'Det mislykkedes at tilføje den nye bruger, prøv venligst igen.',
  errorDeleteUser: 'Det mislykkedes at slette brugeren, prøv venligst igen. ',
  errorEditName: 'Det mislykkedes at redigere brugernavnet, prøv venligst igen.',
  errorEditLanguage: 'Det mislykkedes at ændre brugersproget, prøv venligst igen. ',
  errorFetchAssets: 'Det mislykkedes at hente porteføljer og tilladelser.',
  errorFetchPermissions: 'Det mislykkedes at hente tilladelser.',
  errorAddAssetPermissions: 'Det mislykkedes at tilføje tilladelser. ',
  errorDeleteAssetPermissions: 'Det mislykkedes at fjerne tilladelser. ',
  errorExportUsers: 'Det mislykkedes at eksportere brugerlisten til Excel.',
  errorAdminChange: 'Failed to modify admin rights.',
  errorEditUser: 'Det mislykkedes at redigere brugeroplysninger.',
  errorFileTransferClientFetch: 'Failed to fetch File Transfer client information.',
  errorFileTransferFileFetch: 'Failed to fetch files.',
  errorFileTransferDownload: 'Failed to download file.',

  // Success messages
  successPostUser: 'Brugeren blev tilføjet med succes.',
  successDeleteUser: 'Brugeren blev slettet med succes.',
  successEditUserName: 'Brugernavnet blev ændret med succes.',
  successEditUserLanguage: 'Brugersproget blev ændret med succes.',
  successExportUsers: 'Brugerlisten blev eksporteret til Excel med succes.',
  successAdminChange: 'Successfully modified admin rights.',
  successEditUser: 'Brugerinformationen blev ændret med succes.',

  allClients: 'All clients',

  assetPermissions: 'Asset permissions',
  asset: 'Portefølje',
  id: 'Id',
  client: 'Client',
  assetName: 'Navn',
  permissions: 'Tilladelse',

  export: 'Eksporter alle brugere og tilladelser i Excel.',

  yes: 'Ja',
  no: 'Nej',

  formValidationMandatory: 'Obligatorisk felt',
  formValidationInvalidEmail: 'Ugyldig e-mail adresse',
  assetsNotFound: 'Ingen resultater.',
  usersNotFound: 'Ingen brugere fundet.',

  // Permissions
  permissionRead: 'Læs',
  permissionWrite: 'Skrive',
  'permissionRead Write': 'Skrive',
  permissionReporting: 'Rapportere',
  permissionAllowed: 'Tilladt',
  permissionUSER: 'Tilladt',
  permissionCLI: 'Skrive',
  permissionR: 'Læs',

  // Help modal
  help: 'Hjælp',
  helpModalText: `Her er brugervejledningen til Client Admin brugere af Lowell Access. Som Client Admin kan se administrere din virksomheds Access brugere. Du kan se, ændre, tilføje og fjerne brugere. 
  Service specifikke brugervejledninger findes under hver service.

  Du skal bruge Adobe Acrobat Reader eller et andet værktøj, der understøtter PDF format for at åbne filen.`,
  helpModalDownload: 'Download brugervejledning ',

  readMore: 'Læs mere',
  showLess: 'Vis mindre',

  news: 'Nyheder og opdateringer',

  // File transfer UI
  fileTransfer: 'File Transfer',
  download: 'Download',
  upload: 'Upload',
  searchFiles: 'Search for files',
  submit: 'Submit',
  apply: 'Apply',
  emailFilterNotLimited: 'Files are not limited to any email address',
  emailFilterEnterEmail: 'Enter email for limitation',
  emailFilterLimitedTo: 'Files are limited to:',
  emailFilterRemoveLimitation: 'Remove limitation',
  emailFilterAnotherEmail: 'See files for another email',

  downloaded: 'Downloaded',
  notDownloaded: 'Not downloaded',
  status: 'Status',
  uploadDate: 'Upload date',
  downloadedBy: 'Downloaded by',
  noFilesFound: 'No files found',

  inProgress: 'In Progress',
  success: 'Success',
  failed: 'Failed',
  readyForSubmission: 'Ready for submission',
  dragndropTutorial: 'Drag & drop files here or click to select files',
  actions: 'Actions',
  downloadSelected: 'Download selected',

  fileSizeError: 'File size exceeds',

  // Dashboard
  collectionDashboard: 'Inkasso',
  invoiceDashboard: 'Faktura',

  // No mobile page
  pleaseViewOnDesktop: 'Venligst gå til alm. visning.',
  backToHomePage: 'Tilbage til startside',

  // DISCO announcement filter
  filter: 'Filter',
  all: 'Alle',

  // DISCO apps
  'disco_CollectionWeb Denmark': 'Inkasso Danmark',
  'disco_CollectionWeb Finland': 'Inkasso Finland',
  'disco_CollectionWeb Norway': 'Inkasso Norge',
  'disco_CollectionWeb Sweden': 'Inkasso Sverige',
  'disco_InvoiceWeb': 'Faktura',
  'disco_Credit Decision': 'Kreditinformation',
  'disco_Company Monitor': 'Company Monitor',

  new: 'Ny'
};

export default da;
