import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import close from '../../images/Close-dark.png';
import strings from '../../utils/strings';
import FlagLink from './FlagLink';

class DataProtectionModal extends Component {
  constructor(props) {
    super(props);
    this.createUnsafeDataProtectionText = this.createUnsafeDataProtectionText.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  createUnsafeDataProtectionText(html) {
    return { __html: html };
  }

  render() {
    const { localization } = this.props;
    const dataProtectionFlagLinks = [];

    const fiFlagLink = (
      <FlagLink
        className="mb-2"
        key="fiFlagLink"
        url="lowell.fi/tietosuoja"
        country="fi"
      />
    );

    const seFlagLink = (
      <FlagLink
        className="mb-2"
        key="seFlagLink"
        url="lowell.se/lowell/personuppgifter"
        country="se"
      />
    );

    const dkFlagLink = (
      <FlagLink
        className="mb-2"
        key="dkFlagLink"
        url="lowell.dk/lowell/privatlivspolitik"
        country="dk"
      />
    );

    const noFlagLink = (
      <FlagLink
        className="mb-2"
        key="noFlagLink"
        url="lowell.no/lowell/personvernerklaering-og-cookiepolicy"
        country="no"
      />
    );

    const lang = localization.language;
    if (lang === 'en') {
      dataProtectionFlagLinks.push(dkFlagLink);
      dataProtectionFlagLinks.push(fiFlagLink);
      dataProtectionFlagLinks.push(noFlagLink);
      dataProtectionFlagLinks.push(seFlagLink);
    } else if (lang === 'sv') {
      dataProtectionFlagLinks.push(seFlagLink);
      dataProtectionFlagLinks.push(fiFlagLink);
    }

    return (
      <div id="dataProtectionModal" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className="modal-content rounded-0">
            <div className="modal-header py-2">
              <h5 className="modal-title text-dark"><strong>{strings.dataProtection}</strong></h5>
              <button type="button" className="close" data-dismiss="modal">
                <img src={close} alt="" />
              </button>
            </div>
            <div className="modal-body">
              <p className="wspace-pre-line">
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={
                      this.createUnsafeDataProtectionText(strings.dataProtectionContent)
                    }
                />
              </p>
              <div className="d-flex flex-column">{dataProtectionFlagLinks}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DataProtectionModal.propTypes = {
  localization: PropTypes.shape({
    language: PropTypes.string,
  }),
};

DataProtectionModal.defaultProps = {
  localization: {
    language: 'en',
  },
};

function mapStateToProps(state) {
  return {
    localization: state.localization,
  };
}

export default connect(
  mapStateToProps,
  null,
)(DataProtectionModal);
