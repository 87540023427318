/* eslint-disable vars-on-top */
import logger from '../utils/logger';
import { getConfig } from '../utils/configManager';
import { AlertTypes } from './AlertsStore';
import { actionCreators as localizationActionCreators } from './LocalizationStore';
import api from '../utils/ApiUtils';
import { configEnvironments } from '../utils/enums';

const siteUrl = `${window.location.protocol}//${window.location.host}`;
const config = getConfig();

export const PermissionTypes = Object.freeze({
  setPolicyApiLoading: 'SET_POLICY_API_LOADING',
  setDiscoAnnouncementsLoading: 'SET_DISCO_ANNOUNCEMENTS_LOADING',
  updatePermissionsType: 'UPDATE_PERMISSIONS',
  updatePermissionCoreSystemsType: 'UPDATE_PERMISSION_CORE_SYSTEMS',
  updateExternalPbiReports: 'UPDATE_EXTERNAL_PBI_REPORTS',
  updateDiscoAnnouncementsType: 'UPDATE_DISCO_ANNOUNCEMENTS',
  updateContactInfo: 'UPDATE_CONTACT_INFO',
  updateAvailableClients: 'UPDATE_AVAILABLE_CLIENTS',
  setActiveClient: 'SET_ACTIVE_CLIENT',
  setActingIdentity: 'SET_ACTING_IDENTITY',
});

const initialState = {
  policyApiLoading: false,
  discoAnnouncementsLoading: false,
  policyApiPermissions: null,
  policyApiCoreSystems: [],
  externalPbiReports: [],
  discoAnnouncements: [],
  contactInfo: null,
  availableClients: [],
  activeClient: null,
  actingIdentity: null,
};

function mapCoreSystemsToDisco(coreSystems) {
  const mappedCoreSystems = new Set();

  coreSystems.forEach((cs) => {
    switch (cs) {
      case 'FIN_CS':
        mappedCoreSystems.add(1);
        break;
      case 'SWE_CS':
        mappedCoreSystems.add(2);
        break;
      case 'FIN_IW':
        mappedCoreSystems.add(13);
        break;
      case 'DNK_CS_GUG':
        mappedCoreSystems.add(4);
        break;
      case 'DNK_CS_PINK':
        mappedCoreSystems.add(5);
        break;
      case 'NOR_CS':
        mappedCoreSystems.add(3);
        break;
      case 'FIN_IS_CREDITDECISION':
        mappedCoreSystems.add(14);
        break;
      case 'FIN_IS_COMPANYMONITOR':
        mappedCoreSystems.add(12);
        break;
      default:
        break;
    }
  });

  return Array.from(mappedCoreSystems);
}

export const actionCreators = {
  fetchAndSetPermissions: () => {
    logger.log('FetchAndSetPermissions');

    return async (dispatch) => {
      logger.log('FetchAndSetPermissions Thunk starts');

      dispatch({ type: PermissionTypes.setPolicyApiLoading, payload: true });

      await api.get('api/contactus/v2').then((json) => {
        dispatch({
          type: PermissionTypes.updateContactInfo,
          payload: JSON.parse(json),
        });
      }).catch((e) => {
        // eslint-disable-next-line no-console
        console.error('Error when fetching Contact Us info:', e);
      });

      await api.get('api/powerbi/availableReports').then(data => {
        // Adds a caelum report to response for development purposes
        if (getConfig().Environment === configEnvironments.Development) {
          data = data.concat(
            {
              coreSystem: 'CUSTOM_REPORT',
              country: 'FIN',
              sourceId: 'caelum:14fe8fde-4c4f-4c25-9020-54c02299ef28:b8725483-61c0-40e4-9ca2-445a9e88c9ff',
              name: 'Caelum Report'
            }
          )
        }
        dispatch({ type: PermissionTypes.updateExternalPbiReports, payload: data });
      }).catch(() => {
        dispatch({ type: AlertTypes.setAlertText, payload: 'Failed to fetch reports' });
        dispatch({ type: AlertTypes.setShowErrorAlert, payload: true });
      });

      return api.get('api/permissions').then((json) => {
        const availableClients = json.clientRoles.filter(client => client.administrator);
        dispatch({ type: PermissionTypes.updateAvailableClients, payload: availableClients });
        if (availableClients.length > 0) {
          const activeClient = availableClients[0].clientId;
          dispatch({ type: PermissionTypes.setActiveClient, payload: activeClient });
        }

        dispatch(localizationActionCreators.setLanguage(json.language, true));

        dispatch({ type: PermissionTypes.setActingIdentity, payload: json.actingIdentity });

        // Remove all the unnecessary stuff
        const systemPoliciesCombined = [];
        json.clientInformation.forEach((clientObj) => {
          clientObj.systemPolicies.forEach((sysPolicy) => {
            const withClientId = { ...sysPolicy, clientId: clientObj.clientId };
            // Special logic for when to add FIN_CS to list of apps
            if (withClientId.coreSystemId === 'FIN_CS') {
              if (
                (withClientId.roles && withClientId.roles.length > 0)
                || (withClientId.assetPermissions && sysPolicy.assetPermissions.length > 0)
              ) {
                systemPoliciesCombined.push(withClientId);
              }
            } else {
              // If it's not FIN_CS, add it to list without any other requirements
              systemPoliciesCombined.push(withClientId);
            }
          });
        });

        dispatch({
          type: PermissionTypes.updatePermissionsType,
          payload: [...systemPoliciesCombined],
        });

        let coreSystems = new Set();
        systemPoliciesCombined.forEach((sysPolicy) => {
          coreSystems.add(sysPolicy.coreSystemId);
        });

        coreSystems = Array.from(coreSystems);

        dispatch({ type: PermissionTypes.updatePermissionCoreSystemsType, payload: coreSystems });

        if (coreSystems.length > 0) {
          const mappedCoreSystems = mapCoreSystemsToDisco(coreSystems);

          if (mappedCoreSystems.length > 0) {
            dispatch({ type: PermissionTypes.setDiscoAnnouncementsLoading, payload: true });
            api.get(`api/disco?apps=${mappedCoreSystems.join(',')}`)
            .then((json) => {
              logger.log('Disco Json:', json);

              // Add messages for testing purposes
              if (
                config.Environment
                && (
                  config.Environment === 'Development'
                  || config.Environment === 'Staging'
                )
                && json
                && json.length === 0
              ) {
                json.push({
                  title: 'Test message 1',
                  content: 'Test url https://www.google.fi',
                  startTime: '2018-04-16T14:15:18.07',
                  level: 'Information',
                });

                json.push({
                  title: 'Test message level 2',
                  content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
                  startTime: '2018-04-16T14:15:18.07',
                  level: 'Problem',
                });
              }

              dispatch({
                type: PermissionTypes.updateDiscoAnnouncementsType,
                payload: json,
              });
              dispatch({ type: PermissionTypes.setDiscoAnnouncementsLoading, payload: false });
            }).catch((err) => {
              dispatch({ type: PermissionTypes.setDiscoAnnouncementsLoading, payload: false });
              // eslint-disable-next-line no-console
              console.error('Error when fetching DISCO announcements:', err);
            });
          } else {
            dispatch({ type: PermissionTypes.setDiscoAnnouncementsLoading, payload: false });
          }
        }

        dispatch({ type: PermissionTypes.setPolicyApiLoading, payload: false });
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error in fetch:', error);
        dispatch({ type: PermissionTypes.setPolicyApiLoading, payload: false });
        dispatch({ type: AlertTypes.setAlertText, payload: 'to fetch permissions' });
        dispatch({ type: AlertTypes.setShowErrorAlert, payload: true });
      });
    };
  },
  setActiveClient: id => (dispatch) => {
    dispatch({ type: PermissionTypes.setActiveClient, payload: id });
  },
};

export const reducer = (state, action) => {
  const permissionsState = state || initialState;

  switch (action.type) {
    case PermissionTypes.updatePermissionsType:
      return { ...permissionsState, policyApiPermissions: action.payload };

    case PermissionTypes.updatePermissionCoreSystemsType:
      // eslint-disable-next-line no-var
      var filteredCoreSystems = [...permissionsState.policyApiCoreSystems];

      action.payload.forEach((sysPolicyCS) => {
        if (!filteredCoreSystems.includes(sysPolicyCS)) {
          filteredCoreSystems.push(sysPolicyCS);
        }
      });

      return { ...permissionsState, policyApiCoreSystems: filteredCoreSystems };

    case PermissionTypes.updateDiscoAnnouncementsType:
      return { ...permissionsState, discoAnnouncements: action.payload };

    case PermissionTypes.setPolicyApiLoading:
      return { ...permissionsState, policyApiLoading: action.payload };

    case PermissionTypes.setDiscoAnnouncementsLoading:
      return { ...permissionsState, discoAnnouncementsLoading: action.payload };

    case PermissionTypes.updateContactInfo:
      return { ...permissionsState, contactInfo: action.payload };

    case PermissionTypes.updateAvailableClients:
      return { ...permissionsState, availableClients: action.payload };

    case PermissionTypes.setActiveClient:
      return { ...permissionsState, activeClient: action.payload };

    case PermissionTypes.updateExternalPbiReports:
      return { ...permissionsState, externalPbiReports: action.payload };

    case PermissionTypes.setActingIdentity:
      return { ...permissionsState, actingIdentity: action.payload };

    default:
      return permissionsState;
  }
};
