import {
  arrayOf,
  shape,
  string,
  bool,
  number,
  func,
} from 'prop-types';
import React from 'react';
import strings from '../../utils/strings';

const DownloadClientSelection = ({ clients, selectedClient, setSelectedClient }) => {
  const clientOptions = clients.map(client => (
    <option
      key={client.id}
      value={client.id}
    >
      {client.rootFolder}
    </option>
  ));

  return (
    <div className="d-flex align-items-baseline">
      <label className="font-weight-bolder" htmlFor="downloadClients">{strings.client}</label>
      <select
        className="ml-10 form-control"
        id="downloadClients"
        value={selectedClient}
        onChange={({ target: { value } }) => setSelectedClient(Number(value))}
      >
        {clientOptions}
      </select>
    </div>
  );
};

DownloadClientSelection.propTypes = {
  clients: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
    containsEmail: bool.isRequired,
  })).isRequired,
  selectedClient: number.isRequired,
  setSelectedClient: func.isRequired,
};

export default DownloadClientSelection;
