import React, { useState, useEffect } from 'react';
import useUndo from 'use-undo';
import {
  arrayOf,
  number,
  func,
  bool,
  shape,
  string,
} from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as fileTransferActionCreators } from '../../store/FileTransferStore';
import DownloadTable from './DownloadTable';
import DownloadTablePaging from './DownloadTablePaging';
import DownloadClientSelection from './DownloadClientSelection';
import strings from '../../utils/strings';

export const Download = ({
  clients,
  fileResponse,
  getFilesLoading,
  getClientsLoading,
  getClientFiles,
  downloadFile,
  filesBeingDownloaded,
}) => {
  const [page, setPage] = useState(1);
  const [selectedClient, setSelectedClient] = useState(0);
  const [sortColumn, setSortColumn] = useState('created');
  const [sortDirection, setSortDirection] = useState('desc');
  const [filterInput, setFilterInput] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [
    folderState,
    {
      set: setFolder,
      reset: resetFolder,
      undo: undoFolder,
      redo: redoFolder,
      canUndo,
      canRedo,
    },
  ] = useUndo('');
  const { present: folder } = folderState;

  const getFiles = () => {
    getClientFiles(selectedClient, {
      sortColumn,
      sortDirection,
      filter: filterInput,
      emailFilter,
      folder,
      page,
    });
  };

  const handleDownloadSelected = () => {
    selectedRows.forEach((row) => {
      downloadFile(row);
      setSelectedRows([]);
    });
  };

  useEffect(() => resetFolder(''), [selectedClient]);

  useEffect(() => {
    if (clients.length !== 0 && selectedClient === 0) {
      setSelectedClient(clients[0].id);
    }
    if (selectedClient !== 0) {
      getFiles();
    }
  }, [clients, page, selectedClient, sortColumn, sortDirection, folder, emailFilter]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('email')) {
      setEmailFilter(params.get('email'));
    }
  }, []);

  const handleFilter = (event) => {
    event.preventDefault();
    getFiles();
  };

  return (
    <div className="h-100">
      <div className="p-30">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div className="d-flex align-items-center mr-3">
              <i
                className={`mr-20 fas fa-chevron-left${canUndo ? ' clickable' : ' text-disabled'}`}
                onClick={() => {
                  undoFolder();
                  setPage(1);
                }}
              />
              <i
                className={`fas fa-chevron-right${canRedo ? ' clickable' : ' text-disabled'}`}
                onClick={() => {
                  redoFolder();
                  setPage(1);
                }}
              />
            </div>
            <DownloadClientSelection
              clients={clients}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
            />
          </div>
          <div className="d-flex align-items-center">
            <button
              disabled={selectedRows.length === 0}
              className="btn btn-primary mr-32"
              type="button"
              onClick={handleDownloadSelected}
              data-testid="downloadSelectedButton"
            >
              {strings.downloadSelected}
            </button>
            <form
              onSubmit={handleFilter}
              className="navbar-form"
              data-testid="downloadfilterform"
            >
              <div
                className="input-group flex-nowrap"
                id="downloadFileSearch"
              >
                <input
                  className="form-control border-0"
                  value={filterInput}
                  onChange={({ target: { value } }) => setFilterInput(value)}
                  type="text"
                  id="downloadFileSearchInput"
                  placeholder={strings.searchFiles}
                  data-testid="downloadfilter"
                />
                <button type="submit" className="searchButton">
                  <i className="fas fa-search" />
                </button>
              </div>
            </form>
          </div>
        </div>
        <DownloadTable
          files={fileResponse.files || []}
          folders={fileResponse.folders || []}
          downloadFile={downloadFile}
          loading={getFilesLoading || getClientsLoading}
          sortColumn={sortColumn}
          setSortColumn={setSortColumn}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          setFolder={setFolder}
          setPage={setPage}
          filesBeingDownloaded={filesBeingDownloaded}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
        {fileResponse.pageCount !== undefined && (
          <DownloadTablePaging
            page={page}
            pageCount={fileResponse.pageCount}
            setPage={setPage}
          />
        )}
      </div>
    </div>
  );
};

Download.propTypes = {
  clients: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
    containsEmail: bool.isRequired,
  })).isRequired,
  fileResponse: shape({
    files: arrayOf(shape({})),
    folders: arrayOf(shape({})),
    pageCount: number,
  }).isRequired,
  getClientFiles: func.isRequired,
  getFilesLoading: bool.isRequired,
  getClientsLoading: bool.isRequired,
  downloadFile: func.isRequired,
  filesBeingDownloaded: arrayOf(string).isRequired,
};

Download.defaultProps = {

};

const mapStateToProps = state => ({
  clients: state.fileTransfer.clients,
  fileResponse: state.fileTransfer.fileResponse,
  getFilesLoading: state.fileTransfer.getFilesLoading,
  getClientsLoading: state.fileTransfer.getClientsLoading,
  filesBeingDownloaded: state.fileTransfer.filesBeingDownloaded,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...fileTransferActionCreators,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Download);
