import React, { useMemo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import { actionCreators as fileTransferActionCreators } from '../../store/FileTransferStore';
import strings from '../../utils/strings';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '60px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#B0B3B5',
  borderStyle: 'dashed',
  color: '#434A4F',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const UploadFilesDropzone = ({ addFilesToUpload }) => {
  const onDrop = useCallback((acceptedFiles) => {
    addFilesToUpload(acceptedFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isDragActive,
    isDragReject,
    isDragAccept,
  ]);

  return (
    <div className="mb-1" {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <i className="fal fa-upload fa-3x pb-3" />
      <div>{strings.dragndropTutorial}</div>
    </div>
  );
};

UploadFilesDropzone.propTypes = {
  addFilesToUpload: func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  ...fileTransferActionCreators,
}, dispatch);


export default connect(null, mapDispatchToProps)(UploadFilesDropzone);
