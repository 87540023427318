import styles from './ImpersonationInfo.module.scss';
import React from 'react';
import { useActingIdentity } from '../../utils/selectorUtils';
import { ReactComponent as ImpersonatingIcon } from '../../images/impersonating.svg';

const ImpersonationInfo = () => {
  const actingIdentity = useActingIdentity();
  
  return (
    <div className={styles.infoBox}>
      <div className={styles.text}>
        <div className={styles.impersonatingAsText}>
          IMPERSONATING AS:
        </div>
        <div className={styles.identityText}>
          {actingIdentity}
        </div>
      </div>
      <ImpersonatingIcon />
    </div>
  )
}

export default ImpersonationInfo;
