import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import api from '../utils/ApiUtils';

interface IClaim {
  type: string;
  value: string;
}

interface IUserInfo {
  isAuthenticated: boolean;
  claims: IClaim[];
}

export interface IAuthState {
  isAuthenticated: boolean | null;
  user: Record<string, string> | null;
};

const initialState: IAuthState = {
  user: null,
  isAuthenticated: null
};

export const getUser = createAsyncThunk(
  'auth/user',
  async () => {
    const user: IUserInfo = await api.get('auth/getUser');
    return user;
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.claims) {
        const prettyClaims: Record<string, string> = {};
        for (let i = 0; i < data.claims.length; i++) {
          const claim = data.claims[i];
          const t = claim.type.split('/');
          const key = t.length > 1 ? t[t.length - 1] : t[0];
          prettyClaims[key] = claim.value;
        }
        state.user = prettyClaims;
      }
      state.isAuthenticated = data.isAuthenticated;
    });
  }
});

export const useAuth = () => useSelector((state: any): IAuthState => state.auth);

export default authSlice.reducer;
