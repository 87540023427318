import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import close from '../../images/Close-dark.png';
import strings from '../../utils/strings';

const HelpModal = ({ localization }) => {
  const { language } = localization;

  const manualLink = () => {
    if (language === 'fi') {
      return (
        <div className="d-flex flex-column">
          <a
            href="https://lowell-public-manuals.s3.eu-central-1.amazonaws.com/Access_portal_user_manual_fi.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            {`${strings.helpModalDownload} suomeksi`}
          </a>
          <a
            href="https://lowell-public-manuals.s3.eu-central-1.amazonaws.com/Access_portal_user_manual_en.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            {`${strings.helpModalDownload} englanniksi`}
          </a>
        </div>
      );
    }
    return (
      <a
        href="https://lowell-public-manuals.s3.eu-central-1.amazonaws.com/Access_portal_user_manual_en.pdf"
        target="_blank"
        rel="noreferrer noopener"
      >
        {strings.helpModalDownload}
      </a>
    );
  };

  return (
    <div id="helpModal" className="modal fade" role="dialog">
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header py-2">
            <h5 className="modal-title text-dark"><strong>{strings.help}</strong></h5>
            <button type="button" className="close" data-dismiss="modal">
              <img src={close} alt="" />
            </button>
          </div>
          <div className="modal-body">
            <p className="wspace-pre-line">
              <span>{strings.helpModalText}</span>
            </p>
            {manualLink()}
          </div>
        </div>
      </div>
    </div>
  );
};

HelpModal.propTypes = {
  localization: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  localization: state.localization,
});

export default connect(mapStateToProps)(HelpModal);
