import React from 'react';
import PropTypes, { string } from 'prop-types';
import AssetRow from './AssetRow';
import RoleRow from './RoleRow';
import strings from '../../utils/strings';
import {
  userType,
  roleType,
  userPermissionsType,
  clientType,
} from '../../utils/propTypeTemplates';
import { collectionCoreSystems, swedishRoles } from '../../utils/coreSystemHandling';

const GroupedAssetsAndRoles = ({
  client,
  assets,
  availableUserRoles,
  userRoles,
  user,
  modifyPermissions,
  modifyRoles,
  userPermissions,
  modifyUserPermissionsLoading,
}) => {
  if (assets.every(a => a.assetPermission.length === 0) && availableUserRoles.length === 0) {
    return null;
  }

  const collectionAssetTypes = collectionCoreSystems;
  const [...collectionAssetRows] = assets
    .filter(asset => asset.assetPermission.some(a => collectionAssetTypes.includes(a.AssetId.split(':')[0])))
    .map(asset => asset.assetPermission.map(ap => (
      <AssetRow
        assetId={ap.AssetId}
        assetName={ap.AssetName}
        description={ap.Description || null}
        availablePermissions={asset.availablePermissions}
        user={user}
        modifyPermissions={modifyPermissions}
        client={client}
        userPermissions={userPermissions}
        modifyUserPermissionsLoading={modifyUserPermissionsLoading}
        key={ap.AssetId}
      />
    )));
  const [invoiceAssetRows] = assets
    .filter(asset => asset.assetPermission.some(a => a.AssetId.split(':')[0] === 'FIN_IW'))
    .map(asset => asset.assetPermission.map(ap => (
      <AssetRow
        assetId={ap.AssetId}
        assetName={ap.AssetName}
        description={ap.Description || null}
        availablePermissions={asset.availablePermissions}
        user={user}
        modifyPermissions={modifyPermissions}
        client={client}
        userPermissions={userPermissions}
        modifyUserPermissionsLoading={modifyUserPermissionsLoading}
        key={ap.AssetId}
      />
    )));
  
  const [creditDecisionAssetRows] = assets
    .filter(asset => asset.assetPermission.some(a => a.AssetId.split(':')[0] === 'FIN_IS_CREDITDECISION'))
    .map(asset => asset.assetPermission.map(ap => (
      <AssetRow
        assetId={ap.AssetId}
        assetName={ap.AssetName}
        description={ap.Description || null}
        availablePermissions={asset.availablePermissions}
        user={user}
        modifyPermissions={modifyPermissions}
        client={client}
        userPermissions={userPermissions}
        modifyUserPermissionsLoading={modifyUserPermissionsLoading}
        key={ap.AssetId}
      />
    )));

  const collectionSubHeader = availableUserRoles.length > 0
    && collectionAssetRows
    && (
      <tr>
        <td className="col-12">
          <span>{strings.collection}</span>
        </td>
      </tr>
    );

  const isSubHeader = creditDecisionAssetRows && (
    <tr>
      <td className="col-12">
        <span>{strings.creditDecision}</span>
      </td>
    </tr>
  );

  return (
    <React.Fragment key={client.clientId}>
      <tr key={client.clientName} className="subheader" data-testid="groupedAssetsAndRoles">
        <th className="span" colSpan="5" scope="colgroup">
          {client.clientName}
        </th>
      </tr>
      {availableUserRoles.filter(x => x.CoreSystemId === 'SWE_CS' && swedishRoles.includes(x.RoleId)).length > 0 && (
        <RoleRow
          client={client}
          roles={availableUserRoles.filter(x => x.CoreSystemId === 'SWE_CS' && swedishRoles.includes(x.RoleId))}
          userRoles={userRoles}
          modifyRoles={modifyRoles}
          user={user}
          modifyUserPermissionsLoading={modifyUserPermissionsLoading}
          label={strings.collection}
          key={`${client.clientId}-nova`}
        />
      )}
      {availableUserRoles.filter(x => x.CoreSystemId === 'FILESERVICE').length > 0 && (
        <RoleRow
          client={client}
          roles={availableUserRoles.filter(x => x.CoreSystemId === 'FILESERVICE')}
          userRoles={userRoles}
          modifyRoles={modifyRoles}
          user={user}
          modifyUserPermissionsLoading={modifyUserPermissionsLoading}
          label={strings.fileTransfer}
          key={`${client.clientId}-fileservice`}
        />
      )}
      {availableUserRoles.filter(x => x.CoreSystemId === 'FIN_IS_COMPANYMONITOR').length > 0 && (
        <RoleRow
          client={client}
          roles={availableUserRoles.filter(x => x.CoreSystemId === 'FIN_IS_COMPANYMONITOR')}
          userRoles={userRoles}
          modifyRoles={modifyRoles}
          user={user}
          modifyUserPermissionsLoading={modifyUserPermissionsLoading}
          label={strings.monitorService}
          key={`${client.clientId}-companymonitor`}
        />
      )}
      {availableUserRoles.filter(x => x.CoreSystemId === 'FIN_IW').length > 0 && (
        <RoleRow
          client={client}
          roles={availableUserRoles.filter(x => x.CoreSystemId === 'FIN_IW')}
          userRoles={userRoles}
          modifyRoles={modifyRoles}
          user={user}
          modifyUserPermissionsLoading={modifyUserPermissionsLoading}
          label={strings.invoiceWeb}
          key={`${client.clientId}-invoice`}
        />
      )}
      {invoiceAssetRows}
      {collectionSubHeader}
      {collectionAssetRows}
      {isSubHeader}
      {creditDecisionAssetRows}
    </React.Fragment>
  );
};

GroupedAssetsAndRoles.propTypes = {
  client: clientType.isRequired,
  assets: PropTypes.arrayOf(PropTypes.shape({
    availablePermissions: PropTypes.arrayOf(PropTypes.shape({
      Name: PropTypes.string,
      Id: PropTypes.number,
    })).isRequired,
    assetPermission: PropTypes.arrayOf(PropTypes.shape({
      AssetId: PropTypes.string,
      AssetName: PropTypes.string,
    })).isRequired,
  })).isRequired,
  user: userType.isRequired,
  userPermissions: userPermissionsType,
  availableUserRoles: roleType.isRequired,
  modifyPermissions: PropTypes.func.isRequired,
  userRoles: roleType,
  modifyRoles: PropTypes.func.isRequired,
  modifyUserPermissionsLoading: PropTypes.bool.isRequired,
};

GroupedAssetsAndRoles.defaultProps = {
  userPermissions: [],
  userRoles: [],
};

export default GroupedAssetsAndRoles;
