import React, { Component } from 'react';
import ContactUsModal from './modals/ContactUsModal';
import DataProtectionModal from './modals/DataProtectionModal';
import DeleteUserModal from './modals/DeleteUserModal';
import HelpModal from './modals/HelpModal';

// eslint-disable-next-line react/prefer-stateless-function
class BootstrapModals extends Component {
  render() {
    return (
      <React.Fragment>
        <ContactUsModal />
        <DataProtectionModal />
        <DeleteUserModal />
        <HelpModal />
      </React.Fragment>
    );
  }
}

export default BootstrapModals;
