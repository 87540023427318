import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckboxWithLoadingSpinner from './CheckboxWithLoadingSpinner';
import { userType, roleType, clientType } from '../../utils/propTypeTemplates';

const RoleCheckbox = ({
  roleId,
  client,
  userRoles,
  modifyRoles,
  user,
  coreSystemId,
  modifyUserPermissionsLoading,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(userRoles
      .find(ur => ur.ClientId === client.clientId
        && ur.RoleId === roleId
        && ur.CoreSystemId === coreSystemId) !== undefined);
  }, [userRoles, roleId, client]);

  const requestBody = () => ({
    roleId,
    coreSystemId,
    user: {
      FirstName: user.FirstName,
      LastName: user.LastName,
      Email: user.Email,
      Language: user.Language,
    },
    clientId: client.clientId,
  });

  return (
    <CheckboxWithLoadingSpinner
      checked={checked}
      modifyPermissions={modifyRoles}
      requestBody={requestBody}
      permission={roleId}
      loading={modifyUserPermissionsLoading}
    />
  );
};

RoleCheckbox.propTypes = {
  roleId: PropTypes.string.isRequired,
  userRoles: roleType.isRequired,
  modifyRoles: PropTypes.func.isRequired,
  client: clientType.isRequired,
  user: userType.isRequired,
  coreSystemId: PropTypes.string.isRequired,
  modifyUserPermissionsLoading: PropTypes.bool.isRequired,
};

export default RoleCheckbox;
