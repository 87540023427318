export const AlertTypes = Object.freeze({
  setShowErrorAlert: 'SHOW_ERROR_ALERT',
  setShowSuccessAlert: 'SHOW_SUCCESS_ALERT',
  setAlertText: 'SET_ALERT_TEXT',
});


const initialState = {
  showErrorAlert: false,
  showSuccessAlert: false,
  alertText: '',
};

export const actionCreators = {
  setShowErrorAlert: showErrorAlert => (dispatch) => {
    dispatch({ type: AlertTypes.setShowErrorAlert, payload: showErrorAlert });
  },
  setShowSuccessAlert: showSuccessAlert => (dispatch) => {
    dispatch({ type: AlertTypes.setShowSuccessAlert, payload: showSuccessAlert });
  },
  setAlertText: alertText => (dispatch) => {
    dispatch({ type: AlertTypes.setAlertText, payload: alertText });
  },
};


export const reducer = (state, action) => {
  const alertStoreState = state || initialState;

  switch (action.type) {
    case AlertTypes.setShowErrorAlert:
      return { ...alertStoreState, showErrorAlert: action.payload };
    case AlertTypes.setShowSuccessAlert:
      return { ...alertStoreState, showSuccessAlert: action.payload };
    case AlertTypes.setAlertText:
      return { ...alertStoreState, alertText: action.payload };
    default:
      return alertStoreState;
  }
};
