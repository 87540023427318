import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Overlay, Popover } from 'react-bootstrap';
import PermissionCheckbox from './PermissionCheckbox';
import { friendlyAssetId } from '../../utils/friendlyNames';
import {
  userType,
  userPermissionsType,
  clientType,
} from '../../utils/propTypeTemplates';

const AssetRow = ({
  assetId,
  assetName,
  description,
  availablePermissions,
  user,
  modifyPermissions,
  client,
  userPermissions,
  modifyUserPermissionsLoading,
}) => {
  const [target, setTarget] = useState(null);
  const [show, setShow] = useState(false);

  const permissionCheckboxes = availablePermissions.map(permission => (
    <div key={permission.Name}>
      <PermissionCheckbox
        permissionName={permission.Name}
        asset={{ assetId, client }}
        userPermissions={userPermissions}
        modifyPermissions={modifyPermissions}
        user={user}
        modifyUserPermissionsLoading={modifyUserPermissionsLoading}
      />
    </div>
  ));

  return (
    <tr key={assetId} data-testid="assetRow" className="d-flex align-items-stretch">
      <td
        className="col-4 pr-0"
      >
        <div
          className="pl-2 text-break border-dashed-left border-dashed-top border-dashed-bottom h-100"
          data-testid="assetId"
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          <span
            ref={t => setTarget(t)}
          >
            {friendlyAssetId(assetId)}
          </span>
          <Overlay target={target} placement="right-start" show={description !== null && description.length > 0 && show}>
            {({
              placement,
              scheduleUpdate,
              arrowProps,
              outOfBoundaries,
              show: _show,
              ...props
            }) => (
              <Popover {...props} className="description-popover">
                <div data-testid="assetDescription">
                  {description}
                </div>
              </Popover>
            )}
          </Overlay>
        </div>
      </td>
      <td
        className="col-5 pl-0"
      >
        <div
          className="text-break border-dashed-right border-dashed-top border-dashed-bottom h-100"
          data-testid="assetName"
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          {assetName}
        </div>
      </td>
      <td className="col-3">
        {permissionCheckboxes}
      </td>

    </tr>
  );
};

AssetRow.propTypes = {
  assetId: PropTypes.string.isRequired,
  assetName: PropTypes.string,
  availablePermissions: PropTypes.arrayOf(PropTypes.shape({
    Name: PropTypes.string.isRequired,
  })).isRequired,
  user: userType.isRequired,
  modifyPermissions: PropTypes.func.isRequired,
  client: clientType.isRequired,
  userPermissions: userPermissionsType.isRequired,
  modifyUserPermissionsLoading: PropTypes.bool.isRequired,
  description: PropTypes.string,
};

AssetRow.defaultProps = {
  assetName: '',
  description: '',
};

export default AssetRow;
