import React, { useEffect, useRef, useState } from 'react';
import { string, func, number } from 'prop-types';

const UserListHeader = ({
  sortedBy,
  sortBy,
  sortUsers,
  sortIcon,
  colSize,
  testId,
  headerText,
  leftPadding,
}) => {
  const [shouldShowTitle, setShouldShowTitle] = useState(false);
  const headerTextContent = useRef(null);

  useEffect(() => {
    if (headerTextContent.current !== null) {
      const child = headerTextContent.current.children[0];
      if (child.offsetWidth > headerTextContent.current.offsetWidth) {
        setShouldShowTitle(true);
      }
    }
  });

  return (
    <th className={`border-top-0 col-${colSize}${leftPadding !== 0 ? ` pl-${leftPadding}` : ''}`}>
      <span
        className="d-flex justify-content-start align-items-baseline"
      >
        <span
          className="overflow-ellipsis clickable"
          onClick={() => sortUsers(sortBy)}
          role="presentation"
          data-testid={testId}
          ref={headerTextContent}
          title={shouldShowTitle ? headerText : ''}
        >
          <span>
            {headerText}
          </span>
        </span>
        <i
          onClick={() => sortUsers(sortBy)}
          role="presentation"
          className={`fas ${sortedBy === sortBy ? sortIcon() : 'fa-sort'} pl-1 clickable`}
        />
      </span>
    </th>
  );
};

UserListHeader.propTypes = {
  sortedBy: string.isRequired,
  sortBy: string.isRequired,
  sortUsers: func.isRequired,
  sortIcon: func.isRequired,
  colSize: number.isRequired,
  testId: string.isRequired,
  headerText: string.isRequired,
  leftPadding: number,
};

UserListHeader.defaultProps = {
  leftPadding: 0,
};

export default UserListHeader;
