import React from 'react';
import {
  arrayOf,
  bool,
  func,
  string,
  shape,
} from 'prop-types';
import { friendlyDate } from '../../utils/dateTime';
import DownloadTableHeader from './DownloadTableHeader';
import { ReactComponent as FolderIcon } from '../../images/folder_icon.svg';
import { ReactComponent as FileIcon } from '../../images/file_icon.svg';
import strings from '../../utils/strings';

const DownloadTable = ({
  files,
  folders,
  downloadFile,
  loading,
  sortColumn,
  setSortColumn,
  sortDirection,
  setSortDirection,
  setFolder,
  setPage,
  filesBeingDownloaded,
  selectedRows,
  setSelectedRows,
}) => {
  const loadingSpinner = (
    <div className="d-flex justify-content-center mt-5">
      <img alt="Loading files" src="/lowell_loading.gif" />
    </div>
  );

  const iconColumnStyle = { maxWidth: '42px', paddingLeft: '4px' };

  const folderRows = folders.map(folder => (
    <div
      key={folder.name}
      onDoubleClick={() => {
        setFolder(folder.url.split('=').pop());
        setPage(1);
      }}
      className="download-table-row row justify-content-between align-items-center clickable"
    >
      <div className="col-1" style={iconColumnStyle}>
        <FolderIcon />
      </div>
      <div className="col-6">
        <span>
          {folder.name}
        </span>
      </div>
      <div className="col-1" />
      <div className="col-2 align-right">
        {friendlyDate(folder.createdDate)}
      </div>
      <div className="col-2 align-right">-</div>
    </div>
  ));

  const fileRows = files.map(file => (
    <div
      key={file.fileName}
      onClick={() => setSelectedRows(prev => (prev.includes(file.downloadUrl)
        ? prev.filter(x => x !== file.downloadUrl)
        : prev.concat(file.downloadUrl)))}
      onDoubleClick={
        filesBeingDownloaded.includes(file.downloadUrl) ? () => {} : () => downloadFile(file.downloadUrl)}
      className={`download-table-row row justify-content-between align-items-center clickable${selectedRows.includes(file.downloadUrl) ? ' selected' : ''}`}
      data-testid="downloadFileRow"
    >
      <div className="col-1" style={iconColumnStyle}>
        {filesBeingDownloaded.includes(file.downloadUrl)
          ? <img src="/lowell_loading.gif" alt="" height="42" />
          : <FileIcon />
        }
      </div>
      <div
        className="col-6 overflow-anywhere"
      >
        <span
          onKeyDown={({ key }) => { if (key === 'Enter') downloadFile(file.downloadUrl); }}
          role="presentation"
        >
          {file.fileName}
        </span>
      </div>
      <div className="col-1 no-wrap">
        {file.lastDownloadedBy ? strings.downloaded : strings.notDownloaded}
      </div>
      <div className="col-2 align-right">{friendlyDate(file.createdDate)}</div>
      <div
        className="col-2 align-right overflow-ellipsis"
      >
        {file.lastDownloadedBy || '-'}
      </div>
    </div>
  ));

  return (
    <div>
      <div className="row justify-content-between font-weight-bold mt-15">
        <div className="col-1" style={iconColumnStyle} />
        <DownloadTableHeader
          columnName="filename"
          headerText={strings.usersTableHeaderName}
          setSortColumn={setSortColumn}
          sortColumn={sortColumn}
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          className="col-6"
        />
        <div className="col-1">{strings.status}</div>
        <DownloadTableHeader
          columnName="created"
          headerText={strings.uploadDate}
          setSortColumn={setSortColumn}
          sortColumn={sortColumn}
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          alignRight
          className="col-2"
        />
        <div className="col-2 align-right overflow-ellipsis">{strings.downloadedBy}</div>
      </div>
      {loading ? (
        loadingSpinner
      ) : (
        (folderRows.length === 0 && fileRows.length === 0) ? (
          <div>{strings.noFilesFound}</div>
        ) : (
          <>
            {folderRows}
            {fileRows}
          </>
        )
      )}
    </div>
  );
};

DownloadTable.propTypes = {
  files: arrayOf(shape()).isRequired,
  folders: arrayOf(shape({})).isRequired,
  downloadFile: func.isRequired,
  loading: bool.isRequired,
  sortColumn: string.isRequired,
  setSortColumn: func.isRequired,
  sortDirection: string.isRequired,
  setSortDirection: func.isRequired,
  setFolder: func.isRequired,
  setPage: func.isRequired,
  filesBeingDownloaded: arrayOf(string).isRequired,
  selectedRows: arrayOf(string).isRequired,
  setSelectedRows: func.isRequired,
};

export default DownloadTable;
