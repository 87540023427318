import React from 'react';
import PropTypes from 'prop-types';
import finland from '../../images/flag_finland.png';
import denmark from '../../images/flag_denmark.png';
import estonia from '../../images/flag_estonia.png';
import norway from '../../images/flag_norway.png';
import sweden from '../../images/flag_sweden.png';

const FlagLink = ({ country, className, url }) => {
  let flagIcon = <img className="flagicon" src={finland} alt="Finland" />;

  if (country === 'dk') {
    flagIcon = <img className="flagicon" src={denmark} alt="Denmark" />;
  } else if (country === 'ee') {
    flagIcon = <img className="flagicon" src={estonia} alt="Estonia" />;
  } else if (country === 'se') {
    flagIcon = <img className="flagicon" src={sweden} alt="Sweden" />;
  } else if (country === 'no') {
    flagIcon = <img className="flagicon" src={norway} alt="Norway" />;
  }

  const classes = ['d-inline-block'];

  if (className && className.split(' ').length > 0) {
    className.split(' ').forEach(c => classes.push(c));
  }

  return (
    <div>
      <a href={`https://${url}`} target="_blank" rel="noreferrer noopener" className={classes.join(' ')}>
        <span className="pr-5">
          {flagIcon}
        </span>
        <span>
          {url}
        </span>
      </a>
    </div>
  );
};

FlagLink.propTypes = {
  country: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default FlagLink;
