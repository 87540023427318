import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { actionCreators as userManagementActionCreators } from '../../store/UserManagementStore';
import strings from '../../utils/strings';
import EditUserRow from './EditUserRow';

export class EditUserForm extends Component {
  static handleSubmit(e) {
    e.preventDefault();
  }

  constructor(props) {
    super(props);
    const { selectedUser } = this.props;
    this.state = {
      FirstName: selectedUser.FirstName,
      LastName: selectedUser.LastName,
      userId: selectedUser.Id,
      admin: selectedUser.Administrator,
      FirstNameValid: true,
      LastNameValid: true,
      formErrors: { FirstName: '', LastName: '' },
      edit: false,
    };
    this.handleFormInputChange = this.handleFormInputChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleAdminChange = this.handleAdminChange.bind(this);
    this.validateNameFields = this.validateNameFields.bind(this);
  }

  componentDidUpdate() {
    const { user } = this.props;
    const { userId } = this.state;
    if (user.Id !== userId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        FirstName: user.FirstName,
        LastName: user.LastName,
        userId: user.Id,
        admin: user.Administrator,
      });
    }
  }

  validateNameFields(user) {
    const { formErrors } = this.state;
    const { selectedUser } = this.props;
    let firstNameValid;
    let lastNameValid;
    const fieldValidationErrors = formErrors;
    if (user) {
      firstNameValid = user.FirstName.length >= 1;
      lastNameValid = user.LastName.length >= 1;
    } else {
      firstNameValid = selectedUser.FirstName.length >= 1;
      lastNameValid = selectedUser.LastName.length >= 1;
    }
    fieldValidationErrors.FirstName = firstNameValid ? '' : strings.formValidationMandatory;
    fieldValidationErrors.LastName = lastNameValid ? '' : strings.formValidationMandatory;
    this.setState({
      FirstNameValid: firstNameValid,
      LastNameValid: lastNameValid,
      formErrors: fieldValidationErrors,
    });
    return firstNameValid && lastNameValid;
  }

  handleFormInputChange(e) {
    const { selectedUser, setUserInputs } = this.props;
    const { name } = e.target;
    const user = { ...selectedUser };
    user[name] = e.target.value;
    setUserInputs(user);
    this.validateNameFields(user);
  }

  handleSave() {
    const {
      selectedUser,
      availableClients,
      setUserInputs,
      editUser,
    } = this.props;
    const {
      FirstName,
      LastName,
      admin,
    } = this.state;
    const userInfo = { ...selectedUser };
    if (userInfo.Administrator === admin) {
      userInfo.Administrator = null;
      if (userInfo.FirstName === FirstName) userInfo.FirstName = '';
      if (userInfo.LastName === LastName) userInfo.LastName = '';
    }
    if (this.validateNameFields()) {
      editUser(userInfo, availableClients).then(() => {
        this.setState({
          admin: userInfo.Administrator !== null ? userInfo.Administrator : admin,
          FirstName: userInfo.FirstName.length > 0 ? userInfo.FirstName : FirstName,
          LastName: userInfo.LastName.length > 0 ? userInfo.LastName : LastName,
          edit: false,
        });
      }).catch(() => {
        const user = { ...selectedUser };
        user.Administrator = admin;
        user.FirstName = FirstName;
        user.LastName = LastName;
        setUserInputs(user);
        this.setState({ edit: false });
      });
    }
  }

  handleCancel() {
    const {
      setUserInputs,
      selectedUser,
    } = this.props;
    const {
      FirstName,
      LastName,
      admin,
    } = this.state;
    const user = {
      ...selectedUser,
      FirstName,
      LastName,
      Administrator: admin,
    };
    setUserInputs(user);
    this.setState({
      edit: false,
      FirstNameValid: true,
      LastNameValid: true,
      formErrors: { FirstName: '', LastName: '' },
    });
  }

  handleAdminChange() {
    const {
      selectedUser,
      setUserInputs,
    } = this.props;
    const user = { ...selectedUser };
    user.Administrator = !user.Administrator;
    setUserInputs(user);
  }

  render() {
    const {
      selectedUser,
      emailaddress,
      deleteUserLoading,
      editUserLoading,
      hasAssets,
    } = this.props;
    const {
      FirstNameValid,
      FirstName,
      LastName,
      LastNameValid,
      edit,
      admin,
      formErrors,
    } = this.state;

    const adminCheckbox = edit
      ? (
        <div className="row">
          <div className="col">
            <input
              type="checkbox"
              checked={selectedUser.Administrator}
              onChange={this.handleAdminChange}
              name="Administrator"
              data-testid="adminCheckbox"
            />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col">
            <span>{ admin ? strings.yes : strings.no }</span>
          </div>
        </div>
      );

    const deleteButton = () => {
      if (emailaddress !== selectedUser.Email) {
        return deleteUserLoading
          ? <img className="loadingSpinnerSmall pr-3" alt="" src="/lowell_loading.gif" />
          : (
            <div className="mr-40">
              <button id="deleteUserButton" type="button" data-toggle="modal" data-target="#deleteUserModal" className="">
                <strong>
                  {strings.editUserDelete}
                </strong>
              </button>
            </div>
          );
      }
      return '';
    };

    const editOrSaveButton = edit ? (
      <>
        <button type="button" className="cancel-button mr-15" onClick={this.handleCancel}><strong>{strings.cancel}</strong></button>
        <button type="button" className="save-button" onClick={this.handleSave}><strong>{strings.save}</strong></button>
      </>
    ) : (
      <button
        type="button"
        id="editUserInformationButton"
        data-testid="editUserInformationButton"
        onClick={() => this.setState({ edit: !edit })}
      >
        <strong>
          {strings.editInformation}
        </strong>
      </button>
    );

    const editButton = editUserLoading ? <img className="loadingSpinnerSmall pr-3" alt="" src="/lowell_loading.gif" />
      : editOrSaveButton;

    return (
      <div className="pt-30 pb-15">
        <form onSubmit={this.handleSubmit} id="editUserForm">
          <h6 className="pb-20 mb-0 pl-20">
            <b>
              {`${strings.editUserTitle}:`}
            </b>
          </h6>

          <div className="pl-20">
            <EditUserRow
              label={strings.firstName}
              name="FirstName"
              valid={FirstNameValid}
              content={selectedUser.FirstName}
              originalValue={FirstName}
              handleInputChange={this.handleFormInputChange}
              edit={edit}
              errorMessage={formErrors.FirstName}
            />
            <EditUserRow
              label={strings.lastName}
              name="LastName"
              valid={LastNameValid}
              content={selectedUser.LastName}
              originalValue={LastName}
              handleInputChange={this.handleFormInputChange}
              edit={edit}
              errorMessage={formErrors.LastName}
            />
            <EditUserRow
              label={strings.email}
              content={selectedUser.Email}
              name="Email"
              hasAssets={hasAssets}
            />
            {hasAssets && (
              <div className="row no-gutters">
                <label
                  htmlFor="adminEdit"
                >
                  {`${strings.addUserAdminCheckboxText}:`}
                </label>
                <div className="col-4">
                  {adminCheckbox}
                </div>
              </div>
            )}
          </div>
          {hasAssets && (
          <div className="pr-20 d-flex justify-content-end">
            {deleteButton()}
            {editButton}
          </div>
          )}
        </form>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  usermanagementInfo: state.usermanagement.usermanagementInfo,
  selectedUser: state.usermanagement.selectedUser,
  selectedUserId: state.usermanagement.selectedUserId,
  clientId: state.permissions.activeClient,
  editUserLoading: state.usermanagement.userManagementEditUserLoading,
  deleteUserLoading: state.usermanagement.userManagementDeleteUserLoading,
  emailaddress: state.auth.user.emailaddress,
  availableClients: state.permissions.availableClients,
  activeLanguage: state.localization.language,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...userManagementActionCreators,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditUserForm);

EditUserForm.propTypes = {
  user: PropTypes.shape({
    Id: PropTypes.number,
    clientId: PropTypes.number,
    Email: PropTypes.string,
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
  }).isRequired,
  selectedUser: PropTypes.shape({
    Email: PropTypes.string,
  }).isRequired,
  setUserInputs: PropTypes.func.isRequired,
  availableClients: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
  editUser: PropTypes.func.isRequired,
  deleteUserLoading: PropTypes.bool,
  editUserLoading: PropTypes.bool,
  hasAssets: PropTypes.bool.isRequired,
};
EditUserForm.defaultProps = {
  deleteUserLoading: false,
  editUserLoading: false,
};
