import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import close from '../../images/Close-dark.png';
import strings from '../../utils/strings';
import { actionCreators as userManagementActionCreators } from '../../store/UserManagementStore';

export class DeleteUserModal extends Component {
  constructor(props) {
    super(props);
    this.deleteUser = this.deleteUser.bind(this);
  }

  deleteUser() {
    const {
      massDeleteUser,
      availableClients,
      selectedUser,
      setUserInputs,
    } = this.props;
    massDeleteUser(availableClients, selectedUser.Email).then(() => {
      setUserInputs({ Email: '' });
    });
  }

  render() {
    return (
      <div id="deleteUserModal" className="modal fade" role="dialog">
        <div className="modal-dialog modal-md">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h5 className="modal-title text-dark"><strong>{strings.deleteUserModalTitle}</strong></h5>
              <button type="button" className="close" data-dismiss="modal">
                <img src={close} alt="" />
              </button>
            </div>
            <div className="modal-body">
              <p className="wspace-pre-line">
                <span>{strings.deleteUserModalContent}</span>
              </p>
              <div className="d-flex justify-content-end">
                <button type="button" className="cancel-button" data-dismiss="modal"><strong>{strings.cancel}</strong></button>
                <button onClick={this.deleteUser} type="button" className="save-button ml-20" data-dismiss="modal"><strong>{strings.deleteUserModalRemoveUser}</strong></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DeleteUserModal.propTypes = {
  setUserInputs: PropTypes.func.isRequired,
  massDeleteUser: PropTypes.func.isRequired,
  availableClients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedUser: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  usermanagementInfo: state.usermanagement.usermanagementInfo,
  selectedUser: state.usermanagement.selectedUser,
  availableClients: state.permissions.availableClients,
  activeLanguage: state.localization.language,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...userManagementActionCreators,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteUserModal);
