import React, { useState } from 'react';
import { string } from 'prop-types';
import styles from './EasyBanner.module.scss';
import strings from '../../utils/strings';

const EasyBanner = ({ url }) => {
  const [closed, setClosed] = useState(false);

  return !closed ? (
    <div className={styles.banner}>
      <div className="d-flex">
        <i className={`fas fa-info-circle ${styles.infoIcon}`} />
        <div>
          <div className={styles.text}>
            {strings.easyTryText}
          </div>
          <div className="d-flex">
            <div className="mr-3">
              <a
                className={styles.link}
                href={url}
                target="_blank"
                rel="noreferrer noopener"
              >
                {strings.yes}
              </a>
              <span>{` ${strings.takeMeThere}`}</span>
            </div>
            <div>
              <button
                className={styles.button}
                type="button"
                onClick={() => setClosed(true)}
              >
                {strings.no}
              </button>
              <span>{` ${strings.closeBanner}`}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

EasyBanner.propTypes = {
  url: string.isRequired,
};

export default EasyBanner;
