/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  bool,
  func,
  string,
} from 'prop-types';

const DownloadTableHeader = ({
  sortColumn,
  setSortColumn,
  sortDirection,
  setSortDirection,
  columnName,
  headerText,
  alignRight,
  className,
}) => {
  const sortIcon = () => {
    if (sortColumn !== columnName) {
      return 'fa-sort';
    }
    return sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down';
  };

  const sort = () => {
    setSortColumn(columnName);
    setSortDirection(sortColumn === columnName
      ? sortDirection === 'asc' ? 'desc' : 'asc'
      : columnName === 'filename'
        ? 'asc'
        : 'desc');
  };

  return (
    <div className={`${className}`}>
      <span
        className={`d-flex justify-content-${alignRight ? 'end' : 'start'} align-items-baseline`}
      >
        <span
          className="overflow-ellipsis clickable"
          onClick={() => sort()}
          role="presentation"
          data-testid={`downloadsort-${columnName}`}
        >
          <span>
            {headerText}
          </span>
        </span>
        <i
          onClick={() => sort()}
          role="presentation"
          className={`fas ${sortColumn === columnName ? sortIcon() : 'fa-sort'} pl-1 clickable`}
        />
      </span>
    </div>
  );
};

DownloadTableHeader.propTypes = {
  sortColumn: string.isRequired,
  setSortColumn: func.isRequired,
  sortDirection: string.isRequired,
  setSortDirection: func.isRequired,
  columnName: string.isRequired,
  headerText: string.isRequired,
  alignRight: bool,
  className: string,
};

DownloadTableHeader.defaultProps = {
  className: '',
  alignRight: false,
};

export default DownloadTableHeader;
