import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Overlay, Popover } from 'react-bootstrap';
import CheckboxWithLoadingSpinner from './CheckboxWithLoadingSpinner';
import strings from '../../utils/strings';
import {
  userType,
  roleType,
  assetType,
  userPermissionsType,
} from '../../utils/propTypeTemplates';
import { collectionCoreSystems } from '../../utils/coreSystemHandling';

export const PermissionPopover = ({
  assets,
  user,
  userPermissions,
  modifyPermissions,
  modifyUserPermissionsLoading,
}) => {
  const [target, setTarget] = useState(null);
  const [show, setShow] = useState(false);
  const [possibleCollectionPermissions, setPossibleCollectionPermissions] = useState([]);
  const attachRef = t => setTarget(t);

  useEffect(() => {
    const collectionTypes = collectionCoreSystems;
    const possibles = [];
    assets.filter(a => collectionTypes.includes(a.assetId.split(':')[0])).forEach((p) => {
      p.permissions.forEach((perm) => {
        if (!possibles.includes(perm)) {
          possibles.push(perm);
        }
      });
    });
    setPossibleCollectionPermissions(possibles);
  }, [assets]);

  if (assets.length === 0) {
    return null;
  }

  const assetChecked = permissionType => assets.filter(p => p.permissions.includes(permissionType))
    .filter(p => userPermissions
      .find(up => (
        up.assetId === p.assetId
      && up.permission === permissionType)) === undefined).length === 0;

  const requestBody = (permissionType) => {
    if (assetChecked(permissionType)) {
      return assets.filter(p => p.permissions.includes(permissionType))
        .map(p => ({
          assetId: p.assetId,
          permission: permissionType,
          user: {
            Email: user.Email,
          },
          clientId: p.client.clientId,
        }));
    }
    return assets.filter(p => p.permissions.includes(permissionType))
      .filter(p => userPermissions
        .find(up => up.assetId === p.assetId && up.permission === permissionType) === undefined)
      .map(p => ({
        assetId: p.assetId,
        permission: permissionType,
        user: {
          FirstName: user.FirstName,
          LastName: user.LastName,
          Email: user.Email,
          Language: user.Language,
        },
        clientId: p.client.clientId,
      }));
  };

  const collectionCheckboxes = possibleCollectionPermissions.map(permission => (
    <CheckboxWithLoadingSpinner
      key={permission}
      checked={assetChecked(permission)}
      modifyPermissions={modifyPermissions}
      permission={permission}
      requestBody={requestBody}
      loading={modifyUserPermissionsLoading}
    />
  ));

  return (
    <div onMouseLeave={() => setShow(false)} className="d-inline pr-1">
      <input
        id="permissionPopoverCheckbox"
        ref={attachRef}
        type="checkbox"
        data-testid="permissionPopoverCheckbox"
        onClick={() => setShow(true)}
        checked={
          (!possibleCollectionPermissions.length > 0
            || possibleCollectionPermissions.every(p => assetChecked(p)))
        }
        readOnly
      />
      <Overlay target={target} placement="bottom-start" show={show}>
        {({
          placement,
          scheduleUpdate,
          arrowProps,
          outOfBoundaries,
          show: _show,
          ...props
        }) => (
          <Popover {...props} className="permission-popover">
            <div>
              {possibleCollectionPermissions.length > 0 && (
              <span>
                <strong>
                  {strings.collection}
                </strong>
              </span>
              )}
              {collectionCheckboxes}
            </div>
          </Popover>
        )}
      </Overlay>
    </div>
  );
};

PermissionPopover.propTypes = {
  user: userType.isRequired,
  assets: PropTypes.arrayOf(assetType).isRequired,
  userPermissions: userPermissionsType.isRequired,
  modifyPermissions: PropTypes.func.isRequired,
  availableUserRoles: roleType.isRequired,
  userRoles: roleType.isRequired,
  modifyRoles: PropTypes.func.isRequired,
  modifyUserPermissionsLoading: PropTypes.bool.isRequired,
};

export default PermissionPopover;
