import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as alertsActionCreators } from '../../store/AlertsStore';

export class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.resetTimer = this.resetTimer.bind(this);
  }

  componentDidMount = () => {
    const { timeOut } = this.props;
    if (timeOut !== 0) {
      this.timer = setTimeout(() => {
        this.setShowAlert(false);
      }, timeOut);
    }
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  resetTimer = (timer) => {
    const { timeOut } = this.props;
    clearTimeout(timer);
    this.timer = setTimeout(() => {
      this.setShowAlert(false);
    }, timeOut);
  }

  handleOkPress = (event) => {
    if (event.key === undefined || event.keyCode === 13) {
      this.setShowAlert(false);
    }
  }

  setShowAlert = (show) => {
    const {
      setShowErrorAlert,
      setShowSuccessAlert,
      error,
      success,
    } = this.props;
    if (error) setShowErrorAlert(show);
    if (success) setShowSuccessAlert(show);
  }

  render() {
    const { alertText, error, success } = this.props;
    let alertClass;
    if (error) alertClass = 'alert-error-accessportal';
    else if (success) alertClass = 'alert-success-accessportal';
    return (
      <div className="custom-alert alert-animation-accessPortal" onMouseEnter={() => this.resetTimer(this.timer)}>
        <div className={`alert alert-shadow text-sm m-1 ${alertClass}`}>
          <span className="pr-2"><i className="far fa-exclamation-triangle " /></span>
          {alertText}
          <div className="text-center">
            <span className="underline-text clickable" onClick={this.handleOkPress} onKeyDown={this.handleOkPress} role="button" tabIndex="0">
              OK
            </span>
          </div>
        </div>
      </div>
    );
  }
}

Alert.propTypes = {
  alertText: PropTypes.string.isRequired,
  setShowErrorAlert: PropTypes.func.isRequired,
  setShowSuccessAlert: PropTypes.func.isRequired,
  timeOut: PropTypes.number,
  error: PropTypes.bool,
  success: PropTypes.bool,
};

Alert.defaultProps = {
  timeOut: 5000,
  error: false,
  success: false,
};

function mapStateToProps(state) {
  return {
    alertText: state.alerts.alertText,
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...alertsActionCreators,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Alert);
