import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import { actionCreators as permissionActionCreators } from '../store/PermissionStore';
import { actionCreators as userManagementActionCreators } from '../store/UserManagementStore';
import { actionCreators as fileTransferActionCreators } from '../store/FileTransferStore';
import Sidebar from './sidebarComponents/Sidebar';
import Alert from './alerts/Alert';
import Reports from './content/Reports';
import AdminPanel from './adminPanelComponents/AdminPanel';
import FileTransferDownload from './fileTransferComponents/Download';
import FileTransferUpload from './fileTransferComponents/Upload';
import { checkUploaderRole } from '../utils/fileServiceRoleChecker';
import { Desktop, Mobile, useIsMobile } from './general/MediaQuery';
import Home from './content/Home';
import LogoSection from './sidebarComponents/LogoSection';
import NoMobilePageAvailable from './general/NoMobilePageAvailable';
import { useIsImpersonating } from '../utils/selectorUtils';
import { useAuth } from '../store/AuthStore';

const Dashboard = () => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const permissions = useSelector(state => state.permissions.policyApiPermissions);
  const coreSystems = useSelector(state => state.permissions.policyApiCoreSystems);
  const errorAlertShow = useSelector(state => state.alerts.showErrorAlert);
  const successAlertShow = useSelector(state => state.alerts.showSuccessAlert);
  const availableClients = useSelector(state => state.permissions.availableClients);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isImpersonating = useIsImpersonating();

  useEffect(() => {
    setMobileMenuVisible(false);
  }, [location]);

  useEffect(() => {
    if (isAuthenticated) {
      if (permissions === null) {
        dispatch(permissionActionCreators.fetchAndSetPermissions());
      }
    }
  }, [isAuthenticated, permissions]);

  useEffect(() => {
    if (coreSystems.includes('FILESERVICE')) {
      dispatch(fileTransferActionCreators.getFileTransferClients());
    }
  }, [coreSystems]);

  useEffect(() => {
    if (availableClients.length > 0 && isImpersonating === false) {
      dispatch(userManagementActionCreators.fetchUsersFromMultipleClients(availableClients));
      dispatch(
        userManagementActionCreators
          .fetchAssetsAndPermissionsFromMultipleClients(availableClients),
      );
      dispatch(
        userManagementActionCreators.fetchAvailableUserRolesFromMultipleClients(availableClients),
      );
    }
  }, [availableClients, isImpersonating]);

  let alert;
  if (errorAlertShow) alert = <Alert error />;
  else if (successAlertShow) alert = <Alert success />;

  return (
    <Fragment>
      <main className={`d-flex border border-bottom-0${isMobile ? ' flex-column' : ''}`}>
        <Desktop>
          <Sidebar />
        </Desktop>
        <Mobile>
          <div className="d-flex justify-content-between align-items-center border-bottom">
            <LogoSection />
            <div
              role="button"
              className="p-8"
              onClick={() => setMobileMenuVisible(prev => !prev)}
              tabIndex={0}
            >
              <i
                style={{ fontSize: '2em' }}
                className={classNames(
                  'fas',
                  mobileMenuVisible ? 'fa-times' : 'fa-bars',
                )}
              />
            </div>
          </div>
        </Mobile>
        <div id="pageContentWrapper" className={`container col px-0${!isMobile ? ' desktop' : ''}`}>
          <Mobile>
            {mobileMenuVisible && (
              <div
                style={{ height: 'calc(100vh - 64px)' }}
              >
                <Sidebar />
              </div>
            )}
          </Mobile>
          {(!isMobile || (isMobile && !mobileMenuVisible)) && (
            <div id="pageContentContainer">
              {alert}
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/reports" component={Reports} />
                <Route exact path="/adminpanel" component={!isMobile ? AdminPanel : NoMobilePageAvailable} />
                <Route exact path="/files">
                  <Redirect to="/files/download" />
                </Route>
                <Route exact path="/files/download" component={!isMobile ? FileTransferDownload : NoMobilePageAvailable} />
                {permissions && checkUploaderRole(permissions) && (
                  <Route exact path="/files/upload" component={!isMobile ? FileTransferUpload : NoMobilePageAvailable} />
                )}
              </Switch>
            </div>
          )}
        </div>
      </main>
    </Fragment>
  );
};

export default Dashboard;
