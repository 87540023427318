import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Dashboard from './components/Dashboard';
import PageNotFound from './components/PageNotFound';
import PowerBIDashboard from './components/PowerBIDashboard';
import BootstrapModals from './components/BootstrapModals';
import UserLoader from './components/UserLoader';
import { Redirect } from 'react-router';

// eslint-disable-next-line react/prop-types
const Root = ({
  store,
  history
}) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
        <UserLoader>
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/apps" component={Dashboard} />
            <Route exact path="/reports" component={Dashboard} />
            <Route exact path="/adminpanel" component={Dashboard} />
            <Route path="/files" component={Dashboard} />
            <Route exact path="/pbiReport" component={PowerBIDashboard} />
            <Route path="/callback">
              <Redirect to="/" />
            </Route>
            <Route path="/" component={PageNotFound} />
          </Switch>
          <BootstrapModals />
        </UserLoader>
    </ConnectedRouter>
  </Provider>
);

export default Root;
