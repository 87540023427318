import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckboxWithLoadingSpinner from './CheckboxWithLoadingSpinner';
import { userPermissionsType, userType, assetType } from '../../utils/propTypeTemplates';

const PermissionCheckbox = ({
  permissionName,
  asset,
  userPermissions,
  modifyPermissions,
  user,
  modifyUserPermissionsLoading,
}) => {
  const [checked, setChecked] = useState(userPermissions
    .find(up => up.assetId === asset.assetId && permissionName === up.permission) !== undefined);

  useEffect(() => {
    setChecked(userPermissions
      .find(up => up.assetId === asset.assetId && permissionName === up.permission) !== undefined);
  }, [userPermissions, asset, permissionName]);

  const requestBody = () => ([
    {
      assetId: asset.assetId,
      permission: permissionName,
      user: {
        FirstName: user.FirstName,
        LastName: user.LastName,
        Email: user.Email,
        Language: user.Language,
      },
      clientId: asset.client.clientId,
    },
  ]);

  return (
    <CheckboxWithLoadingSpinner
      checked={checked}
      modifyPermissions={modifyPermissions}
      permission={permissionName}
      requestBody={requestBody}
      loading={modifyUserPermissionsLoading}
    />
  );
};

PermissionCheckbox.propTypes = {
  permissionName: PropTypes.string.isRequired,
  asset: assetType.isRequired,
  userPermissions: userPermissionsType,
  user: userType.isRequired,
  modifyPermissions: PropTypes.func.isRequired,
  modifyUserPermissionsLoading: PropTypes.bool.isRequired,
};

PermissionCheckbox.defaultProps = {
  userPermissions: [],
};

export default PermissionCheckbox;
