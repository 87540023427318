import { func, number } from 'prop-types';
import React from 'react';
import { ReactComponent as FirstPageIcon } from '../../images/first_page_icon.svg';
import { ReactComponent as LastPageIcon } from '../../images/last_page_icon.svg';

const DownloadTablePaging = ({ page, pageCount, setPage }) => (
  <div className="d-flex justify-content-center align-items-center">
    <FirstPageIcon
      className={`fas fa-arrow-to-left mr-30${page > 1 ? ' clickable' : ''}`}
      fill={page > 1 ? '#434a4f' : '#b0b3b5'}
      onClick={() => {
        if (page > 1) setPage(1);
      }}
      data-testid="downloadFirstPageButton"
    />
    <i
      className={`fas fa-chevron-left mr-30 p-1${
        page > 1 ? ' clickable' : ' text-disabled'
      }`}
      onClick={() => {
        if (page > 1) setPage(prev => prev - 1);
      }}
      data-testid="downloadPrevPageButton"
    />
    <span className="mr-30">{`${page} / ${pageCount}`}</span>
    <i
      className={`fas fa-chevron-right mr-30 p-1${
        page < pageCount ? ' clickable' : ' text-disabled'
      }`}
      onClick={() => {
        if (page < pageCount) setPage(prev => prev + 1);
      }}
      data-testid="downloadNextPageButton"
    />
    <LastPageIcon
      className={`fas fa-arrow-to-left mr-30${
        page < pageCount ? ' clickable' : ''
      }`}
      fill={page < pageCount ? '#434a4f' : '#b0b3b5'}
      onClick={() => {
        if (page < pageCount) setPage(pageCount);
      }}
      data-testid="downloadLastPageButton"
    />
  </div>
);

DownloadTablePaging.propTypes = {
  page: number.isRequired,
  pageCount: number.isRequired,
  setPage: func.isRequired,
};

export default DownloadTablePaging;
