import classNames from 'classnames';
import React from 'react';
import logo from '../../images/lowell_logo2.png';
import { useIsMobile } from '../general/MediaQuery';

const LogoSection = () => {
  const isMobile = useIsMobile();

  return (
    <div
      className={classNames(
        'd-flex align-items-center justify-content-between',
        isMobile ? 'p-8' : 'p-20',
      )}
    >
      <img id="logoTopbar" src={logo} alt="" />
      {!isMobile && (
        <span id="topbar-text" className="text-secondary ml-30">
          Access
        </span>
      )}
    </div>
  );
};

export default LogoSection;
