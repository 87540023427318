/* eslint-disable camelcase */
// Address of the ReactRedux application
const siteUrl = `${window.location.protocol}//${window.location.host}`;

let config;

function getConfigFromAPI() {
  let remote_config;
  const request = new XMLHttpRequest();

  request.open('GET', `${siteUrl}/api/config`, false);
  request.send(null);

  if (request.status === 200) {
    try {
      remote_config = JSON.parse(JSON.parse(request.responseText));
    } catch (error) {
      remote_config = {
        AssemblyVersion: '1.0.0.1',
        Environment: 'Development',
      };

      if (process.env.NODE_ENV === 'development') {
        console.warn(
          'Failed to fetch Config from API, falling back to development defaults. Json parse failed.',
        );
      }
    }
  } else {
    remote_config = {
      AssemblyVersion: '1.0.0.1',
      Environment: 'Development',
    };

    if (process.env.NODE_ENV === 'development') {
      console.warn('Failed to fetch Config from API, falling back to development defaults');
    }
  }

  if (process.env.NODE_ENV === 'development') {
    console.groupCollapsed('Config');
    console.log('CONFIG:');
    console.log(remote_config);
    console.groupEnd('Config');
  }

  return remote_config;
}

export function getConfig() {
  if (!config) {
    config = getConfigFromAPI();
  }

  return config;
}
