import PropTypes, { arrayOf, shape, string } from 'prop-types';


export const userType = PropTypes.shape({
  Id: PropTypes.number,
  clientId: PropTypes.number,
  Email: PropTypes.string.isRequired,
  FirstName: PropTypes.string.isRequired,
  LastName: PropTypes.string.isRequired,
  Language: PropTypes.string.isRequired,
});

export const roleType = PropTypes.arrayOf(PropTypes.shape({
  ClientId: PropTypes.number,
  RoleId: PropTypes.string,
  CoreSystemId: PropTypes.string,
}));

export const clientType = PropTypes.shape({
  clientId: PropTypes.number.isRequired,
});

export const userPermissionsType = PropTypes.arrayOf(PropTypes.shape({
  assetId: PropTypes.string.isRequired,
  permission: PropTypes.string.isRequired,
}));

export const assetType = PropTypes.shape({
  assetId: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
  client: PropTypes.shape({ clientId: PropTypes.number.isRequired }),
});

export const localizationType = PropTypes.shape({
  language: PropTypes.string.isRequired,
});

export const policyApiPermissionsType = arrayOf(
  shape({
    coreSystemId: string,
    sourceId: string,
    roles: arrayOf(
      shape({
        name: string,
        id: string,
      }),
    ),
    assetPermissions: arrayOf(shape({})),
  }),
);
