import React, { useEffect } from 'react';
import { useDispatch} from 'react-redux';
import { useHistory } from 'react-router';
import { getUser, useAuth } from '../store/AuthStore';
import { login } from '../utils/authUtils';
import { useQuery } from '../utils/useQuery';

interface Props {
  children: JSX.Element;
}

const UserLoader = ({ children }: Props) => {
  const dispatch = useDispatch();
  const client = useQuery('client');
  const { isAuthenticated } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated === null) {
      dispatch(getUser());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // After login remove 'client' query param from url
    if (isAuthenticated) {
      if (client) {
        history.replace("/");
      }
    }
  }, [isAuthenticated]);
  
  if (isAuthenticated === false) {
    login(`${location.pathname}${location.search}`, client);
  }

  return !isAuthenticated ? (
    <div className="row flex-column pt-5 w-100 justify-content-center align-items-center">
      <h4>Verifying your credentials..</h4>
      <img height="64" width="64" alt="Loading.." src="/lowell_loading.gif" />
    </div>
  ) : children;
};

export default UserLoader;
