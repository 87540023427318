import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { actionCreators as userManagementActionCreators } from '../../store/UserManagementStore';
import PermissionPopover from './PermissionPopover';
import GroupedAssetsAndRoles from './GroupedAssetsAndRoles';
import strings from '../../utils/strings';
import { friendlyAssetId } from '../../utils/friendlyNames';
import {
  userType,
  roleType,
  userPermissionsType,
} from '../../utils/propTypeTemplates';

export const AssetPermissions = ({
  permissions,
  clients,
  user,
  userPermissionsLoading,
  userPermissions,
  fetchUserPermissions,
  modifyUserPermissionsLoading,
  addUserPermission,
  deleteUserPermission,
  availableUserRoles,
  fetchUserRoles,
  userRoles,
  userRolesLoading,
  addUserRole,
  deleteUserRole,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [sortOrder, setSortOrder] = useState(0);
  const [sortedByAssetId, setSortedByAssetId] = useState(false);
  const [sortedByAssetName, setSortedByAssetName] = useState(false);
  const [
    filteredAssetsAndRolesGroupedByClient,
    setFilteredAssetsAndRolesGroupedByClient,
  ] = useState([]);
  const [
    sortedAssetsAndRolesGroupedByClient,
    setSortedAssetsAndRolesGroupedByClient,
  ] = useState([]);
  const [originalGroups, setOriginalGroups] = useState([]);

  useEffect(() => {
    const assetsAndRolesGroupedByClient = clients.map(client => ({
      client,
      assets: permissions.filter(perm => client.clientId === perm.clientId),
      userRoles: availableUserRoles.filter(ur => ur.ClientId === client.clientId),
    }));
    setSearchInput('');
    fetchUserPermissions(clients, user.Email);
    fetchUserRoles(clients, user.Email);
    setSortOrder(0);
    setSortedByAssetId(false);
    setSortedByAssetName(false);
    setSortedAssetsAndRolesGroupedByClient(assetsAndRolesGroupedByClient);
    setFilteredAssetsAndRolesGroupedByClient(assetsAndRolesGroupedByClient);
    setOriginalGroups(assetsAndRolesGroupedByClient);
  }, [fetchUserPermissions, fetchUserRoles, clients, user, availableUserRoles, permissions]);

  useEffect(() => {
    if (searchInput.length > 0) {
      const splitSearch = searchInput.split(' ');
      const filteredGroups = sortedAssetsAndRolesGroupedByClient.map((group) => {
        const filteredRoles = group.userRoles.filter(() => splitSearch
          .filter(word => strings.invoiceWeb.toLowerCase().includes(word.toLowerCase())
            || group.client.clientName.toLowerCase().includes(word.toLowerCase()))
          .length === splitSearch.length);
        const filteredAssets = group.assets.map((p) => {
          const filteredAssetPermission = p.assetPermission.filter(ap => splitSearch
            .filter(word => friendlyAssetId(ap.AssetId).toLowerCase().includes(word.toLowerCase())
              || (ap.AssetName !== null
                && ap.AssetName.toLowerCase().includes(word.toLowerCase()))
              || group.client.clientName.toLowerCase().includes(word.toLowerCase()))
            .length === splitSearch.length);
          return {
            ...p,
            assetPermission: filteredAssetPermission,
          };
        });
        return {
          ...group,
          assets: filteredAssets,
          userRoles: filteredRoles,
        };
      });
      setFilteredAssetsAndRolesGroupedByClient(filteredGroups);
    } else setFilteredAssetsAndRolesGroupedByClient(sortedAssetsAndRolesGroupedByClient);
  }, [searchInput, sortedAssetsAndRolesGroupedByClient]);

  const modifyPermissions = (checked, request) => {
    if (!modifyUserPermissionsLoading) {
      if (checked) {
        deleteUserPermission(request);
      } else {
        addUserPermission(request);
      }
    }
  };

  const modifyRoles = (checked, request) => {
    if (!modifyUserPermissionsLoading) {
      if (!checked) {
        addUserRole(request);
      } else {
        deleteUserRole(request);
      }
    }
  };

  const sortPermissions = (changeOrder, byAssetId) => {
    const groupedAssets = JSON.parse(JSON.stringify(originalGroups));
    let order;
    if (sortOrder === 0 || byAssetId !== sortedByAssetId) {
      order = 1;
    } else if (sortOrder === 1) {
      order = -1;
    } else if (sortOrder === -1) {
      order = 0;
    }
    if (byAssetId) {
      groupedAssets
        .forEach(group => group.assets
          .forEach(asset => asset.assetPermission
            .sort((a, b) => {
              if (a.AssetId.toLowerCase() < b.AssetId.toLowerCase()) {
                return -order;
              } if (a.AssetId.toLowerCase() > b.AssetId.toLowerCase()) {
                return order;
              } if (a.AssetId.toLowerCase() < b.AssetId.toLowerCase()) {
                return -order;
              } if (a.AssetId.toLowerCase() > b.AssetId.toLowerCase()) {
                return order;
              }
              return 0;
            })));
      setSortedByAssetId(true);
      setSortedByAssetName(false);
    } else {
      groupedAssets
        .forEach(group => group.assets
          .forEach(asset => asset.assetPermission
            .sort((a, b) => {
              if (a.AssetName === null) return order;
              if (b.AssetName === null) return -order;
              if (a.AssetName.toLowerCase() < b.AssetName.toLowerCase()) {
                return -order;
              } if (a.AssetName.toLowerCase() > b.AssetName.toLowerCase()) {
                return order;
              } if (a.AssetName.toLowerCase() < b.AssetName.toLowerCase()) {
                return -order;
              } if (a.AssetName.toLowerCase() > b.AssetName.toLowerCase()) {
                return order;
              }
              return 0;
            })));
      setSortedByAssetId(false);
      setSortedByAssetName(true);
    }
    setSortOrder(order);
    setSortedAssetsAndRolesGroupedByClient(groupedAssets);
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const assetsLoading = (userPermissionsLoading || userRolesLoading || !userPermissions) && (
    <tr data-testid="assetsLoading">
      <td className="container pt-4 pb-4 text-center">
        <img alt="" src="/lowell_loading.gif" />
      </td>
    </tr>
  );

  const permissionListIsEmpty = (
    <tr className="d-flex">
      <td className="mx-auto border-top-0">
        <span>{strings.assetsNotFound}</span>
      </td>
    </tr>
  );

  const groupedAssetsAndRoles = filteredAssetsAndRolesGroupedByClient.some(group => (
    group.assets.some(a => (
      a.assetPermission.length > 0
    )) || group.userRoles.length > 0
  ))
    ? filteredAssetsAndRolesGroupedByClient.map(group => (
      <GroupedAssetsAndRoles
        client={group.client}
        assets={group.assets}
        availableUserRoles={group.userRoles}
        userRoles={userRoles}
        modifyPermissions={modifyPermissions}
        modifyRoles={modifyRoles}
        user={user}
        userPermissions={userPermissions}
        modifyUserPermissionsLoading={modifyUserPermissionsLoading}
        key={group.client.clientId}
      />
    )) : permissionListIsEmpty;

  const sortIcon = () => {
    if (sortOrder === 0) {
      return 'fa-sort';
    }
    return sortOrder === 1 ? 'fa-sort-up' : 'fa-sort-down';
  };

  const getVisibleAssets = () => {
    const visibleAssets = [];
    filteredAssetsAndRolesGroupedByClient
      .forEach(group => group.assets
        .forEach(a => a.assetPermission.forEach(ap => visibleAssets.push({
          assetId: ap.AssetId,
          permissions: a.availablePermissions.map(perm => perm.Name),
          client: { clientId: a.clientId },
        }))));
    return visibleAssets;
  };

  const getVisibleRoles = () => {
    const visibleRoles = [];
    filteredAssetsAndRolesGroupedByClient.forEach((group) => {
      if (group.userRoles.length > 0) {
        group.userRoles.forEach(ur => visibleRoles.push(ur));
      }
    });
    return visibleRoles;
  };

  return (
    <div className="border-top mx-20 pt-3">
      <div className="row pb-2">
        <div className="col-6">
          <strong className="align-middle pt-2">
            {`${strings.permissions}:`}
          </strong>
        </div>
        <div className="col-6 pl-2">
          <input onChange={e => handleSearchInputChange(e)} value={searchInput} type="text" className="form-control w-100" placeholder={strings.searchAsset} />
        </div>
      </div>
      <div id="permissionTableContainer" className="h-100 no-gutters">
        <table id="permissionTableFixed" className="table table-sm">
          <thead className="d-flex" id="permissionHeader">
            <tr className="flex-grow-1">
              <th className="col-4 text-nowrap">
                <span className="pl-2">
                  {strings.id}
                </span>
                <i
                  onClick={() => sortPermissions(true, true)}
                  onKeyDown={(e) => { if (e.keyCode === 13) sortPermissions(true, true); }}
                  className={`fas ${!sortedByAssetId ? 'fa-sort' : sortIcon()} clickable pl-1`}
                  role="button"
                  tabIndex={0}
                  data-testid="assetIdSort"
                />
              </th>
              <th className="col-5 pl-0 text-nowrap">
                {strings.assetName}
                <i
                  onClick={() => sortPermissions(true, false)}
                  onKeyDown={(e) => { if (e.keyCode === 13) sortPermissions(true, false); }}
                  className={`fas ${!sortedByAssetName ? 'fa-sort' : sortIcon()} clickable pl-1`}
                  role="button"
                  tabIndex={0}
                  data-testid="assetNameSort"
                />
              </th>
              <th className="col-3 text-nowrap">
                {userPermissions && userRoles && (
                  <PermissionPopover
                    assets={getVisibleAssets()}
                    user={user}
                    modifyPermissions={modifyPermissions}
                    userPermissions={userPermissions}
                    availableUserRoles={getVisibleRoles()}
                    userRoles={userRoles}
                    modifyRoles={modifyRoles}
                    modifyUserPermissionsLoading={modifyUserPermissionsLoading}
                  />
                )}
                <label className="m-0" htmlFor="permissionPopoverCheckbox">
                  {strings.permissions}
                </label>
              </th>
            </tr>
            <tr>
              <th className="h-100" style={{ width: '17px' }} />
            </tr>
          </thead>
          <tbody>
            {assetsLoading || groupedAssetsAndRoles}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  userPermissionsLoading: state.usermanagement.userPermissionsLoading,
  userPermissions: state.usermanagement.userPermissions,
  activeLanguage: state.localization.language,
  modifyUserPermissionsLoading: state.usermanagement.modifyUserPermissionsLoading,
  userRolesLoading: state.usermanagement.userRolesLoading,
  userRoles: state.usermanagement.userRoles,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...userManagementActionCreators,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssetPermissions);

AssetPermissions.propTypes = {
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      clientId: PropTypes.number.isRequired,
      assetPermission: PropTypes.arrayOf(
        PropTypes.shape({
          AssetId: PropTypes.string.isRequired,
          AssetName: PropTypes.string,
        }),
      ).isRequired,
      availablePermissions: PropTypes.arrayOf(
        PropTypes.shape({
          Name: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      administrator: PropTypes.bool,
      clientId: PropTypes.number,
      clientName: PropTypes.string,
    }),
  ),
  user: userType.isRequired,
  userPermissionsLoading: PropTypes.bool,
  userPermissions: userPermissionsType,
  availableUserRoles: roleType.isRequired,
  fetchUserPermissions: PropTypes.func.isRequired,
  modifyUserPermissionsLoading: PropTypes.bool,
  addUserPermission: PropTypes.func.isRequired,
  deleteUserPermission: PropTypes.func.isRequired,
  fetchUserRoles: PropTypes.func.isRequired,
  userRoles: roleType,
  userRolesLoading: PropTypes.bool,
  addUserRole: PropTypes.func.isRequired,
  deleteUserRole: PropTypes.func.isRequired,
};
AssetPermissions.defaultProps = {
  clients: [],
  userPermissions: [],
  userPermissionsLoading: false,
  userRolesLoading: false,
  modifyUserPermissionsLoading: false,
  userRoles: [],
};
